import React from "react";
import Snackbar, { SnackbarContent } from '@nokia-csf-uxr/ccfk/Snackbar';
import PropTypes from "prop-types";

const SnackBar = ({ message, onClose }) => {
    return (
        <>
            {message && (
                <Snackbar
                    key={message}
                    open={true} // Always open when there's a message
                    onClose={onClose} // Close the Snackbar when a close action occurs (e.g., clicking the close icon)
                    onExited={onClose} // Safely clear the snackbar message after it has fully exited
                    role="alert"
                >
                    <SnackbarContent>{message}</SnackbarContent>
                </Snackbar>
            )}
        </>
    );
};

SnackBar.propTypes = {
    message: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default SnackBar;
