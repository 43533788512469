import { Button, ButtonsRow, Dialog, Label, Typography } from '@nokia-csf-uxr/ccfk';
import { AlertBar, AlertMessage } from '@nokia-csf-uxr/ccfk/Alert';
import { DialogContent, DialogFooter, DialogTitle } from '@nokia-csf-uxr/ccfk/Dialog';
import { ExpansionPanel, ExpansionPanelBody, ExpansionPanelButton, ExpansionPanelHeader, ExpansionPanelTitle } from '@nokia-csf-uxr/ccfk/ExpansionPanels';
import ExpansionPanelsContainer from '@nokia-csf-uxr/ccfk/ExpansionPanels/ExpansionPanelsContainer';
// import { systemStyle } from 'Constants/StyleLayer';
import PropTypes from 'prop-types';
import React from 'react';

interface IActionBtns {
    label: string,
    onAction: Function,
    variant: "neutral"| "outlined" | "call-to-action",
    fullWidth?: Boolean
}
interface IDialogProps {
    title: string,
}
interface IPanel {
    show: boolean,
    title: string,
    msg: string,
    onExpand?: Function,
}
export type TPopupVariantTypes = "CONFIRM" | "ERROR" | "WARNING" | undefined;
interface IPopupProps extends IDialogProps {
    msg: string,
    variant?: TPopupVariantTypes,
    panel?: IPanel,
}

interface IDialogModal {
    type: "modal" | "popup",
    isOpen: boolean,
    children?: React.ReactNode,
    popInfo?: IPopupProps,
    dialogInfo?: IDialogProps,
    actionBtns: Array<IActionBtns>,

}
const POPUP_STYLE = { height: '15rem', top: `calc((100vh - 15rem) / 2)` };
const MODAL_STYLE = { top: "10vh", bottom: "10vh", minHeight: "12.5rem", width: '80%', inset: '10vh calc((100vw - 80%) / 2)' }
const DialogModal = (props: IDialogModal) => {
    const POPUP_VARIANT = (props.popInfo?.hasOwnProperty('variant') && props.popInfo?.variant) ? props.popInfo.variant : "CONFIRM"

    return (
        <>
            <div style={{ width: '80%' }}>
                <Dialog
                    key={'dialog'}
                    shouldCloseOnEsc={true}
                    isOpen={props.isOpen} //props.isOpen
                    style={{ overlay: {}, content: (props.type === "popup") ? POPUP_STYLE : MODAL_STYLE }}
                    onRequestClose={() => {
                        //  console.log('onRequestClose')
                    }}>
                    {
                        (props.type === "popup") ? (<>
                            <AlertBar variant={POPUP_VARIANT} />

                            <DialogTitle title={<AlertMessage
                                message={<b style={{ fontSize: '1rem' }}>{props.popInfo.title}</b>}
                                variant={POPUP_VARIANT}
                            />} />
                            <DialogContent isTopDividerVisible={false} isBottomDividerVisible={false} >
                                <Typography typography="BODY">{props.popInfo.msg}</Typography>
                                {props.children}
                                {
                                    (props.popInfo.panel && props.popInfo.panel.msg.length > 0) && (<ExpansionPanelsContainer >
                                        <ExpansionPanel
                                            expanded={props.popInfo.panel.show}
                                            key={1}
                                            id={'1'}
                                        >
                                            <ExpansionPanelHeader
                                                data-test="header"
                                                id='expansionheader'
                                                role="button"
                                                style={{ justifyContent: "space-between", alignItems: 'center' }}
                                            >
                                                <div id="list" style={{ display: "flex", justifyContent: "space-between", width: 300 }}>
                                                    <ExpansionPanelTitle id='test'>
                                                        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", }}>
                                                            <div id="list-heading" style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', alignContent: 'center' }}>
                                                                <Typography style={{ alignSelf: 'center' }} typography='BODY' maxLines={1} >{props.popInfo.panel.title}</Typography>
                                                            </div>
                                                        </div>

                                                    </ExpansionPanelTitle>
                                                </div>
                                                <div style={{ color: "#737373", alignSelf: 'center', flexShrink: 0 }}>
                                                    {/* <Typography style={{ alignSelf: 'center' }} typography='BODY' maxLines={1} >{'test'}</Typography> */}
                                                </div>
                                                <div >
                                                    {/* <Typography style={{ alignSelf: 'center' }} typography='BODY' maxLines={1} >{'sdfsdf'}</Typography> */}
                                                </div>

                                                <ExpansionPanelButton onClick={() => {
                                                    props.popInfo.panel.onExpand();
                                                }} style={{ flexGrow: `1` }} />


                                            </ExpansionPanelHeader>
                                            <ExpansionPanelBody style={{ padding: `1rem` }} >
                                                <Label>{props.popInfo.panel.msg}</Label>
                                            </ExpansionPanelBody>
                                        </ExpansionPanel>
                                    </ExpansionPanelsContainer>)
                                }
                            </DialogContent>
                        </>) : (<>
                            <DialogTitle title={props.dialogInfo.title} />
                            <DialogContent isTopDividerVisible isBottomDividerVisible>
                                {props.children}
                            </DialogContent>
                        </>)
                    }
                    <DialogFooter style={{padding:0,margin:0}}>
                        {
                            (props.actionBtns) && (<ButtonsRow >
                                {
                                    props.actionBtns.map((e, i) => (<Button  style={{width:'8rem'}} fullWidth={Boolean(e.hasOwnProperty('fullWidth') && e?.fullWidth)} variant={e.variant} key={i} onClick={() => e.onAction()}>{e.label}</Button>))
                                }
                            </ButtonsRow>)
                        }
                    </DialogFooter>
                </Dialog >
            </div >
        </>
    )
}

export default DialogModal

DialogModal.propType = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node,
    actionBtns: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string,

}