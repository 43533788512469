import React, { useEffect, useRef, useState } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import OOT from 'Constants/services/oot';
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import { setActiveOrders } from "Store/contentState/marketplaceSlice";
import eyeIcon from '../../../Assets/Icons/eye_icon.svg';
import Chip, {
    ChipLabel,
  } from '@nokia-csf-uxr/ccfk/Chip';
  import OfferViewDialog from "../OfferViewDialog";
  import { updatedOffersList } from "../Utils/commons";
  import PriceDisplay from '../UIElements/PriceDisplay';

const ActiveOrders = () => {
    
    const dispatch = useDispatch();
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const [showViewDialog, setShowViewDialog] = useState(false);
    const [offer, setOffer] = useState({})


    const gridApi = useRef(null);

    useEffect(() => {
        if (marketplaceSelector.filters.selection.partner && marketplaceSelector.accountDetails.length > 0 && marketplaceSelector.filters.selection.customer) {
            getActiveOrders();
        } else {
            dispatch(setActiveOrders([]))
        }
    }, [marketplaceSelector.filters.selection.partner, marketplaceSelector.accountDetails, marketplaceSelector.filters.selection.customer])


    const handleOrderView = (data) => {
        setOffer(data);
        setShowViewDialog(true);
    }
    


    const getActiveOrders = async () => {
        try {
          const oneStarId = marketplaceSelector.filters.selection.partner;
          const endCustomer = marketplaceSelector.accountDetails[0]['customer_one_star_num'];
          let filter = "offer_header_data.po_link_pdf_attachment_link IS NOT NULL and offer_header_data.field_ofr_order_source = 'marketplace' and offer_header_data.field_ofr_order_status = 'order'"
          const res = await OOT.getQuotationAPI(oneStarId, endCustomer, filter)
          if(res.status){
            // console.log(res.data, 'offers')
            const result = updatedOffersList(res.data);
            const updatedResult = result.map((item) => {
              return {
                ...item,
                "endCustomerName":marketplaceSelector.accountDetails[0]?.end_customer_name,
              }
            })
            dispatch(setActiveOrders(updatedResult))
          }else{
            dispatch(setActiveOrders([]))
          }
        } catch (error) {
          console.error(error)
        }
      }
    
      

    return (
        <>
        {showViewDialog && (
            <OfferViewDialog onClose={() => setShowViewDialog(false)} selectedOffer={offer} offerType="Order"/>
          )}
        <div id='orders' style={{ width: '100%', height: '100%', marginTop: '10px' }}>
            <DataGrid
                // variant={"compact"}
                suppressMovableColumns={false}
                suppressColumnMoveAnimation={false}
                suppressRowClickSelection={false}
                isRowDrag={false}
                wrapperProps={{
                    style: {
                        width: "100%"
                        // width: 600,
                    }
                }}
                sideBar
                disableAutoloadStyles
                suppressContextMenu
                onGridReady={(params) => {
                    gridApi.current = params.api;
                }}
                rowData={marketplaceSelector.activeOrders}
                columnDefs={[
                { 
                    headerName: 'Order ID', 
                    field: 'offer_id', 
                    minWidth: 100,
                    cellRenderer: (params) => (
                        <a href={`${process.env.REACT_APP_OOT}/offer/edit/${params.data.offer_id}`} target="_blank" rel="noopener noreferrer" style={{  color:'#333333'}}>
                            {params.data.offer_id}
                        </a>
                    ),
                    cellStyle: { 'borderLeft': '3px solid', 'borderLeftColor': 'var(--g-color-app-banner-background)' } 
                },

                // { headerName: 'Quotation Name', field: 'offer_title', minWidth: 240, flex: 2 },
                {
                    headerName: 'Account',
                    field: 'name',
                    minWidth: 200,
                    //flex: 1,

                },
                {
                  headerName: 'End customer',
                  field: 'endCustomerName',
                  minWidth: 115,
                  //flex: 1,

              },
                {
                    headerName: 'Order date',
                    field: 'offer_created_date',
                    // minWidth: 115,
                    //flex: 1,

                },
                {
                    headerName: 'Cost',
                    field: 'cost',
                    minWidth: 115,
                    //flex: 1,

                },
                {
                    headerName: 'Ordered by',
                    field: 'owner_email',
                    minWidth: 115,
                    //flex: 1,

                },
                {
                    headerName: 'Status',
                    field: 'status',
                    width: 115,
                    //flex: 1,
                    cellRenderer: (params) => 
                        // console.log(params)
                        <div>

                            <Chip size="small" style={{ backgroundColor: '#E4F0FF', border: '1px solid #8EC2FF', marginRight: '.5rem', borderRadius: '15px' }}><ChipLabel label={params.data.status} /></Chip>

                        </div>


                    

                },
                // {
                //     headerName: 'PO Number',
                //     field: 'po_number',
                //     minWidth: 115,
                //     //flex: 1,

                // },
                {
                    headerName: 'PO file id',
                    field: 'po_link_pdf_attachment_link',
                    width: 125,
                    //flex: 1,

                },
                {
                    width: 50,
                    // flex: 0.3,
                    hide: !true,
                    cellRenderer: (params) => <div style={{ display: 'flex', justifyContent: 'center' }}><img src={eyeIcon} onClick={() => handleOrderView(params.data)} /></div>,
                  },


                ]}
                key={'orders'}
                defaultColDef={{
                    editable: false,
                    sortable: true,
                    flex: 0,
                    minWidth: 30,
                    filter: true,
                    resizable: false,
                }}
                overlayNoRowsTemplate='No orders are available'
                isInlineRowActions={false}
                domLayout='autoHeight'
                onFirstDataRendered={(params) => {
                    params.columnApi.autoSizeAllColumns();
                    // onFirstDataRendered(params);
                }}
            />
        </div>
        </>
    )
}

export default ActiveOrders;