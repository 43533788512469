import React, { useState, useEffect } from 'react';
import { setProp } from 'Store/Actions';
import { useDispatch, useSelector } from 'react-redux';
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import { Button } from "@nokia-csf-uxr/ccfk";
import { RootState } from "Store/mainStore";
import OOT from 'Constants/services/oot';
import { Typography } from "@nokia-csf-uxr/ccfk";
import DOMPurify from 'dompurify';

const DIALOG_HEIGHT = "70%";
const DIALOG_WIDTH = "60%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};
type ITermsConditionsDailogCloseProp = {
    onClose: () => void;
};

const TermsConditionsDailog = ({ onClose, type }: ITermsConditionsDailogCloseProp & { type: string }) => {
    const dispatch = useDispatch();
    const cart = useSelector((state: RootState) => state.marketplace.cart);
    const [termsConditions, setTermsConditions] = useState([])
    let versionIds = [];

    useEffect(() => {
        if (type) {
            getTermsAndConditions(type);
        }
    }, [type, cart]);



    const getSupplierIdsFromCart = (cart) => {
        // Extract unique supplier_ids from cart items where is_agent_model is 1
        const supplierIds = Array.from(new Set(cart.filter(item => item.is_agent_model === 1 || item.is_agent_model === 0).map(item => item.supplier_id)));
        return supplierIds;
      };

    
    const getTermsAndConditions = async (type: string) => {
        try {
          let supplierIds = [];
          
          if (type === 'merchant' || type === 'dac') {
            supplierIds = getSupplierIdsFromCart(cart);
          }
          let filter = `order by merchant_terms_and_conditions.version_id desc`
          const response = await OOT.getTermsConditions(type === 'dac' ? ["NFI85"] : supplierIds, filter);
          if (response.status) {
            setTermsConditions(response.data[0]['terms_and_conditions']['info']);
            versionIds.push(response.data[0]['version_id']);
            //console.log(versionIds)
            dispatch(setProp({ slice: 'marketplace', key: 'nokiaTermsConditions', value: versionIds.join(',') }))
          }
        } catch (error) {
          console.error(error);
        }
      };

    return (
        <>
            <Dialog
                isOpen={true}
                ariaHideApp={false}
                style={{ content: CONFIRM_ALERT_STYLE }}
            >
                <DialogTitle
                    title={type === 'dac' ? "Marketplace Terms & Conditions" : "Merchants Terms & Conditions"}
                    buttonContent={<CloseCloseIcon />}
                    buttonProps={{
                        onClick: onClose,
                    }}
                />
                <DialogContent isTopDividerVisible={true} isBottomDividerVisible={true}>
                    {termsConditions.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <h4 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.header) }} style={{ marginBottom: "10px" }}></h4>
                                <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.data) }}></Typography>

                            </React.Fragment>
                        )
                    })}
                </DialogContent>
                <DialogFooter>
                    <Button onClick={onClose}>Close</Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default TermsConditionsDailog;