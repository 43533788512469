import { createSlice } from "@reduxjs/toolkit";
import { segment_solution } from "Components/Content/configurationArea/PTDAC/Applications/system";
import { STATUS } from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
import { setPropT } from 'Store/Actions/ts';
import _ from "lodash";

const generalOptions = {
    segment_solution: {
        use_case: [],
        applications_devices: {
            a1: [],
            a2: []
        }
    },
    recommended_devices: []
};
const initialData = {
    view: {
        PTDAC: {}
    },
    options: {
        PTDAC: { ...generalOptions }
    }

};
const applicationsSlice = createSlice({
    name: 'applications',
    initialState: {
        data: initialData,
        status: STATUS.IDLE,
    },
    reducers: {
        Case(state, action) {
            //{method:"add",case:""}
            if (action.payload.method === "add") {
                state.data.options.PTDAC.segment_solution.use_case.push(action.payload.case);
            }
            if (action.payload.method === "remove") {
                const isCase = segment_solution.allIds.findIndex((e) => e === action.payload.case);
                if (isCase >= 0) {
                    _.pull(state.data.options.PTDAC.segment_solution.applications_devices[segment_solution.allIds[isCase]],...segment_solution.byIds[segment_solution.allIds[isCase]].options.allIds) 
                }
                
                _.remove(state.data.options.PTDAC.segment_solution.use_case, function (n) {
                    return n == action.payload.case;
                });
            }
        },
        applicationsDevices(state, action) {
            //@applications_devices
            //{"method":"",useCase:"",device:""}
            if (action.payload.method === "add") {
                state.data.options.PTDAC.segment_solution.applications_devices[action.payload.useCase].push(action.payload.device);
            }
            if (action.payload.method === "remove") {
                state.data.options.PTDAC.segment_solution.applications_devices[action.payload.useCase] = state.data.options.PTDAC.segment_solution.applications_devices[action.payload.useCase].filter((e) => e !== action.payload.device);
            }

        },
        /** All Recommendation contains Product ITEM*/
        recommendDevices(state, action) {
            //@recommended_devices : {"method":"",product:""}
            if (action.payload.method === "add") {
                state.data.options.PTDAC.recommended_devices.push(action.payload.product);
            }
            if (action.payload.method === "remove") {
                const res = state.data.options.PTDAC.recommended_devices.filter((e) => e.product_id !== action.payload.product.product_id);
                state.data.options.PTDAC.recommended_devices = res;
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: setPropT) => {
            if (action.payload.slice === applicationsSlice.name) {
                _.set(state.data, action.payload.key, action.payload.value);
            }
        })
    }
});

export default applicationsSlice.reducer;
export const { applicationsDevices, recommendDevices, Case } = applicationsSlice.actions;