import React, { useEffect, useState, useMemo } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import { setProp } from "Store/Actions";
import userStyles from "../Style/UserManagement.module.css";
import Label from '@nokia-csf-uxr/ccfk/Label';
import SelectBox from "Pages/subscription/Custom/SelectBox";
import { updateRoles } from "Store/contentState/userManagementSlice";
import OOT from "Constants/services/oot";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import { modifyData } from "../utils/constants";
import { setUserInputFields, updateSelectedPersonas } from "Store/contentState/userManagementSlice";
import CommonEmailField from "./CommonEmailField";

const CommonFormFields = (prop) => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const selectedUserOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
    const selectedUserType = useSelector((state: RootState) => state.userManagement.selectedUserType);
    const selectedRoles = useSelector((state: RootState) => state.userManagement.userData.roles);
    const rolesBasedOnOrgType = useSelector((state: RootState) => state.userManagement.rolesBasedOnOrgType);
    const applicationsList = useSelector((state: RootState) => state.userManagement.azureApplications);
    const selectedApplication = useSelector((state: RootState) => state.userManagement.userData.id);
    const selectedApplicationName = useSelector((state: RootState) => state.userManagement.userData.fullName);
    const userEmailAddress = useSelector((state: RootState) => state.userManagement.userData.emailAddress);
    const isAddingNokiaUser = useSelector((state: RootState) => state.userManagement.isAddingNokiaUser);
    const isEmailEntered = useSelector((state: RootState) => state.userManagement.isEmailEntered);
    let modifiedRoleData = [];

    const memoizedSelectedUserOrgType = useMemo(() => selectedUserOrgType, [selectedUserOrgType]);
    const memoizedSelectedUserType = useMemo(() => selectedUserType, [selectedUserType]);

    //const shouldExclude = (field) => prop.excludeFields.includes(field);

    useEffect(() => {
        if ((memoizedSelectedUserType === 'user' && memoizedSelectedUserOrgType.length > 0) || memoizedSelectedUserType === 'machine') {
            fetchRolesBasedOnOrgType();
        }
    }, [memoizedSelectedUserOrgType, memoizedSelectedUserType]);



    const fetchRolesBasedOnOrgType = async () => {
        console.log(selectedUserOrgType, 'selectedUserOrgType', "calling 1st time");
        try {
            let selectedOrgType = selectedUserType === 'user' ? selectedUserOrgType[0] : 'nokia';
            let filter = "JSON_CONTAINS(data_objects->'$.allowed_org_type', '\"" + selectedOrgType + "\"')";
            const response = await OOT.getUserRoles(filter);
            if (response.status) {
                console.log(response.data, 'response.data');
                modifiedRoleData = modifyData(response.data, 'id', 'name');
                dispatch(setProp({ slice: 'userManagement', key: 'rolesBasedOnOrgType', value: modifiedRoleData }))
            }
        } catch (error) {
            console.log(error);
        }

    }

   
    const handleRolesSelection = (role) => {
        console.log(role, 'role');
        if (role) {
            fetchRolesMetaData(role);
            let filteredRole = rolesBasedOnOrgType.filter((item) => item.id === role);
            const roleData = {
                [filteredRole[0].id]: filteredRole[0].value
            }
            dispatch(updateRoles(roleData));

            //future implementation
            //  let roles = [role]
            //  console.log(roles, 'roles')


            // const updatedRoles = {
            //     ...selectedRoles,
            //     [id]: selectedRoles[id] === undefined ? name : undefined
            // };
            // dispatch(updateRoles(updatedRoles));
        }



    };


    const fetchRolesMetaData = async (role) => {
        try {
            let filter = `common_roles.id = '${role}'`
            const result = await OOT.getUserRoles(filter)
            if (result.status) {
                console.log(result.data)
                const { view, data_elements } = result.data[0]['data_objects'];
                //console.log(view, data_elements, 'view, data_elements')
                dispatch(setUserInputFields({ field: "viewAccess", value: view }))
                dispatch(setUserInputFields({ field: "dataElement", value: data_elements }))
            }
        } catch (error) {
            console.log(error)
        }
    }



    const fetchAzureApplication = async () => {
        try {
            const response = await OOT.getAzureApplications(searchText)
            if (response.status) {
                console.log(response.data)
                let modifiedApplicationsList = modifyData(response.data, 'appId', 'displayName');
                dispatch(setProp({ slice: 'userManagement', key: 'azureApplications', value: modifiedApplicationsList }))
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            fetchAzureApplication(); // Call the API when Enter key is pressed
        }
    };


    const handleApplicationSelection = (item) => {
        console.log(item, 'item');
        if (item) {
            let filteredApplication = applicationsList.filter((app) => app.id === item);
            console.log(filteredApplication, 'filteredApplication');
            dispatch(setUserInputFields({ field: 'id', value: item }))
            dispatch(setUserInputFields({ field: 'fullName', value: filteredApplication[0].value }))
        }

    }





    return (
        <>
            <div className={userStyles.dFlex}>
                {((selectedUserType === 'whitelisted_client' || selectedUserType === 'machine') && prop.mode === 'edit') && <div className={userStyles.marginBottom}>
                    <Label className={userStyles.userLabel}>Application name<span style={{ color: '#C91514' }}>*</span></Label>
                    <TextInput
                        id="search-applications"
                        disabled={true}
                        value={selectedApplicationName}
                        variant={"outlined"}
                        placeholder="Search application"
                        maxWidth={true}
                        style={{ width: '100%' }}
                    />

                </div>}
                {((selectedUserType === 'whitelisted_client' || selectedUserType === 'machine') && prop.mode === 'add') && <div className={userStyles.marginBottom}>
                    <Label className={userStyles.userLabel}>Search applications <span style={{ color: '#C91514' }}>*</span></Label>
                    <TextInput
                        id="search-applications"
                        // disabled={mode === "edit" && selectedUserData.name.trim() !== ""}
                        value={searchText}
                        variant={"outlined"}
                        placeholder="Search application"
                        maxWidth={false}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={handleKeyDown}
                        style={{ width: '100%' }}
                    />

                </div>}
                {(((selectedUserType === 'whitelisted_client' || selectedUserType === 'machine') && prop.mode === 'add') && applicationsList.length > 0 && searchText !== '') && <div className={userStyles.marginBottom}>
                    <Label className={userStyles.userLabel}>Select applications <span style={{ color: '#C91514' }}>*</span></Label>
                    <div style={{ width: '100%' }}>
                        <SelectBox
                            placeholder="Select application"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={selectedApplication}
                            data={applicationsList}
                            onUpdateSelection={handleApplicationSelection}
                            isLoading={false}
                        />
                    </div>

                </div>}
                {(selectedUserType === 'user' || selectedUserType === 'machine' || prop.mode === 'edit') && (
                    <div className={userStyles.marginBottom}>
                        <Label className={userStyles.userLabel}>Role name <span style={{ color: '#C91514' }}>*</span></Label>
                        <div style={{ width: '100%' }}>
                            <SelectBox
                                placeholder="Select role name"
                                maxWidth={true}
                                caseInsentiveMatching={true}
                                selectedItem={Object.keys(selectedRoles)[0]}  //it will be changed in future
                                data={rolesBasedOnOrgType}
                                onUpdateSelection={handleRolesSelection}
                                isLoading={false}
                            />
                        </div>
                    </div>
                )}
            </div>

        </>
    )
}

export default CommonFormFields;