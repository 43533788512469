import React, { useRef, useState, useEffect, memo } from 'react';
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import Button from '@nokia-csf-uxr/ccfk/Button';
import editIcon from '../../../Assets/Icons/pencil_icon.svg';
import userActiveIcon from '../../../Assets/Icons/user-check.svg';
import userInActiveIcon from '../../../Assets/Icons/user-delete.svg';
//import  from '../../../Assets/Icons/edit_icon.svg';
import trashIcon from '../../../Assets/Icons/trash_icon_new.svg';
import OOT from 'Constants/services/oot';
import { setUsers, setUsersLoading } from 'Store/contentState/userManagementSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "Store/mainStore";
import Chip, {
  ChipLabel,
} from '@nokia-csf-uxr/ccfk/Chip';
import Loader from 'Components/Elements/Loader';
import DeleteUser from './DeleteUser';
import Pagination from 'Pages/marketplace/common/Pagination';
import { setOffset, resetPaginationsProps } from 'Store/contentState/paginationSlice';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUserActions } from './hooks/useUserActions';
import { Typography } from '@nokia-csf-uxr/ccfk';
import userStyles from './Style/UserManagement.module.css';
import UserDialogNew from './UserDialogNew';
import UserDetailsOverlay from './components/UserDetailsOverlay';
import { setUser } from 'Store/auth';

const UsersList = (prop) => {
  const gridApi = useRef(null);
  const dispatch = useDispatch();
  const userManagementSelector = useSelector((state: RootState) => state.userManagement);
  const paginationSelector = useSelector((state: RootState) => state.pagination);
  const globalSearchText = useSelector((state: RootState) => state.common.globalSearchText);
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);
  const [showEditUserDialog, setShowEditUserDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const { currentPage, limitPerpage } = paginationSelector.paginationProps;
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [clickedOnActionButton, setClickedOnActionButton] = useState(false);

  const hasFetchedData = useRef(false);


  useEffect(() => {
    const offset = (currentPage - 1) * limitPerpage;
    dispatch(setOffset(offset));
    if (prop.data.isActive) {
      getUsersList(limitPerpage, offset);
    }

  }, [globalSearchText, currentPage, limitPerpage, userManagementSelector.userFilters, prop.data.isActive, prop.data.type]);

  const prepareFilterConditions = () => {
    const filterConditions = [];

    // Global search text filter
    if (globalSearchText) {
      filterConditions.push(`(users.name LIKE '%${globalSearchText}%' OR users.email LIKE '%${globalSearchText}%')`);
    }

    // Role name filter
    const selectedRole = userManagementSelector.userFilters.role; // Assume this is a string like 'owner'

    if (selectedRole) {
      // Generate the SQL query to check for the selected role in the JSON column
      const roleCondition = `JSON_EXTRACT(user_roles.role, '$.roles.${selectedRole}') IS NOT NULL`;
      filterConditions.push(roleCondition);
    }

    // // Market filter
    // if (userManagementSelector.selectedMarket) {
    //   filterConditions.push(`users.market = '${userManagementSelector.selectedMarket}'`);
    // }

    // // Account group filter
    // if (userManagementSelector.selectedAccountGroup) {
    //   filterConditions.push(`users.account_group = '${userManagementSelector.selectedAccountGroup}'`);
    // }

    // Combine all conditions into a single query
    return filterConditions.length > 0 ? filterConditions.join(' AND ') : '1=1';
  };


  const getUsersList = async (limitPerpage, offset) => {
    dispatch(setUsersLoading(true));
    const filterConditions = prepareFilterConditions();
    try {
      const response = await OOT.getAllUsers(`${filterConditions} order by users.email, users.type desc`, limitPerpage, offset, prop.data.type);
      if (response.status) {
        const updatedUserList = response.data.map((user) => {
          const role = user.role || {};
          // return {
          //   id: user.id,
          //   name: user.name ? user.name : '',
          //   email: user.email ? user.email : '',
          //   status: user.active === 1 ? 'Active' : 'Inactive',
          //   userTypeInList: user.role.persona.includes("nokia") ? 'Nokia' : 'External',
          //   userType: user.role.persona,
          //   viewAccess: user.role.view,
          //   userRoles: user.role.roles,
          //   dataElement: user.role['data-element'],
          //   oneStarNum: user.role.one_star,
          //   //type: user.type,
          //   threeStarNum: user.role.three_star,
          //   whitelisted_ip: user.role.whitelisted_ip,
          //   markets: user.role.markets,
          //   business_name: user?.role?.business_name || []

          // }
          return {
            id: user.id,
            name: user.name || '',
            email: user.email || '',
            status: user.active === 1 ? 'Active' : 'Inactive',
            userTypeInList: role.persona?.includes("nokia") ? 'Nokia' : 'External',
            userType: role.persona || [],
            viewAccess: role.view || [],
            userRoles: role.roles || [],
            dataElement: role['data-element'] || {},
            oneStarNum: role.one_star || [],
            threeStarNum: role.three_star || [],
            whitelisted_ip: role.whitelisted_ip || {},
            markets: role.markets || [],
            business_name: role.business_name || []
          };
        })
        console.log('updatedUserList:', updatedUserList);
        dispatch(setUsers(updatedUserList));
        hasFetchedData.current = true;
        dispatch(setUsersLoading(false));
      } else {
        dispatch(setUsersLoading(false));
        console.log("No users available")
      }
    } catch (error) {
      dispatch(setUsersLoading(false));
      console.error(error)
    }
  }

  if (userManagementSelector.isUsersLoading) {
    return <Loader />
  }




  const handleShowDeleteModal = (user) => {
    setClickedOnActionButton(true);
    setShowDeleteUserDialog(true);
    setSelectedUser(user);
  }

  const handleEditUser = (user) => {
    setClickedOnActionButton(true);
    setShowEditUserDialog(true);
    setSelectedUser(user);
  }

  const handleDisableUser = (user) => {
    // Toggle the `isDisabled` property
    const updatedUsers = userManagementSelector.users.map((u) =>
      u.id === user.id ? { ...u, isDisabled: !u.isDisabled } : u
    );

    console.log('Toggled user:', updatedUsers.find(u => u.id === user.id));
    // Dispatch the updated list to Redux store
    dispatch(setUsers(updatedUsers)); // Assuming `setUsers` is your Redux action to update users
  };

  const handleGetUserDetails = (user) => {
    console.log(user)
    setClickedOnActionButton(true);
    setShowUserDetails(true);
    setSelectedUser(user);
  }

  const commonColumnDefs = [
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 240
    },
    {
      headerName: 'Email',
      field: 'email',
      minWidth: 240,
      flex: 2
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 115,
      cellRenderer: (params) => (
        <div>
          {params.data.status === 'Active' && (
            <Chip size="small" style={{ backgroundColor: '#E4F0FF', border: '1px solid #8EC2FF', marginRight: '.5rem', borderRadius: '15px' }}>
              <ChipLabel label={params.data.status} />
            </Chip>
          )}
          {params.data.status === 'Inactive' && (
            <Chip size="small" style={{ backgroundColor: '#FFF1E4', border: '1px solid #FFD0A5', marginRight: '.5rem', borderRadius: '15px' }}>
              <ChipLabel label={params.data.status} />
            </Chip>
          )}
        </div>
      )
    }
  ];

  // const specificColumnDefs =
  //   prop.data.type === 'whitelisted_client'
  //     ? []
  //     : prop.data.type === 'machine'
  //       ? [
  //         {
  //           headerName: 'IP List',
  //           field: 'whitelisted_ip.ip_ranges',
  //           minWidth: 240,
  //           cellRenderer: (params) => {
  //             const ipList = params.value || [];
  //             const maxIPsToShow = 2;
  //             return (
  //               <Typography title={ipList.join(', ')}>
  //                 {ipList.slice(0, maxIPsToShow).join(', ')}
  //                 {ipList.length > maxIPsToShow ? ', ...' : ''}
  //               </Typography>
  //             );
  //           }
  //         }
  //       ]
  //       : [
  //         {
  //           headerName: 'Type',
  //           field: 'userTypeInList',
  //           minWidth: 150,
  //           flex: 2
  //         },
  //         {
  //           headerName: 'Role name',
  //           field: 'userRoles',
  //           minWidth: 240,
  //           flex: 2,
  //           cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
  //           valueFormatter: (params) =>
  //             params.value && typeof params.value === 'object'
  //               ? Object.values(params.value).join(', ')
  //               : '',
  //           cellRenderer: (params) => {
  //             const roles = params?.data?.userRoles ? Object.values(params.data.userRoles) : [];
  //             const maxRolesToShow = 2;
  //             return (
  //               <div title={roles.length > 0 ? roles.join(', ') : ' - '}>
  //                 {roles.length > 0 ? roles.slice(0, maxRolesToShow).join(', ') : ' - '}
  //                 {roles.length > maxRolesToShow ? '...' : ''}
  //               </div>
  //             );
  //           }
  //         },
  //         {
  //           headerName: 'Permissions',
  //           field: 'viewAccess',
  //           cellRenderer: (params) => {
  //             const { value } = params;
  //             const maxPermissionsToShow = 2;
  //             if (Array.isArray(value)) {
  //               const displayedPermissions = value.slice(0, maxPermissionsToShow).join(', ');
  //               const hasMore = value.length > maxPermissionsToShow;
  //               return (
  //                 <Typography title={value.join(', ')}>
  //                   {displayedPermissions}{hasMore ? ', ...' : ''}
  //                 </Typography>
  //               );
  //             } else {
  //               return '';
  //             }
  //           }
  //         }
  //       ];


  const specificColumnDefs = [
    ...(prop.data.type !== 'whitelisted_client' ? [
      // Type column for "user" and "machine"
      {
        headerName: 'Type',
        field: 'userTypeInList',
        minWidth: 150,
        flex: 2
      },
      // Role Name column for "user" and "machine"
      {
        headerName: 'Role name',
        field: 'userRoles',
        minWidth: 150,
        flex: 2,
        cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
        valueFormatter: (params) =>
          params.value && typeof params.value === 'object'
            ? Object.values(params.value).join(', ')
            : '',
        cellRenderer: (params) => {
          const roles = params?.data?.userRoles ? Object.values(params.data.userRoles) : [];
          const maxRolesToShow = 2;
          return (
            <div title={roles.length > 0 ? roles.join(', ') : ' - '}>
              {roles.length > 0 ? roles.slice(0, maxRolesToShow).join(', ') : ' - '}
              {roles.length > maxRolesToShow ? '...' : ''}
            </div>
          );
        }
      },
      // Permissions column for "user" and "machine"
      {
        headerName: 'Permissions',
        field: 'viewAccess',
        cellRenderer: (params) => {
          const { value } = params;
          const maxPermissionsToShow = 2;
          if (Array.isArray(value)) {
            const displayedPermissions = value.slice(0, maxPermissionsToShow).join(', ');
            const hasMore = value.length > maxPermissionsToShow;
            return (
              <Typography title={value.join(', ')}>
                {displayedPermissions}{hasMore ? ', ...' : ''}
              </Typography>
            );
          } else {
            return '';
          }
        }
      }
    ] : []),
    // IP List column specifically for "machine" type
    ...(prop.data.type === 'machine' ? [
      {
        headerName: 'IP address/range',
        field: 'whitelisted_ip',
        minWidth: 240,
        cellRenderer: (params) => {
          const ipList = Array.isArray(params.value?.ip_list) ? params.value.ip_list : [];
          const ipRanges = Array.isArray(params.value?.ipranges) ? params.value.ip_ranges : [];
          const maxItemsToShow = 2;
      
          // Format IP ranges as "start - end"
          const formattedRanges = ipRanges.map(range => `${range.start} - ${range.end}`);
      
          // Combine IP list and formatted ranges, limiting to the max items to show
          const allIPs = [...ipList, ...formattedRanges];
          const displayedIPs = allIPs.slice(0, maxItemsToShow).join(', ');
          const hasMore = allIPs.length > maxItemsToShow;
      
          return (
            <Typography title={allIPs.join(', ')}>
              {displayedIPs}
              {hasMore ? ', ...' : ''}
            </Typography>
          );
        }
      }
      
    ] : [])
  ];


  // Define the action buttons column separately to apply it to all types
  const actionButtonsColumn = {
    minWidth: 30,
    cellRenderer: (params) => {
      const isDisabled = params.data.isDisabled;
      return (
        <div className={userStyles.dFlex5}>
          <img
            src={editIcon}
            className="action-button"
            onClick={(event) => {
              setClickedOnActionButton(true);
              handleEditUser(params.data);
            }}
          />
          <img
            src={isDisabled ? userActiveIcon : userInActiveIcon}
            onClick={() => {
              setClickedOnActionButton(true);
              handleDisableUser(params.data);
            }}
            className={userStyles.toggleButton}
          />
          <img
            src={trashIcon}
            onClick={() => {
              setClickedOnActionButton(true);
              handleShowDeleteModal(params.data);
            }}
          />
        </div>
      );
    }
  };

  // Combine all column definitions
  const columnDefs = [...commonColumnDefs, ...specificColumnDefs, actionButtonsColumn];

  return (
    <>

      {showUserDetails && <UserDetailsOverlay onClose={() => setShowUserDetails(false)} selectedUserData={selectedUser} />
      }
      {showDeleteUserDialog && (
        <DeleteUser onClose={() => {
          setShowDeleteUserDialog(false)
        }} userId={selectedUser['id']} typeOfUser={prop.data.type} />
      )}

      {/* {showEditUserDialog && (
        <UserDialogModal onClose={() => setShowEditUserDialog(false)} selectedUserData={selectedUser} mode="edit" typeOfUser={prop.data.type}/>
      )} */}

      {showEditUserDialog && (
        <UserDialogNew onClose={() => {
          setShowEditUserDialog(false)
        }} selectedUserData={selectedUser} mode="edit" typeOfUser={prop.data.type} />
      )}

      <div style={{ width: '100%', height: '100%', marginTop: '10px' }}>
        {/* <DataGrid
          onRowClicked={(params) => {
            if (!clickedOnActionButton) {
              handleGetUserDetails(params.data);
            }
            setClickedOnActionButton(false);
          }
          }
          suppressMovableColumns={false}
          suppressColumnMoveAnimation={false}
          suppressRowClickSelection={false}
          isRowDrag={false}
          wrapperProps={{
            style: {
              width: "100%"
            }
          }}
          sideBar
          disableAutoloadStyles
          suppressContextMenu
          onGridReady={(params) => {
            gridApi.current = params.api;
          }}
          rowData={userManagementSelector.users}
          columnDefs={[{ headerName: 'Name', field: 'name', minWidth: 240 },

          { headerName: 'Email', field: 'email', minWidth: 240, flex: 2 },
          { headerName: 'Type', field: 'userTypeInList', minWidth: 150, flex: 2 },
          {
            headerName: 'Role name', field: 'userRoles', minWidth: 240, flex: 2,
            cellStyle: {
              whiteSpace: 'nowrap',     // Keep content on a single line
              overflow: 'hidden',       // Hide overflowing content
              textOverflow: 'ellipsis', // Show the "..." when content is too long
            },
            valueFormatter: (params) => {
              return params.value && typeof params.value === 'object'
                ? Object.values(params.value).join(', ')
                : '';
            },
            cellRenderer: (params) => {
              const roles = params?.data?.userRoles ? Object.values(params.data.userRoles) : []; 
              const maxRolesToShow = 2; 

              return (
                <div title={roles.length > 0 ? roles.join(', ') : ' - '}>
                  {roles.length > 0 ? roles.slice(0, maxRolesToShow).join(', ') : ' - '}
                  {roles.length > maxRolesToShow ? '...' : ''}
                </div>
              );
            }
          },
          {
            headerName: 'Status',
            field: 'status',
            minWidth: 115,
            cellRenderer: (params) => {
              return (
                <div>
                  {params.data.status === 'Active' && <Chip size="small" style={{ backgroundColor: '#E4F0FF', border: '1px solid #8EC2FF', marginRight: '.5rem', borderRadius: '15px' }}><ChipLabel label={params.data.status} /></Chip>}
                  {params.data.status === 'Inactive' && <Chip size="small" style={{ backgroundColor: '#FFF1E4', border: '1px solid #FFD0A5', marginRight: '.5rem', borderRadius: '15px' }}><ChipLabel label={params.data.status} /></Chip>}
                </div>
              );

            }

          },
          {
            headerName: 'Permissions',
            field: 'viewAccess',
            cellRenderer: (params) => {
              const { value } = params;
              const maxPermissionsToShow = 2; 

              if (Array.isArray(value)) {
                const displayedPermissions = value.slice(0, maxPermissionsToShow).join(', '); 
                const hasMore = value.length > maxPermissionsToShow; 

                return (
                  <Typography title={value.join(', ')}>
                    {displayedPermissions}{hasMore ? ', ...' : ''}  
                  </Typography>
                );
              } else {
                return ''; 
              }
            },
          },

          {
            minWidth: 30,
            // flex: 0.3,
            hide: !true,
            cellRenderer: (params) => {
              const isDisabled = params.data.isDisabled; 
              return (
                <div className={userStyles.dFlex5}>
                  <img src={editIcon} className='action-button' onClick={(event) => {
                    setClickedOnActionButton(true)
                    handleEditUser(params.data)
                  }
                    }/>
                  <img
                    src={isDisabled ? userActiveIcon : userInActiveIcon} 
                    onClick={() => {
                      setClickedOnActionButton(true)
                      handleDisableUser(params.data)
                    }}
                    className={userStyles.toggleButton}
                  />
                  <img src={trashIcon} onClick={() => {
                    setClickedOnActionButton(true)
                    handleShowDeleteModal(params.data)
                    }} />
                </div>
              );
            }
          }
          ]}
          key={'users'}
          defaultColDef={{
            editable: false,
            sortable: true,
            flex: 0,
            minWidth: 30,
            filter: true,
            resizable: false,
          }}
          overlayNoRowsTemplate='No users are available'
          isInlineRowActions={false}
          domLayout='autoHeight'
          onFirstDataRendered={(params) => {
            params.columnApi.autoSizeAllColumns();
          
          }}
          getRowClass={(params) => {
            return params.data.isDisabled ? userStyles.disabledRow : '';
          }}
        /> */}

        <DataGrid
          isRowDrag={false}
          onRowClicked={(params) => {
            if (!clickedOnActionButton) {
              handleGetUserDetails(params.data);
            }
            setClickedOnActionButton(false);
          }}
          wrapperProps={{
            style: { width: '100%' }
          }}
          sideBar
          disableAutoloadStyles
          suppressContextMenu
          onGridReady={(params) => {
            gridApi.current = params.api;
          }}
          rowData={userManagementSelector.users}
          columnDefs={columnDefs}
          key={'users'}
          defaultColDef={{
            editable: false,
            sortable: true,
            flex: 0,
            minWidth: 30,
            filter: true,
            resizable: false
          }}
          overlayNoRowsTemplate="No users are available"
          isInlineRowActions={false}
          domLayout="autoHeight"
          onFirstDataRendered={(params) => {
            params.columnApi.autoSizeAllColumns();
          }}
          getRowClass={(params) => {
            return params.data.isDisabled ? userStyles.disabledRow : '';
          }}
        />

      </div>

      {/* <Pagination disableProp={userManagementSelector.users} /> */}



    </>
  )
}

export default memo(UsersList);