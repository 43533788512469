import React, { useEffect } from "react";
import classes from '../marketplace/Style/ProductSpecification.module.css'
import { Typography } from "@nokia-csf-uxr/ccfk";
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import Button from '@nokia-csf-uxr/ccfk/Button';
import cartAdd from '../../Assets/Icons/cart-add.svg';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import { setSelectedDeviceSpecification } from 'Store/contentState/marketplaceSlice';
import Spinner from '@nokia-csf-uxr/ccfk/Spinner';
import useCartActions from "./hooks/useCartActions";
import warrantyIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/security.svg';
import shipIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/ship.svg';
import factoryIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/factory.svg';
import {formatNumberWithCommas, getFulFillmentType, getSoldByInfo} from './Utils/commons';
import PropTypes from "prop-types"; 
import PriceDisplay from "./UIElements/PriceDisplay";




const ProductSpecification = ({ onAddToCartSnackbar }) => {
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const { addToCart } = useCartActions();
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    let selectedData = marketplaceSelector.selectedDeviceSpecification;

    

    useEffect(() => {
        const productInCart = marketplaceSelector.cart.find(item => item.id === selectedData[0]?.id);
        if (productInCart && (productInCart.product_quantity !== selectedData[0]?.product_quantity)) {
            const updatedSelectedData = [{
                ...selectedData[0],
                product_quantity: productInCart.product_quantity,
                product_total_cost: (productInCart.product_quantity * parseInt(selectedData[0]?.price_amount)).toFixed(2)
            }];
            dispatch(setSelectedDeviceSpecification({ selectedProduct: updatedSelectedData }));
        }
    }, [marketplaceSelector.cart, dispatch]); // Only depend on 'cart' and 'dispatch', and ensure conditions inside prevent unnecessary updates

    const handleQuantityChange = (event) => {
        const newQuantity = event.value; // Ensure the value is an integer
        const newTotalCost = (newQuantity * parseInt(selectedData[0]?.price_amount)).toFixed(2);

        const updatedSelectedData = [{
            ...selectedData[0],
            product_quantity: newQuantity,
            product_total_cost: newTotalCost
        }];

        dispatch(setSelectedDeviceSpecification({ selectedProduct: updatedSelectedData }));
    };

    const handleAddToCart = () => {
        addToCart(selectedData[0], selectedData[0]['product_quantity'], true);
        onAddToCartSnackbar(`${selectedData[0]['product_description']} added in the cart.`);
    };
    return (
        <>
            <div className={classes.productSpecification}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '.5rem' }} >
                    <div style={{ fontWeight: 'bold', fontSize: '.85rem', padding: '.5rem', marginRight: 'auto!important' }}>Price</div>


                  

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '.4rem' }}>
                        <Typography style={{ fontSize: '.8rem' }}>Unit price</Typography>
                        <div className={classes.priceChip}>
                            {`${formatNumberWithCommas(selectedData[0]?.price_amount)} ${selectedData[0]?.priceamount_currencycode}`}
                        </div>
                    </div>


                </div>
                <div style={{ borderBottom: '1px solid #D7D7D7' }}></div>

                <div style={{ display: 'flex', justifyContent:'space-between',  alignItems: 'center', gap: '.4rem', padding:'1.5rem' }}>
                    <div>
                    <Typography style={{ fontSize: '.8rem' }}>Quantity</Typography>
                    <Spinner
                        inputProps={{
                            inputProps: {
                                'aria-label': `Sp  inner ${selectedData[0]?.product_quantity}`,
                            },
                            id: "spinner-1"
                        }}
                        variant="outlined"

                        min={1}
                        max={100}
                        step={1}
                        incrementButtonProps={{ 'aria-label': `Value ${selectedData[0]?.product_quantity}. Increment` }}
                        decrementButtonProps={{ 'aria-label': `Value ${selectedData[0]?.product_quantity}. Decrement` }}
                        readOnly={false}
                        onChange={(e) => handleQuantityChange(e)}
                        value={selectedData[0]?.product_quantity}
                        style={{ height: '27px', width: '82px' }}
                    />
                    </div>
                   
                   <div>
                    <Typography>{<PriceDisplay price={selectedData[0]?.price_amount}/>} x {selectedData[0]?.product_quantity}</Typography>
                   </div>
                    
                </div>
                
                <div style={{padding:'1.5rem'}}>
                {(selectedData[0]?.is_agent_model === 0 && selectedData[0]?.product_type_id === 'Hardware') && 
                <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', marginBottom:'5px'}}>
                  <img src={warrantyIcon} style={{marginRight: '3px'}}/>
                  <Typography>Warranty: 1 year</Typography>
                </div>}
               {selectedData[0] && getSoldByInfo(selectedData[0]) && <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', marginBottom:'5px'}}>
                  <img src={factoryIcon} alt='ship_icon' style={{marginRight: '3px'}}/>
                  <Typography>Sold by: {getSoldByInfo(selectedData[0])}</Typography>
                </div>}
                {selectedData[0] && getFulFillmentType(selectedData[0]) &&<div style={{display:'flex', justifyContent: 'center', alignItems: 'center', marginBottom:'5px'}}>
                  <img src={shipIcon} alt='ship_icon' style={{marginRight: '3px'}}/>
                  <Typography>Ships from : {getFulFillmentType(selectedData[0])}</Typography>
                </div>}
                </div>

                <div style={{position:'relative', float:'right', bottom:'-42px', padding:'1.5rem'}}>
                  <Typography>Total:  <span style={{fontSize:'16px'}}>{<PriceDisplay price={selectedData[0]?.product_total_cost}/>} {selectedData[0]?.priceamount_currencycode}</span></Typography>
                </div>

                {/* <div style={{ borderBottom: '1px solid #D7D7D7' }}></div> */}
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '7rem', padding: '.5rem', borderTop: '1px solid #D7D7D7', alignItems:'center' }}>
                    <Typography style={{fontSize:'12px'}}>Additional discounts applicable: Add the item to the cart to see the final breakup</Typography>
                    <Button
                        variant="call-to-action"
                        onClick={handleAddToCart}
                    >
                        <ButtonIcon><img src={cartAdd} /></ButtonIcon>
                        <ButtonText>Add to cart</ButtonText>

                    </Button>
                </div>
            </div>
        </>
    )
}

export default ProductSpecification;

ProductSpecification.propTypes = {
    onAddToCartSnackbar: PropTypes.func,
   
 }