import React, { useState, useRef, useEffect } from 'react';
import _findIndex from 'lodash/findIndex';
// FF icon
import ChevronDownSmallIcon from '@nokia-csf-uxr/ccfk-assets/latest/ChevronDownSmallIcon';
import CloseSmallIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseSmallIcon';

import Label from '@nokia-csf-uxr/ccfk/Label';
import Avatar from '@nokia-csf-uxr/ccfk/Avatar';

import SelectItem, {
  SelectItemLabelContent,
  SelectListItem,
  SelectItemText,
  SelectItemButton,
  SelectItemClearButton,
} from '@nokia-csf-uxr/ccfk/SelectItem';

import Chip, {
  ChipLabel,
  ChipIconButton
} from '@nokia-csf-uxr/ccfk/Chip';


const isSelectionKeyPressed = key => key && (key === ENTER_KEY || key === SPACE_KEY);
const DELETE = 'Delete';
const BACKSPACE = 'Backspace';
const ENTER_KEY = 'Enter';
const SPACE_KEY = ' ';

const CHIP_STYLE = {
  style: {
    margin: '0.1875rem 0.125rem 0.125rem 0.125rem'
  }
};



/** Example of non-searchable SelectItem with mutiple selections allowed. */
// const MultiSelect = (prop) =>{
//   const selectItemRef = useRef(null);
//   const selectItemButtonRef = useRef(null);
//   const [values, setValues] = useState([]);
//   const [isOpen, setIsOpen] = useState(false);

//   const ariaString = () => values.map((item) => {return item}).toString();
//   const haveValues = () => values.length > 0;

//   // handle list item selection and close dropdown list after item is selected
//   const handleEvent = (index) => (event) => {
//     const { type } = event;
//     const isValueAlreadySelected = value => _findIndex(values, item => item['id'] === value['id']) > -1;
//     const theValue = prop.data[index];
//     if (type === 'keydown') {
//       if (isSelectionKeyPressed(event.key)) {
//         if (!isValueAlreadySelected(theValue)) {
//           const newValues = [...values, theValue ];
//           setValues(newValues);
//           setOnSelectedValues(newValues);
//         }
//         setIsOpen(false);
//       }
//     } else if (type === 'click') {
//       if (!isValueAlreadySelected(theValue)) {
//         const newValues = [...values, theValue ];
//         setValues(newValues);
//         setOnSelectedValues(newValues);
//       }
//       setIsOpen(false);
//     }
    
//   };

//   const setOnSelectedValues = (selectedValues) => {
//     prop.onSelected(selectedValues);
//   }

//   // remove closed chip from selected values
//   const handleChipClose = (value) => () => {
//     const valueIndex = _findIndex(values, item => item['id'] === value['id']);
//     if (valueIndex > -1) {
//       const clonedValues = [...values];
//       clonedValues.splice(valueIndex, 1);
//       setValues(clonedValues);
//       selectItemButtonRef.current && selectItemButtonRef.current.focus();
//       setOnSelectedValues(clonedValues)
//     }
//   };

//   const renderClearButton = (props) => {
//     return (
//       <SelectItemClearButton
//         aria-label="clear input"
//         onClick={() => {setOnSelectedValues([]); selectItemButtonRef.current && selectItemButtonRef.current.focus();}}
//         {...props}
//         >
//         <CloseSmallIcon />
//       </SelectItemClearButton>
//     );
//   };

//   const renderSelectItemBase = (props)  => {
//     // only show ClearButton when there is at least 1 item selected and the menu is opened
//     const showClearButton = haveValues() && isOpen;
//     return (
//       <SelectItemButton
//         ref={selectItemButtonRef}
//         placeholder={prop.placeholder}
//         dropdownIcon={<ChevronDownSmallIcon />}
//         inputProps={{ value: haveValues() ? ariaString() : prop.placeholder }}
//         renderClearButton={showClearButton ?  renderClearButton : undefined}
//         role="combobox"
//         {...props}
//       >
//         {haveValues() && values.map((item, x) => {
//           return (
//             <Chip
//               key={`chip-${item['id']}-${x}`}
//               tabIndex={0}
//               role="comment"
//               size="small"
//               aria-label={item['id']}
//               {...CHIP_STYLE}
//               onClick={(event) => {event.stopPropagation();}}
//               onKeyDown={(event) => {
//                 if (event.key === BACKSPACE || event.key === DELETE) {
//                   handleChipClose(item)();
//                 }}}
//             >
//               <ChipLabel label={item['value']} />
//               <ChipIconButton aria-label={`remove ${item}`} onClick={handleChipClose(item)}><CloseSmallIcon /></ChipIconButton>
//             </Chip>
//         )})}
//       </SelectItemButton>
//     )
//   };

//   return (
//     <>
   
//       <Label
//         id="selectitem-component-label"
//         variant="vertical-layout"
//       >
//        <SelectItemLabelContent variant='default'>
//           {prop.label}
//         </SelectItemLabelContent>
//       </Label>
      
//         <SelectItem
//           ref={selectItemRef}
//           aria-labelledby="selectitem-component-label"
//           aria-label={haveValues() ? ariaString() : prop.placeholder}
//           multiSelect
//           max-width={true}
//           isOpen={isOpen}
//           onOpen={() => { setIsOpen(true); }}
//           onClose={() => { setIsOpen(false); }}
//           truncateListText
//           listProps={{ ulProps: { role: 'listbox' } }}
//           renderSelectItemBase={renderSelectItemBase}
//         >
//           {prop.data.map((item, x) => {
//             return (
//               <SelectListItem
//                 role="option"
//                 key={`${item.id}-${x}`}
//                 selected={_findIndex(values, value => value['id'] === item['id']) > -1}
//                 onClick={handleEvent(x)}
//                 onKeyDown={handleEvent(x)}
//                 aria-label={item.id}
//                 style={{ height: '3rem', minHeight: '3rem',  maxHeight: '3rem'}}
//               >
//                 {/* <Avatar size="medium">{item.id.substring(0,2).toUpperCase()}</Avatar> */}
//                 <SelectItemText style={{ paddingLeft: '1rem',}} >{item.value}</SelectItemText>
//               </SelectListItem>
//             );
//           })}
//         </SelectItem>
        
//     </>
//   )
// };

const MultiSelect = (prop) => {
  const selectItemRef = useRef(null);
  const selectItemButtonRef = useRef(null);
  const [values, setValues] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const ariaString = () => values.map((item) => item.value).toString();
  const haveValues = () => values.length > 0;

  // Update internal state when the selectedItems prop changes
  useEffect(() => {
    setValues(prop.selectedItems || []); // Initialize state with selectedItems
  }, [prop.selectedItems]);

  const handleEvent = (index) => (event) => {
    const { type } = event;
    const isValueAlreadySelected = (value) => values.some((item) => item.id === value.id);
    const theValue = prop.data[index];

    if (type === "keydown" || type === "click") {
      if (!isValueAlreadySelected(theValue)) {
        const newValues = [...values, theValue];
        setValues(newValues);
        setOnSelectedValues(newValues);
      }
      setIsOpen(false);
    }
  };

  const setOnSelectedValues = (selectedValues) => {
    prop.onSelected(selectedValues); // Pass the selected values to the parent component
  };

  const handleChipClose = (value) => () => {
    const newValues = values.filter((item) => item.id !== value.id);
    setValues(newValues);
    setOnSelectedValues(newValues);
    selectItemButtonRef.current && selectItemButtonRef.current.focus();
  };

  const renderClearButton = (props) => {
    return (
      <SelectItemClearButton
        aria-label="clear input"
        onClick={() => {
          setOnSelectedValues([]);
          setValues([]);
          selectItemButtonRef.current && selectItemButtonRef.current.focus();
        }}
        {...props}
      >
        <CloseSmallIcon />
      </SelectItemClearButton>
    );
  };

  const renderSelectItemBase = (props) => {
    const showClearButton = haveValues() && isOpen;
    const selectedCount = values.length;
    return (
      <SelectItemButton
        ref={selectItemButtonRef}
        placeholder={prop.placeholder}
        dropdownIcon={<ChevronDownSmallIcon />}
        inputProps={{ value: haveValues() ? ariaString() : prop.placeholder }}
        renderClearButton={showClearButton ? renderClearButton : undefined}
        role="combobox"
        {...props}
      >
        {haveValues() &&
          values.map((item, x) => (
            <Chip
              key={`chip-${item.id}-${x}`}
              tabIndex={0}
              role="comment"
              size="small"
              aria-label={item.value}
              {...CHIP_STYLE}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onKeyDown={(event) => {
                if (event.key === BACKSPACE || event.key === DELETE) {
                  handleChipClose(item)();
                }
              }}
              disabled={prop.disabled}
            >
              <ChipLabel label={item.value} />
              <ChipIconButton aria-label={`remove ${item}`} onClick={handleChipClose(item)}>
                <CloseSmallIcon />
              </ChipIconButton>
            </Chip>
          ))}
          {/* Render the selected count instead of individual chips */}
         {/* {haveValues() && !isOpen && `${selectedCount} selected`} */}
      </SelectItemButton>
    );
  };

  return (
    <>
      {/* <Label id="selectitem-component-label" variant="vertical-layout">
        <SelectItemLabelContent variant="default">{prop.label}</SelectItemLabelContent>
      </Label> */}
      <SelectItem
        ref={selectItemRef}
        aria-labelledby="selectitem-component-label"
        aria-label={haveValues() ? ariaString() : prop.placeholder}
        multiSelect
        max-width={true}
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        truncateListText
        listProps={{ ulProps: { role: "listbox" } }}
        renderSelectItemBase={renderSelectItemBase}
        disabled={prop.disabled}
      >
        {prop.data.map((item, x) => (
          <SelectListItem
            role="option"
            key={`${item.id}-${x}`}
            selected={values.some((value) => value.id === item.id)}
            onClick={handleEvent(x)}
            onKeyDown={handleEvent(x)}
            aria-label={item.id}
            style={{ height: "3rem", minHeight: "3rem", maxHeight: "3rem" }}
          >
            <SelectItemText style={{ paddingLeft: "1rem" }}>{item.value}</SelectItemText>
          </SelectListItem>
        ))}
      </SelectItem>
    </>
  );
};

export default MultiSelect;