import { useState, useEffect, useCallback } from 'react';
import OOT from 'Constants/services/oot';
import { useDispatch } from 'react-redux';
import { setRolesInfo } from 'Store/contentState/userManagementSlice';


const useFetchRoles = () => {

    const dispatch = useDispatch();

    const fetchUserRoles = async () => {
        dispatch(setRolesInfo({ field: 'loading', value: true }));
        try {
            const response = await OOT.getUserRoles("");
            if (response.status) {
                dispatch(setRolesInfo({ field: 'rolesData', value: response.data }));
                dispatch(setRolesInfo({ field: 'loading', value: false }));
            } else {
                dispatch(setRolesInfo({ field: 'loading', value: false }));
            }
        } catch (error) {
            dispatch(setRolesInfo({ field: 'loading', value: false }));
        }

    }

    return {fetchUserRoles }
    
};

export default useFetchRoles;