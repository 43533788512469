
import { encodeBase64 } from "Components/Logics/ensoCommonFunctions";
import {
  ROLE,
  STATUS,
  caseSubTypes,
  caseTypesMap,
  extensionOfferTools,
  mpwSegmentSolution,
  sCRMRegex,
  salesChannelLogic,
  toolRedirect,
} from "Components/Misc/CaseDetails_System";
import OOT from "Constants/services/oot";
import {
  changeAppState,
  resetAuth,
  setAccount,
  setError,
  showLoader,
  showModal,
  updateAccount,
} from "Store/auth";
import { resetCD, setData, setOBJ, setStatus } from "Store/contentState/caseDetailsSlice";
import { resetFC } from "Store/contentState/findCaseSlice";
import { RootState, store } from "Store/mainStore";
import { setLive, setVisible } from "Store/theme";
import { generateSyncOfferItems } from "Utils";

/* LOGIC FOR SALES CHANNEL */

export const handleSalesChannelLogic = (state) => {
  //const state = store.getState();

  const selectedSalesChannel = state.caseDetails.data.sales_channel;

  //  console.log('sed',selectedSalesChannel)
  if (selectedSalesChannel) {
    if (selectedSalesChannel === "DIR") {
      //  store.dispatch(setData({ key: 'endCustomer', value: state.caseDetails.data.account }))
      store.dispatch(
        setData({ key: "endCustomer", value: state.caseDetails.data.account })
      );
      store.dispatch(setData({ key: "distributor", value: "" }));
      store.dispatch(setData({ key: "resellers", value: "" }));
      store.dispatch(setData({ key: "indirect_resellers", value: "" }));
    }
    if (selectedSalesChannel === "DIS") {
      store.dispatch(
        setData({
          key: "endCustomer",
          value: state.caseDetails.data.endCustomer,
        })
      );
      store.dispatch(
        setData({ key: "distributor", value: state.caseDetails.data.account })
      );
      store.dispatch(setData({ key: "resellers", value: "" }));
      store.dispatch(
        setData({
          key: "indirect_resellers",
          value: state.caseDetails.data.indirect_resellers,
        })
      );
    }
    if (selectedSalesChannel === "RES") {
      store.dispatch(
        setData({
          key: "endCustomer",
          value: state.caseDetails.data.endCustomer,
        })
      );
      store.dispatch(setData({ key: "distributor", value: "" }));
      store.dispatch(
        setData({ key: "resellers", value: state.caseDetails.data.account })
      );
      store.dispatch(setData({ key: "indirect_resellers", value: "" }));
    }
    switch (state.caseDetails.data.sales_channel) {
      case selectedSalesChannel:
        store.dispatch(
          setVisible({
            id: salesChannelLogic[selectedSalesChannel].visible,
            value: true,
          })
        ); //stopped due to max stack error
        store.dispatch(
          setVisible({
            id: salesChannelLogic[selectedSalesChannel].hidden,
            value: false,
          })
        );

        // salesChannelLogic[selectedSalesChannel].visible.forEach(element => {
        //     const field = document.getElementById(element);
        //     if (field) {
        //         field.style.display="block"
        //     }
        // });

        // salesChannelLogic[selectedSalesChannel].hidden.forEach(element => {
        //     const field = document.getElementById(element);
        //     if (field) {
        //         field.style.display="none"
        //     }
        // });
        break;
      default:
        break;
    }
    handleCaseDepSaleChannelLogic(state); //Makes fields R / O on the basis of casetype
  }
};

export const handleRestLogic = () => {
  store.dispatch(resetAuth());
  store.dispatch(resetFC());
  store.dispatch(resetCD());
};

export const handleAccountLogic = (oot_response , state) => {
  if (!oot_response.data.sales_channel.default) {
    return;
  }

  switch (oot_response.data.sales_channel.default) {
    case "DIR":
      store.dispatch(
        updateAccount({
          id: oot_response.data.end_customer.nid,
          value: oot_response.data.end_customer.name,
          account_id: oot_response.data.end_customer.account_id,
          type: "account",
        })
      );

      store.dispatch(
        updateAccount({
          id: oot_response.data.end_customer.nid,
          value: oot_response.data.end_customer.name,
          type: "end_customer",
        })
      );

      //set-the-fields
      store.dispatch(
        setOBJ({ path: "account", value: oot_response.data.end_customer.nid })
      );
      store.dispatch(
        setOBJ({
          path: "endCustomer",
          value: oot_response.data.end_customer.nid,
        })
      );
      break;
    case "RES":
      // alert('RES')
      store.dispatch(
        updateAccount({
          id: oot_response.data.reseller.nid,
          value: oot_response.data.reseller.name,
          account_id: oot_response.data.end_customer.account_id,
          type: "account",
        })
      );
      store.dispatch(
        updateAccount({
          id: oot_response.data.end_customer.nid,
          value: oot_response.data.end_customer.name,
          type: "end_customer",
        })
      );
      store.dispatch(
        updateAccount({
          id: oot_response.data.reseller.nid,
          value: oot_response.data.reseller.name,
          type: "resellers",
        })
      );

      store.dispatch(
        setOBJ({ path: "account", value: oot_response.data.reseller.nid })
      );
      store.dispatch(
        setOBJ({
          path: "endCustomer",
          value: oot_response.data.end_customer.nid,
        })
      );
      console.log('ec', oot_response.data.end_customer.nid)
      store.dispatch(
        setOBJ({ path: "resellers", value: oot_response.data.reseller.nid })
      );

      //set-the-fields

      break;
    case "DIS":
      store.dispatch(
        updateAccount({
          id: oot_response.data.distributor.nid,
          value: oot_response.data.distributor.name,
          account_id: oot_response.data.end_customer.account_id,
          type: "account",
        })
      );
      // console.log('partrick',{
      //   id: oot_response.data.distributor.nid,
      //   value: oot_response.data.distributor.name,
      //   type: "account",
      // },'result=>',store.getState().authSlice.system.account)
      store.dispatch(
        updateAccount({
          id: oot_response.data.distributor.nid,
          value: oot_response.data.distributor.name,
          type: "distributor",
        })
      );
      store.dispatch(
        updateAccount({
          id: oot_response.data.end_customer.nid,
          value: oot_response.data.end_customer.name,
          type: "end_customer",
        })
      );
      store.dispatch(
        updateAccount({
          id: oot_response.data.indirect_reseller.nid,
          value: oot_response.data.indirect_reseller.name,
          type: "indirect_resellers",
        })
      );
      //set-the-fields
      store.dispatch(
        setOBJ({ path: "account", value: oot_response.data.distributor.nid })
      );
      store.dispatch(
        setOBJ({
          path: "distributor",
          value: oot_response.data.distributor.nid,
        })
      );
      store.dispatch(
        setOBJ({
          path: "endCustomer",
          value: oot_response.data.end_customer.nid,
        })
      );
      store.dispatch(
        setOBJ({
          path: "indirect_resellers",
          value: oot_response.data.indirect_reseller.nid,
        })
      );
      break;
    default:
      break;
  }
};

export const handleExceptionR6InternalLogic = (state) => {
  if (state.findCase.data.caseType === Object.keys(caseTypesMap)[3] && state.authSlice.system.account.length > 0) {
    store.dispatch(setData({ key: "sales_channel", value: "DIR" }));
    store.dispatch(
      setAccount(
        state.authSlice.system.account.filter((e) => e?.value?.toLowerCase()?.includes("nokia"))
      )
    );
    return true; //if this logic is executing
  }
};

export const handleCaseDepSaleChannelLogic = (state) => {
  if (state.findCase.data.caseType === "frame_agreement") {
    var found = state.authSlice.system.currentState;
    var copyFound = Object.assign({}, found);
    let salesChannel = state.caseDetails.data.sales_channel;

    switch (salesChannel) {
      case "DIR":
        copyFound = {
          ...copyFound,
          fieldsRequired: { ...copyFound.fieldsRequired, endCustomer: true },
        };
        copyFound.fieldsRequired.distributor = false;
        copyFound.fieldsRequired.resellers = false;
        break;
      case "DIS":
        copyFound = {
          ...copyFound,
          fieldsRequired: { ...copyFound.fieldsRequired, endCustomer: false },
        };
        copyFound.fieldsRequired.distributor = true;
        copyFound.fieldsRequired.resellers = false;
        break;
      case "RES":
        copyFound = {
          ...copyFound,
          fieldsRequired: { ...copyFound.fieldsRequired, endCustomer: false },
        };
        copyFound.fieldsRequired.distributor = false;
        copyFound.fieldsRequired.resellers = true;
        break;
      default:
    }
    store.dispatch(changeAppState(copyFound));
    //   console.log('NITESH',copyFound)
  }
};

export const generateEOTRedirect = (redirect_uri) => {
  //step 1
  const dbName = toolRedirect.ENSO.dbName;
  const redirectURi = redirect_uri; //redirect.homePage;
  //step 2
  const encodedParams = encodeBase64(`${dbName}&redirect_uri=${redirectURi}`);
  //step 3
  const loginMSALAuth = `https://login.microsoftonline.com/${toolRedirect.ENSO.tenantID}/oauth2/v2.0/authorize?scope=User.Read&response_type=code&client_id=${toolRedirect.ENSO.clientID}&redirect_uri=${toolRedirect.ENSO.domain}/auth_oauth/microsoft&state=${encodedParams}`;
  return loginMSALAuth;
};
export const handleRedirectType = (state: RootState) => {
  // const authSelector = state.authSlice;
  const caseDetailsSelector = state.caseDetails
  const findCaseSelector = state.findCase
  const toolSelection = state.toolSelection
  // const cartBoqSelector = state.cartBoq
  const businessSelector = state.business
  try {
    var caseType = findCaseSelector.data.caseType;
    var subType = findCaseSelector.data.subType;
    var customerSegment = businessSelector.data.options.PTDAC.caseIndustry;
    var advanceFeatures = businessSelector.data.view.PTDAC.additionalFeature;
    if (Object.keys(caseSubTypes).includes(caseType)) {
      var caseTypeSubTypeKeysArray = caseSubTypes[caseType].map(
        (obj, caseIndex) => ({
          caseType: obj.types
            .map(
              (typeObj, index) =>
                typeObj.key === subType && { subType: typeObj.key, id: index }
            )
            .filter((z) => z !== false),
          caseIndex: caseIndex,
        })
      );
      var caseTypeSubTypeKeys = caseTypeSubTypeKeysArray
        .flatMap((z) => z)
        .filter((z) => z.caseType.length !== 0)[0];
      if (caseTypeSubTypeKeys) {
        //get the redirect type by reaching the node in caseSubTypes in system;
        var redirect = caseTypeSubTypeKeys
          ? caseSubTypes[caseType][caseTypeSubTypeKeys.caseIndex].redirect
          : null;
        if (subType === "help") {
          //If Redirect is not defined, user selects:
          if (mpwSegmentSolution.includes(customerSegment)) {
            //If Solution is "MPW-S", system sets Redirect = EnSo shop
            redirect = toolRedirect.ENSO;
          } else {
            //Solution is undefined:
            redirect = toolRedirect.OOT;
            if (advanceFeatures.length > 0) {
              //If any Advance features are needed, system sets Redirect = EnSo shop, otherwise, system sets Redirect = OOT
              redirect = toolRedirect.ENSO;
            }
          }
        }

        if (redirect === null) {
          //If Redirect is not defined, user selects:

          if (toolSelection.data.extensionTool === extensionOfferTools.NDAC) {
            //"Packaged Plug & Play as-a-service solution (NDAC)". System sets Redirect = OOT.
            redirect = redirect = toolRedirect.OOT;
          }
          if (
            toolSelection.data.extensionTool === extensionOfferTools.MPW_GSM
          ) {
            //"Packaged Plug & Play as-a-service solution (NDAC)". System sets Redirect = OOT.
            redirect = redirect = toolRedirect.ENSO;
          }
        }
        return redirect;
      }
      if (caseTypeSubTypeKeys === undefined) {
        //handle no-subtype scenario - DEMAN/FRAME-agremments
        redirect = caseSubTypes[caseType][0].redirect;
        return redirect;
      }
    } else {
      alert('Error Creating:Looks like Casetype & subtype is not selected ')
    }
  } catch (error) {
    store.dispatch(
      setError({
        key: "error",
        value: { errorMsg: error, errorCode: null },
      })
    );
  } finally {

  }
};
export const handleOffer = async (state: RootState) => {

  const authSelector = state.authSlice;
  const caseDetailsSelector = state.caseDetails
  const findCaseSelector = state.findCase
  // const toolSelection = state.toolSelection
  const businessSelector = state.business
  const applicationsSelector = state.applications
  const connectivitySelector = state.connectivity
  const edgeSelector = state.edge;
  const servicesSelector = state.services;
  const cartBoqSelector = state.cartBoq
  const stateMachineSelector = state.stateMachine
  const devicesSelector = state.devices;
  const warrantySelector = state.warranty;
  
  try {
    let redirect = handleRedirectType(state);
    let ptdac_contractDuration;
    let PTDACProtoType = {};
    if(businessSelector.data.options.PTDAC.caseIndustry === "port") {
      const slaWarrantyProds = fetchSlaWarrantyProducts(warrantySelector,stateMachineSelector);
      ptdac_contractDuration = (businessSelector.data.view.PTDAC.commercial_model === "capex") ? businessSelector.data.view.PTDAC.contract_duration * 12 : businessSelector.data.view.PTDAC.contract_duration;

      PTDACProtoType = {
        "business": {
          "annualCapacity": businessSelector.data.view.PTDAC.annual_capacity,
          "area": businessSelector.data.view.PTDAC.area,
          "commercial_model": businessSelector.data.view.PTDAC.commercial_model.toLowerCase(),
          //Converting always to Months.
          "contractDuration": ptdac_contractDuration,
        },
        "applications": {
          "recommended_applications": stateMachineSelector.PTDAC.selected?.['applications']?.getrecommendedApplicationLogic.data.products,
          "recommended_device": cartBoqSelector.data.system.prepare.suggest.devices,
          "segmentSolutionUsecase": applicationsSelector.data.options.PTDAC.segment_solution
        },
        "connectivity": {
          "connectivity_solution": connectivitySelector.data.options.PTDAC.connectivity_solution,
          "radio_frequency_band": connectivitySelector.data.options.PTDAC.radio_frequency_band,
          "bandwidth": connectivitySelector.data.options.PTDAC.bandwidth,
          "private_wireless_users": connectivitySelector.data.options.PTDAC.private_wireless_users,
          "recommended_accessPoints": cartBoqSelector.data.system.prepare.suggest.aps
        },
        //TODO: Connect skipConnectivity to state
        "skipConnectivity": "no",
        "edge": {
          "georedudancy": edgeSelector.data.options.PTDAC.georedundancy,
          "power": edgeSelector.data.options.PTDAC.power,
          "connector_type": edgeSelector.data.options.PTDAC.connector_type,
          "connector_switch": edgeSelector.data.options.PTDAC.connector_switch,
          "switch_variant": edgeSelector.data.options.PTDAC.switch_variant,
          "mixe_server": edgeSelector.data.options.PTDAC.mxie_server,
          //Todo: Hardcoded graph
          "mixe_server_consumption": {
            "storage": 25,
            "memory": 20,
            "vcpu": 10
          }
        },
        "devices": {
          "sim_card": devicesSelector.data.options.PTDAC.sim_card
        },
        "services": generateSyncOfferItems('services', servicesSelector.data.options.PTDAC.services),
        "warranty": warrantySelector.data.options.PTDAC,
        "boq": generateSyncOfferItems('ndac', cartBoqSelector.data.cart.items),
        "total_prod_cost": cartBoqSelector.data.cart.total_cost,
        "ndac_premium_addons" : slaWarrantyProds
      }
    }

    const dataProtoType = {
      offer: {
        id: "",
        name: caseDetailsSelector.data.caseName,
        shipping_country: caseDetailsSelector.data.shipping_country,
        sales_channel: caseDetailsSelector.data.sales_channel,
        end_customer: caseDetailsSelector.data.endCustomer,
        market: caseDetailsSelector.data.market,
        summary: caseDetailsSelector.data.summary,
        owner: authSelector.login.mail,
        network_location: caseDetailsSelector.data.country,
        co_owner: caseDetailsSelector.data.co_owner,
        scrm_id: !sCRMRegex.test(findCaseSelector.data.sCRMID)
          ? "19.XY.123456"
          : findCaseSelector.data.sCRMID,
        public_funding_required: caseDetailsSelector.data
          .public_funding_required
          ? "yes"
          : "no",
        reason_for_funding: caseDetailsSelector.data.public_funding_required
          ? caseDetailsSelector.data.summary
          : "NA",
        case_type: findCaseSelector.data.caseType,
        case_sub_type: findCaseSelector.data.subType,
        customerSegmentId: businessSelector.data.options.PTDAC.caseIndustry,
        budgetary: caseDetailsSelector.data.caseCategory,
        lead_product: "NDAC",
        contract_duration: ptdac_contractDuration ? ptdac_contractDuration : caseDetailsSelector.data.contract_duration,
        contracting_party_id: caseDetailsSelector.data.account,
        currency: caseDetailsSelector.data.currency,
        network_id : caseDetailsSelector.data.network_ids
      },
      PTDAC: {
       ...PTDACProtoType
      },
    };
    
    if (findCaseSelector.data.subType === "help") {

      if (!businessSelector.data.options.PTDAC.caseIndustry) {
        store.dispatch(
          setError({
            key: "error",
            value: {
              errorCode: null,
              errorMsg: "Please select a customer segment",
            },
          })
        );

        return { response_code: 0, response_msg: 'Please select a customer segment' };
      }
    }

    if (redirect.hasOwnProperty('id')) {
      switch (redirect.id) {
        case "OOT":
          store.dispatch(showLoader(true));
          store.dispatch(showModal({ msg: "Offer creation in progress, Please wait..", title: "Creating Offer", show: true, variant: "WARNING" }))
          if (!authSelector.login.hasOwnProperty("accessToken")) {

            setError({
              key: "warn",
              value: {
                errorCode: null,
                errorMsg: "The accesstoken is not set,re-login is required.",
              },
            });
            return;
          }

          try {
            const response = await OOT.syncOffer({
              data: dataProtoType,
            })
            store.dispatch(showLoader(false));
            if (response.status) {
              store.dispatch(setLive(true));
                store.dispatch(showModal({ msg: `Offer: ${response.data.offer_id} created`, title: `Offer ${response.data.offer_id} successfully created.`, show: true }))
                // store.dispatch(showModal({ msg: `<a href='redirect.urlPrefix'+response.data.offer_id + "&cui_login=ms">" + text + "</a>"`, title: `Offer ${response.data.offer_id} successfully created.`, show: true }))
                window.open(
                  redirect.urlPrefix + response.data.offer_id + "&cui_login=ms","_self"
                );
                return { response_code: 1, response_msg: response.data }
                //  setTimeout(()=>handleRestLogic(),4000)
            }else {
              store.dispatch(showModal({ msg: response.errorMessage, title: "Something went wrong", show: true, variant: "ERROR" }))
              return { response_code: 0, response_msg: response.errorMessage }
            }
          } catch (err) {
            store.dispatch(showModal({ msg: err.response.status + ':' + err.message, title: "Server Error", show: true, variant: "ERROR" }))
            store.dispatch(setLive(false));
          }

          break;
        case "ENSO":
          store.dispatch(showLoader(false));
          // dispatch(setError(null));
          if (true) {
            if (!sCRMRegex.test(findCaseSelector.data.sCRMID)) {
              store.dispatch(
                setError({
                  key: "warn",
                  value: {
                    errorCode: null,
                    errorMsg: "Empty or invalid sCRM will not work for MPW-S cases",
                  },
                })
              );
              return;
            }
            // if (caseDetailsSelector.data.caseCategory === "Budgetary") {
            //   //const loginMSALAuth =`https://login.microsoftonline.com/${toolRedirect.ENSO.tenantID}/ oauth2/v2.0/authorize?scope=User.Read&response_type=code&client_id=${proces}&redirect_uri=https://iesp.cloud-test.nokia.com/auth_oauth/Microsoft&state=`

            //   if (authSelector.role === ROLE.INTERNAL) {
            //     window.open(generateEOTRedirect(redirect.homePage), "_self");
            //   }
            //   if (authSelector.role === ROLE.PARTNER) {
            //     window.open(
            //       generateEOTRedirect(redirect.homePagePartner),
            //       "_self"
            //     );
            //   }
            //   handleRestLogic();
            //   return { response_code: 1, response_msg: 'Redirected to EOT' };
            // }

            let decodedEndCustomer = undefined;
            if (
              caseDetailsSelector.data.sales_channel === "DIR" &&
              authSelector.OOT?.data?.end_customer?.name
            ) {
              decodedEndCustomer = authSelector.OOT.data.end_customer.name;
            } else {
              const search = authSelector.system.endCustomer.find(
                (z) => z.id === caseDetailsSelector.data.endCustomer
              );
              decodedEndCustomer = search.value;
            }
            // console.log("s", decodedEndCustomer);
            if (decodedEndCustomer === undefined) {
              store.dispatch(
                setError({
                  key: "error",
                  value: {
                    errorCode: null,
                    errorMsg: "Failed to find customer name",
                  },
                })
              );
              return { response_code: 0, response_msg: 'Failed to find customer name' };
            }
            const base64Customer = encodeBase64(decodedEndCustomer);

            // console.log("cyber", base64Customer);
            if (authSelector.role === ROLE.INTERNAL) {
              const urlParms = `?case_type=${findCaseSelector.data.caseType}&case_sub_type=${findCaseSelector.data.subType}&opportunity_id=${findCaseSelector.data.sCRMID}&segment_id=${businessSelector.data.options.PTDAC.caseIndustry}&customer_name=${base64Customer}`;
              //  window.location.href = redirect.urlPrefix + urlParms;
              const encodedState = encodeBase64(urlParms);
              const url = redirect.urlPrefix + "?state=" + encodedState;
              ///   console.log('ishan',url,encodedState,urlParms)
              window.open(generateEOTRedirect(url), "_self");
              handleRestLogic();
              return { response_code: 1, response_msg: 'Redirected to EOT' };
            }
            if (authSelector.role === ROLE.PARTNER) {
              const urlParms = `?case_type=${findCaseSelector.data.caseType}&case_sub_type=${findCaseSelector.data.subType}&opportunity_id=${findCaseSelector.data.sCRMID}&segment_id=${businessSelector.data.options.PTDAC.caseIndustry}`;
              //  window.location.href = redirect.urlPrefixPartner + urlParms;
              const encodedState = encodeBase64(urlParms);
              const url = redirect.urlPrefixPartner + "?state=" + encodedState;
              window.open(generateEOTRedirect(url), "_self");
              handleRestLogic();
              return { response_code: 1, response_msg: 'Redirected to EOT' };
            }
          }
          break;
        default:
          //  console.log("Something went wrong during sync");
          break;
      }
    }
  } catch (error) {
    store.dispatch(setStatus(STATUS.ERROR));
    setError({
      key: "error",
      value: { errorMsg: error, errorCode: null },
    });
  }
};

export const fetchSlaWarrantyProducts = (warrantySelector,stateMachineSelector) => {
  const planselected = warrantySelector.data.options.PTDAC.subscription_plan;
  const slawarrantyData = stateMachineSelector.PTDAC.selected.warranty;
  let warrantyProds = [];
  if(slawarrantyData.extendDurationWarrantyTab.data) {
    warrantyProds = slawarrantyData.extendDurationWarrantyTab.data.products;
  }
  let slaProds = [];
  if(slawarrantyData.plansWarrantyTab.data.plans.byPlan[planselected]){
    slaProds = slawarrantyData.plansWarrantyTab.data.plans.byPlan[planselected].products;
  }
  const slawarrantyProds = warrantyProds.concat(slaProds);
  const addonArray = [];
  slawarrantyProds.forEach(eachProd => {
    const prodObj = {
        "product_code": eachProd.product_id,
        "quantity": eachProd.quantity
    }
    addonArray.push(prodObj);
  });
  return addonArray;
}


export const getDateCalculated = () => {
  // estimated date will be current date + 2 months
  let current_est_date = new Date();
  current_est_date.setMonth(current_est_date.getMonth() + 2);
  const est_order_date = current_est_date.getMonth() + 1 ;
  const estyear = current_est_date.getFullYear();
  const estdate = current_est_date.getDate();
  
  // requested date will be current date + 6 months
  let current_req_date = new Date();
  current_req_date.setMonth(current_req_date.getMonth() + 6);
  const req_delivery_date = current_req_date.getMonth() + 1 ;
  const reqyear = current_req_date.getFullYear();
  const reqdate = current_req_date.getDate();
  
  const est_order_month = est_order_date < 10 ? '0' + est_order_date : '' + est_order_date;
  const req_delivery_month = req_delivery_date < 10 ? '0' + req_delivery_date : '' + req_delivery_date;

  const finalestdate = estdate < 10 ? '0' + estdate : '' + estdate;
  const finalreqdate = reqdate < 10 ? '0' + reqdate : '' + reqdate;
  
  const est = `${estyear}-${est_order_month}-${finalestdate}`;
  const req_delivery = `${reqyear}-${req_delivery_month}-${finalreqdate}`;
  
  return {'final_est_order_date' : est,'final_req_delivery_date' : req_delivery };
}


