
import React from "react";
import Button from '@nokia-csf-uxr/ccfk/Button';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import Chip, {
    ChipLabel,
} from '@nokia-csf-uxr/ccfk/Chip';
import {ReactComponent as SaveIcon} from '../../../../Assets/Icons/save_icon.svg';
import cancelIcon from '../../../../Assets/Icons/cancel_icon.svg';
import editIcon from '../../../../Assets/Icons/edit_roles_pencil_icon.svg';
import userStyles from '../Style/UserManagement.module.css'
import classes from '../../../marketplace/Style/MarketplaceFilter.module.css';
import MultiSelect from "Pages/marketplace/common/MultiSelect";
import { ReactComponent as AddIcon } from '@nokia-csf-uxr/ccfk-assets/icons/latest/add.svg';
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import { modifyData } from "../utils/constants";
import { setProp } from "Store/Actions";
import { useDispatch } from "react-redux";
import { setEditMode, cancelModifications } from "Store/contentState/userManagementSlice";
import OOT from "Constants/services/oot";




const placeholder = "Select Roles"
const label = "Show roles"

const RolesActions = (prop) => {
    const dispatch = useDispatch();
    const roleList = useSelector((state: RootState) => state.userManagement.roleInfo.rolesData);
    const isEditMode = useSelector((state: RootState) => state.userManagement.isEditMode);
    const modifiedUserRolesTobeSaved = useSelector((state: RootState) => state.userManagement.modifiedRoles);

    const modifiedRoles = modifyData(roleList, 'id', 'name');

    const handleRoleSelection = (selectedRole) => {
        console.log("selected", selectedRole)
        const selectedRolesIds = selectedRole.map((role) => role.id);
        dispatch(setProp({slice:'userManagement', key:'userRolesFilterColumns', value:selectedRolesIds}))
    }


    const handleEditRoles = () => {
        dispatch(setEditMode(true))
    }


    const handleSaveRolesMapping = async () => {
        try {
           const response = await OOT.saveUserRoles(modifiedUserRolesTobeSaved, 'edit'); 
           if(response.status){
            console.log(response.data)
            dispatch(setEditMode(false));
            prop.showSnackbar(response.msg);
           }
        } catch (error) {
            console.log(error)
            dispatch(setEditMode(true));
            prop.showSnackbar("Error while saving roles!");
        }

    }

    const handleCancel = () => {
        dispatch(cancelModifications());
    }

    return (
        <>
            <div className={userStyles.dFlexBetween}>
                <div className={userStyles.userRolesActionButtons}  >
                    <Button
                        variant="secondary"
                        onClick={handleEditRoles}
                    >
                        <ButtonIcon><img src={editIcon} alt="save-icon" /></ButtonIcon>
                        <ButtonText>Edit roles</ButtonText>
                    </Button>
                    <Button
                        variant="secondary"
                    onClick={handleSaveRolesMapping}
                    disabled={!isEditMode}
                    >
                        <ButtonIcon><SaveIcon style={{color:'white', height:'20px'}}/></ButtonIcon>
                        <ButtonText>Save changes</ButtonText>
                    </Button>
                    <Button
                        variant="neutral"
                        onClick={handleCancel}
                        disabled={!isEditMode}
                    >
                        <ButtonIcon><img src={cancelIcon} alt="cancel-icon" /></ButtonIcon>
                        <ButtonText>Cancel</ButtonText>
                    </Button>
                </div>

                <div className={userStyles.userRolesFilterContainer}>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:'15px'}}>
                        <Chip size="small" style={{ backgroundColor: '#FFFFFF', border: '1px solid #333333', borderRadius: '15px', marginRight: '5px' }}><ChipLabel label="C - Create" /></Chip>
                        <Chip size="small" style={{ backgroundColor: '#FFFFFF', border: '1px solid #333333', borderRadius: '15px', marginRight: '5px' }}><ChipLabel label="R - Read" /></Chip>
                        <Chip size="small" style={{ backgroundColor: '#FFFFFF', border: '1px solid #333333', borderRadius: '15px', marginRight: '5px' }}><ChipLabel label="U - Update" /></Chip>
                        <Chip size="small" style={{ backgroundColor: '#FFFFFF', border: '1px solid #333333', borderRadius: '15px', marginRight: '5px' }}><ChipLabel label="D - Delete" /></Chip>
                    </div>
                    <div className={classes.verticalDivider} style={{ margin: '0 .3rem 0 .3rem' }}></div>
                    <div style={{width:'40%'}}>
                        <MultiSelect
                            placeholder={placeholder}
                            label={label}
                            data={modifiedRoles}
                            onSelected={handleRoleSelection}
                        />
                    </div>
                </div>
            </div>

        </>
    )

}

export default RolesActions;