import React from "react";
import PriceDisplay from "../UIElements/PriceDisplay";

const CartDiscountBreakup = (prop) => {
    return (
        <>
        <div style={{boxSizing:'border-box', direction:'ltr', wordBreak: 'break-word',whiteSpace: 'pre-line',
        maxWidth:'30rem', padding:'.5rem', borderRadius:'.5rem', backgroundColor:"#101620d9", color:'#ffffff'}}>
        <div style={{fontSize:'.8rem'}}>Discounts</div>
        <div>Listed price: {<PriceDisplay price={prop.discountBreakup.list_price}/>}</div>
        <div>Partner tier discount: {prop.discountBreakup.ptd_percentage}</div>
        <div>Volume discount: {prop.discountBreakup.vd_percentage}</div>
        <div>Time based discount: {prop.discountBreakup.tbd_percentage}</div>
        <div>Custom and logistics: {prop.discountBreakup.customs_and_logistics}</div>
        <div>Total: {<PriceDisplay price={prop.discountBreakup.total}/>}</div>
        </div>
        </>
    )

}

export default CartDiscountBreakup;
