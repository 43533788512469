import React from "react";
import classes from "../../Style/Cart.module.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import { Label } from '@nokia-csf-uxr/ccfk';
import { Typography } from "@nokia-csf-uxr/ccfk";
import _ from "lodash";
import PriceDisplay from "Pages/marketplace/UIElements/PriceDisplay";
import {formatNumberWithCommas} from '../../Utils/commons';

const CartSummaryItemsTable = () => {
    const cart = useSelector((state: RootState) => state.marketplace.cart);
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace)



    const groupByType = (products) => {
        return products.reduce((acc, product) => {
            let type = product.product_type_id; // Default to using product_type_id
            // Apply special cases
            if ((type === 'Software' && product.product_sub_type_id === 'Applications') ||
                (type === 'Services' && product.product_sub_type_id === 'Subscription')) {
                type = `${product.product_sub_type_id}`; // Create a unique key for these special cases
            }
            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(product);
            return acc;
        }, {});
    };



    // const calculateSubtotal = (products, key) => {
    //     return products.reduce((total, product) => {
    //         return total + (product[key] !== undefined ? parseFloat(product[key]) : 0);
    //     }, 0).toFixed(2);
    // };

    const calculateSubtotal = (products, key) => {
        return products.reduce((total, product) => {
            // Check if the product object has the specified key
            if (typeof product[key] === 'string' || product[key] instanceof String) {
                // If the value is a string, try parsing it as a float
                return total + parseFloat(product[key] || 0); // If the string is empty, parseFloat returns NaN
            } else if (!isNaN(parseFloat(product[key]))) {
                // If it's not a string but can be parsed as a number, add it to the total
                return total + parseFloat(product[key]);
            } else {
                // If it's neither a string nor a number, return total as it is
                return total;
            }
        }, 0).toFixed(2);
    };

    const companyProducts = cart.filter(item => item.is_agent_model === 0);
    const thirdPartyProducts = cart.filter(item => item.is_agent_model === 1);

    const companyProductsByType = groupByType(companyProducts);
    const thirdPartyProductsByType = groupByType(thirdPartyProducts);


    const formatType = (type, subtype) => {
       
        if (
            (type === 'Software' && subtype === 'Applications') ||
            (type === 'Services' && subtype === 'Subscription')
        ) {
            return 'Subscription';
        } else {
            return type;
        }
    };

   
    return (
        <>
            {companyProducts.length > 0 && (
                <div>
                    <table style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'left' }}>
                        <thead>
                            <tr style={{ padding: '.3rem', backgroundColor: "#E4F0FF", marginBottom: '.3rem' }}>
                                <th style={{ fontWeight: 'bold', color: '#333333', fontSize: '13px', marginBottom: '.5rem', padding: '0.5rem', whiteSpace: 'normal' }}>Nokia items</th>
                                <th style={{ fontWeight: 'bold', color: '#333333', fontSize: '13px', marginBottom: '.5rem', textAlign: 'right', padding: '0.5rem', whiteSpace: 'nowrap' }}>List price</th>
                                <th style={{ fontWeight: 'bold', color: '#333333', fontSize: '13px', marginBottom: '.5rem', textAlign: 'right', padding: '0.5rem', whiteSpace: 'nowrap' }}>Discount price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(companyProductsByType).map(type => (
                                <React.Fragment key={type}>
                                    <tr>
                                        <td style={{ color: '#757575', fontSize: '13px', padding: '5px' }}>{formatType(type, companyProductsByType[type][0]['product_sub_type_id'])} subtotal</td>
                                        <td style={{ color: '#757575', fontSize: '13px', padding: '5px', textAlign: 'right' }}><PriceDisplay price={calculateSubtotal(companyProductsByType[type], 'product_total_cost')}/></td>
                                        <td style={{ color: '#757575', fontSize: '13px', padding: '5px', textAlign: 'right' }}><PriceDisplay price={calculateSubtotal(companyProductsByType[type], companyProductsByType[type][0]['list_price'] ? 'total': "product_total_cost")}/></td>
                                    </tr>
                                </React.Fragment>
                            ))}
                            <tr style={{ padding: '10px', backgroundColor: "#F3F3F3" }}>
                                <td style={{ color: '#333333', fontSize: '13px', padding: '5px', fontWeight: 'bold' }}>Nokia items subtotal</td>
                                <td style={{ color: '#333333', fontSize: '13px', padding: '5px', textAlign: 'right', fontWeight: 'bold' }}><PriceDisplay price={calculateSubtotal(companyProducts, "product_total_cost")}/></td>
                                <td style={{ color: '#333333', fontSize: '13px', padding: '5px', textAlign: 'right', fontWeight: 'bold' }}><PriceDisplay price={calculateSubtotal(companyProducts, companyProducts[0]['list_price'] ? 'total': "product_total_cost")}/></td>
                            </tr>
                        </tbody>
                    </table>

                    <div className={classes.horizontalDivider} style={{ marginTop: '7px', marginBottom: '7px' }}></div>
                </div>
            )}


            {thirdPartyProducts.length > 0 && (
                <div>
                    <table style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'left' }}>
                        <thead>
                            <tr style={{ padding: '.3rem', backgroundColor: "#E4F0FF", marginBottom: '.3rem' }}>
                                <th style={{ fontWeight: 'bold', color: '#333333', fontSize: '13px', marginBottom: '.5rem', padding: '0.5rem', whiteSpace: 'normal' }}>Third party items</th>
                                <th style={{ fontWeight: 'bold', color: '#333333', fontSize: '13px', marginBottom: '.5rem', padding: '0.5rem', textAlign: 'right', whiteSpace: 'nowrap' }}>List price</th>
                                <th style={{ fontWeight: 'bold', color: '#333333', fontSize: '13px', marginBottom: '.5rem', padding: '0.5rem', textAlign: 'right', whiteSpace: 'nowrap' }}>Discount price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(thirdPartyProductsByType).map(type => (
                                <React.Fragment key={type}>
                                    <tr>
                                        <td style={{ color: '#757575', fontSize: '13px', padding: '5px' }}>{formatType(type, thirdPartyProductsByType[type][0]['product_sub_type_id'])} subTotal</td>
                                        <td style={{ color: '#757575', fontSize: '13px', padding: '5px', textAlign: 'right' }}><PriceDisplay price={calculateSubtotal(thirdPartyProductsByType[type], "product_total_cost")}/></td>
                                        <td style={{ color: '#333333', fontSize: '13px', padding: '5px', textAlign: 'right', fontWeight: 'bold' }}><PriceDisplay price={calculateSubtotal(thirdPartyProductsByType[type], thirdPartyProductsByType[type][0]['list_price'] ? 'total': "product_total_cost")}/></td>
                                    </tr>
                                </React.Fragment>
                            ))}
                            <tr style={{ padding: '10px', backgroundColor: "#F3F3F3" }}>
                                <td style={{ color: '#333333', fontSize: '13px', padding: '5px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Third party items subTotal</td>
                                <td style={{ color: '#333333', fontSize: '13px', padding: '5px', textAlign: 'right', fontWeight: 'bold', }}><PriceDisplay price={calculateSubtotal(thirdPartyProducts, thirdPartyProducts[0]['list_price'] ? 'total': "product_total_cost")}/></td>
                                <td style={{ color: '#333333', fontSize: '13px', padding: '5px', textAlign: 'right', fontWeight: 'bold', }}><PriceDisplay price={calculateSubtotal(thirdPartyProducts, thirdPartyProducts[0]['list_price'] ? 'total': "product_total_cost")}/></td>
                            </tr>

                        </tbody>
                    </table>

                    <div className={classes.horizontalDivider} style={{ marginTop: '7px', marginBottom: '7px' }}></div>
                </div>


            )}

            <div style={{ padding: '7px', display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                <Typography style={{ fontSize: '14px', fontWeight: '400', color: '#333333' }}>Total discount</Typography>
                <Typography></Typography>
                {/* <Typography style={{ textAlign: 'right', fontSize: '14px', fontWeight: '400', color: '#333333' }}> {marketplaceSelector.cartResponse.cartSummary &&
         marketplaceSelector.cartResponse.cartSummary.hasOwnProperty('original_price') && 
         marketplaceSelector.cartResponse.cartSummary.hasOwnProperty('deal_final_price') ? 
         (marketplaceSelector.cartResponse.cartSummary['original_price'] - marketplaceSelector.cartResponse.cartSummary['deal_final_price']) : 0}</Typography>
          */}

            <Typography style={{ textAlign: 'right', fontSize: '14px', fontWeight: '400', color: '#333333' }}><PriceDisplay 
                    price={marketplaceSelector.cartResponse.cartSummary && marketplaceSelector.cartResponse.cartSummary.hasOwnProperty('original_price') && marketplaceSelector.cartResponse.cartSummary.hasOwnProperty('deal_final_price') 
                            ? marketplaceSelector.cartResponse.cartSummary['original_price'] - marketplaceSelector.cartResponse.cartSummary['deal_final_price'] 
                            : 0} 
                    currency={marketplaceSelector.accountDetails[0].currency}
                /></Typography>
            </div>

            <div style={{ padding: '7px', display: 'flex', justifyContent: 'space-between', marginTop: '5px', backgroundColor: '#EBEBEB' }}>
                <Typography style={{ fontSize: '16px', fontWeight: '700', color: '#333333' }}>Final price</Typography>
                <Typography></Typography>
                {/* the currency from sync offer can be used here */}
                <Typography style={{ textAlign: 'right', fontSize: '16px', fontWeight: '700', color: '#333333' }}>{<PriceDisplay price={_.sumBy(cart, c => Number(c.total ? c.total : c.product_total_cost))}/>} {marketplaceSelector.accountDetails[0].currency}</Typography>
            </div>
        </>
    )
}

export default CartSummaryItemsTable;