import ConnectionServerIcon from "@nokia-csf-uxr/ccfk-assets/legacy/ConnectionServerIcon";
import ESimIcon from "@nokia-csf-uxr/ccfk-assets/legacy/ESimIcon";
import LanguageIcon from "@nokia-csf-uxr/ccfk-assets/legacy/LanguageIcon";
import ModemIcon from "@nokia-csf-uxr/ccfk-assets/legacy/ModemIcon";

import countryListData from "Constants/countryList.json";
import currency from "Constants/currency.json";


import AirportIcon from '@nokia-csf-uxr/ccfk-assets/legacy/AirportIcon';
import DefenceIcon from '@nokia-csf-uxr/ccfk-assets/legacy/DefenceIcon';
import IndustrialSiteIcon from '@nokia-csf-uxr/ccfk-assets/legacy/IndustrialSiteIcon';
import LogisticsIcon from '@nokia-csf-uxr/ccfk-assets/legacy/LogisticsIcon';
import ManufacturingIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ManufacturingIcon';
import MiningIcon from '@nokia-csf-uxr/ccfk-assets/legacy/MiningIcon';
import OilAndGasIcon from '@nokia-csf-uxr/ccfk-assets/legacy/OilAndGasIcon';
import OtherIcon from '@nokia-csf-uxr/ccfk-assets/legacy/OtherIcon';
import PlantIcon from '@nokia-csf-uxr/ccfk-assets/legacy/PlantIcon'; //ENERGY
import PortIcon from '@nokia-csf-uxr/ccfk-assets/legacy/PortIcon';
import RailwayIcon from '@nokia-csf-uxr/ccfk-assets/legacy/RailwayIcon';
import SmartCityIcon from '@nokia-csf-uxr/ccfk-assets/legacy/SmartCityIcon';
const CryptoJS = require("crypto-js");
export const ROLE = {
  ADMIN: "admin",
  INTERNAL: "internal",
  PARTNER: "partner",
};
export const caseTypesMap = {
  new: "New",
  addition_to_existing_edge: "Addition to existing Network",
  non_commercial_network: "Non-commercial Network",
  internal_order: "Internal Order",
  demand_forecast: "Demand forecast",
  frame_agreement: "Frame agreement / Commitments",
};

export const caseTypesMapPartner = {
  new: "New",
  addition_to_existing_edge: "Addition to existing Network",
  non_commercial_network: "Non-commercial Network",
};

export const debounceInterval = 1000;
export const customerInfoRegex = RegExp(/^\d{2}\.[a-zA-Z]{2}\.[a-zA-Z0-9]{6}$/);

export const toolRedirect = {
  OOT: {
    id: "OOT",
    urlPrefix:
      process.env.REACT_APP_OOT + "/ndac-login?destination=/offer/edit/",
    homePage: process.env.REACT_APP_OOT + "/offer/node/add/offers",
  },
  ENSO: {
    id: "ENSO",
    // urlPrefix: process.env.REACT_APP_ENSO + "/",
    domain: process.env.REACT_APP_ENSO,
    homePage:
      process.env.REACT_APP_ENSO +
      "/web#action=283&cids=1&id=&menu_id=174&model=sale.order&view_type=form",
    homePagePartner:
      process.env.REACT_APP_ENSO +
      "/web#action=283&cids=1&id=&menu_id=174&model=sale.order&view_type=form",
    urlPrefix: process.env.REACT_APP_ENSO + "/get_so_page/",
    urlPrefixPartner:
      process.env.REACT_APP_ENSO_PARTNER + "/get_partner_so_page/",
    dbName: process.env.REACT_APP_EOT_DB_NAME,
    clientID: process.env.REACT_APP_EOT_CLIENT_ID,
    tenantID: process.env.REACT_APP_EOT_TENANT_ID
  },
};

export const salesChannel = [
  {
    id: "DIR",
    value: "Direct [DIR]",
  },
  {
    id: "RES",
    value: "Reseller (or spectrum partner)  [RES]",
  },
  {
    id: "DIS",
    value: "Distributor [DIS]",
  },
];
export const market = [
  {
    id: "Global Frame",
    value: "Global Frame Agreement [Global Frame]",
  },
  {
    id: "APJ",
    value: "Asia Pacific & Japan [APJ]",
  },
  {
    id: "EUR",
    value: "Europe [EUR]",
  },
  {
    id: "GCHN",
    value: "Greater China [GCHN]",
  },
  {
    id: "INDIA",
    value: "India [INDIA]",
  },
  {
    id: "LAT",
    value: "Latin America [LAT]",
  },
  {
    id: "MEA",
    value: "Middle East & Africa [MEA]",
  },
  {
    id: "NAM",
    value: "North America [NAM]",
  },
];

export const networkids = [];
export const caseTypesList = Object.entries(caseTypesMap).map(
  (z) => new Object({ id: z[0], value: z[1] })
);
export const caseTypesPartnerList = Object.entries(caseTypesMapPartner).map(
  (z) => new Object({ id: z[0], value: z[1] })
);


export const leadProduct = {
  NDAC: "Nokia Digital Automation Cloud (NDAC)",
  NDAC_explorer: " Nokia Digital Automation Cloud explorer ",
  NDAC_CAPEX: "Nokia Digital Automation Cloud CAPEX",
  HAIP: "High Accuracy Indoor Positioning (HAIP)",
  NDN: "Nokia Drone Networks (NDN)",
  DEV: "Devices",
};
export const extensionOfferTools = {
  MPW_GSM:
    "(MPW-S) Modular with choice of elements; customer owned and operated",
  NDAC: "(NDAC) Packaged Plug & Play as-a-service solution",
};

export const IndustrySegments = {
  AIR: "Airport",
  PORT: "Port Terminal",
  ENRGY: "Energy",
  INDUS: "Industrial",
  DEF: "Defense",
  THTR: "Theatre",
  INST: "Institutions",
  COMM: "Commercial",
  RAIL: "Railway",
  OTHER: "Others",
}; //outdated

export const portPlanSolutions = [
  { icon: ModemIcon, label: "AP", value: "Access points" },
  {
    icon: ConnectionServerIcon,
    label: "NDAC",
    value: "NDAC Egde: Large(includes 50 SIMs)",
  },
  {
    icon: LanguageIcon,
    label: "GEOR",
    value: "Geo Redundancy configuration for Large Enterprise",
  },
  { icon: ESimIcon, label: "SIM", value: "SIM card kit (25 SIM pack)" },
];

export const STATUS = {
  ERROR: "error",
  IDLE: "idle",
  LOADING: "loading",
  HIDE: "hide",
};

export const managePortTabTheme = {
  CONFIG: "Configuration",
  SERVICES: "Services",
  DISC: "Discount",
};

export const contractPlans = {
  BASIC: {
    name: "Basic",
    price: "1000",
    currency: "$",
    serivceDesk: "24/7",
    initRespTime: ["24h"],
    serviceReq: "24h",
    resolution: ["Best effort"],
    permanentFix: null,
    supportChannels: ["DAC info Center", "DAC Manager", "eMail"],
  },
  PROFESSIONAL: {
    name: "Professional",
    price: "1500",
    currency: "$",
    serivceDesk: "24/7",
    initRespTime: ["4h", "8h", "24h"],
    serviceReq: "48h",
    resolution: ["Best effort"],
    permanentFix: null,
    supportChannels: ["DAC info Center", "DAC Manager", "eMail"],
  },
  PREMIUM: {
    name: "Premium",
    price: "1700",
    currency: "$",
    serivceDesk: "24/7",
    initRespTime: ["4h", "8h", "12h"],
    serviceReq: "48h",
    resolution: ["P1 4h", "P2 8h", "P3 48h"],
    permanentFix: "30 days a month",
    supportChannels: [
      "DAC info Center",
      "DAC Manager",
      "eMail",
      "chat",
      "Escalation process",
    ],
  },
};

export const DATAMART_GLOBAL = {
  baseUrl: process.env.REACT_APP_DATAMART,
  blobProductPath: process.env.REACT_APP_BLOB_PATH_PRODUCT,
  endPoints:{
    user: "/user",
    getDetailsForMarketplace: '/model',
    productsData: "/product",
    shadowOffer: "/offer",
    uploadPO: "/offer/uploadPO",
    getAllOffers:"/offer/getAllOffers",
    getAccountData:"/account",
    countryApi:"/country",
    bulkUsers: "/user/all",
    getUserRoles: "/roles/all",
    hierachyLevels: "/hierarchy",
    allAccounts: "/v1/account",
    saveUserRoles:"/roles",
    applicationList: "/application",
    orderitems: "/offer/items"
  }
}

export const OOT_GLOBAL = {
  proxyUrl: process.env.REACT_APP_NODE,
  baseUrl: process.env.REACT_APP_OOT,
  endPoints: {
    login: "/api/v1/auth/token",
    getOffer: "/api/get-offer",
    getProducts: "/api/get-products",
    syncOffer: "/api/sync-offer",
    getOpportunity: "/api/get-opportunity-data",
    getCustomer: "/api/customer-data",
    getAccountCustomer: "/api/account-data",
    getCountryBands: "/api/get-country-bands",
    getApplicationTab: "/api/get-applicationTab",
    getConnectivitytab: "/api/get-connectivityTab",
    getEdgeTab: "/api/get-edgeTab",
    getDevicesTab: "/api/get-deviceTab",
    getServicesTab: "/api/get-servicesTab",
    // getProfessionalServicesTab:"/api/professional-service-data",
    getWarrantyTab: "/api/get-sla_warranty",
    // Subscription end points
    getSubscriptionQuestionnaire: "/api/subscription-questionnaire",
    getSubscriptionNetworkData: "/api/network_data",
    updateSubscription: "/api/subscription-update",
    cancellationSubscription: "/api/subscription-cancellation",

  },
 
};
export const caseSubTypes = {
  new: [
    {
      types: [{ key: "help", value: "Help me identify solution type" }],
      redirect: null,
    },
    {
      types: [
        {
          key: "pkg_plug_play",
          value: "(NDAC) Packaged Plug & Play as-a-service solution",
        },
      ],
      redirect: toolRedirect.OOT,
    },
    {
      types: [
        {
          key: "modular",
          value:
            "(MPW-S) Modular with choice of elements; customer owned and operated",
        },
      ],
      redirect: toolRedirect.ENSO,
    },
    {
      types: [
        // {
        //   key: "pkg_plug_play",
        //   value: "(NDAC) Packaged Plug & Play as-a-service solution",
        // },
        { key: "devices_only", value: "Devices only" },
        { key: "perimeter_nw", value: "Perimeter Network" },
      ],
      redirect: toolRedirect.OOT,
    },
  ],
  addition_to_existing_edge: [
    {
      types: [{ key: "subscriptions_renewal", value: "Subscriptions renewal" }],
      redirect: toolRedirect.OOT,
    },
    {
      types: [
        {
          key: "extension_commercial_network",
          value: "Extension to existing commercial network",
        },
        { key: "sw_small_item_upsell ", value: "SW and small items upsell" },
        { key: "free_of_charge", value: "Free of charge replacement" },
      ],
      redirect: null,
    },
  ],
  non_commercial_network: [
    {
      types: [
        // { key: "poc_saas", value: "PoC aaS" },
        { key: "subscriptions_renewal", value: "Subscriptions renewal" },
      ],
      redirect: toolRedirect.OOT,
    },
    {
      types: [
        { key: "new_proof_of_concept", value: "New Proof of Concept" },
        { key: "new_partner_demo_kit", value: "New Partner Demo Kit" },
        { key: "new_partner_lab", value: "New Partner Lab" },
        { key: "extension_commercial_network ", value: "Extension To Existing" },
      ],
      redirect: null,
    },
  ],
  internal_order: [
    {
      types: [
        { key: "new_network", value: "New network" },
        { key: "extension_commercial_network ", value: "Extension to existing" },
        { key: "sw_small_item_addition", value: "SW and small items addition" },
        { key: "subscriptions_renewal", value: "Subscriptions renewal" },
      ],
      redirect: null,
    },
  ],
  demand_forecast: [{ types: [], redirect: toolRedirect.OOT }],
  frame_agreement: [{ types: [], redirect: toolRedirect.OOT }],
};

export const subtypes = [{ id: "help", value: "Help me identify solution type" }, {
  id: "extension_commercial_network",
  value: "Extension to existing commercial network",
},
{ id: "sw_small_item_upsell", value: "SW and small items upsell" },
{ id: "free_of_charge", value: "Free of charge replacement" },
// { id: "poc_saas", value: "PoC aaS" },
{ id: "subscriptions_renewal", value: "Subscriptions renewal" },
{ id: "new_proof_of_concept", value: "New Proof of Concept" },
{ id: "new_partner_demo_kit", value: "New Partner Demo Kit" },
{ id: "new_partner_lab", value: "New Partner Lab" },
{ id: "extension_commercial_network ", value: "Extension To Existing" },
{ id: "new_network", value: "New network" },
{ id: "extension_commercial_network ", value: "Extension to existing" },
{ id: "sw_small_item_addition", value: "SW and small items addition" },
{ id: "subscriptions_renewal", value: "Subscriptions renewal" },
{ id: "devices_only", value: "Devices only" },
{ id: "perimeter_nw", value: "Perimeter Network" },];
export const segmentSolution = [
  {
    id: "airport",
    name: "Airports",
    description: "Airport Segment",
    thumbnail: "/assets/images/segments/Airport.svg",
    icon: AirportIcon,
  },
  {
    id: "rail",
    name: "Railways",
    description: "Railway Segment",
    thumbnail: "/assets/images/segments/Railways.svg",
    icon: RailwayIcon,
  },
  {
    id: "port",
    name: "Ports",
    description: "Port Segment",
    thumbnail: "/assets/images/segments/Port.svg",
    icon: PortIcon
  },
  {
    id: "energy",
    name: "Energy",
    description: "Utility sites Power plants & renewable, sub-stations Segment",
    thumbnail: "/assets/images/segments/Utility_sites_renewables.svg",
    icon: PlantIcon
  },
  {
    id: "industrial",
    name: "Industrial",
    description: "Industrial sites, plants and campuses Segment",
    thumbnail: "/assets/images/segments/Industrial_sites.svg",
    icon: IndustrialSiteIcon,
  },
  // {
  //   id: "fan_regional",
  //   name: "Fan & regional",
  //   description: "Fan & regional Segment",
  //   thumbnail: "/assets/images/segments/Fan_regional.svg",
  // },
  // {
  //   id: "utility",
  //   name: "Utility FAN",
  //   description: "Utility FAN Segment",
  //   thumbnail: "/assets/images/segments/Utility_FAN.svg",
  // },
  {
    id: "smart_city",
    name: "Smart City",
    description: "Smart City Segment",
    thumbnail: "/assets/images/segments/Smart_city.svg",
    icon: SmartCityIcon,
  },
  {
    id: "manufacturing",
    name: "Manufacturing",
    description: "Manufacturing Segment",
    thumbnail: "/assets/images/segments/Manufacturing.svg",
    icon: ManufacturingIcon,
  },
  {
    id: "logistics",
    name: "Logistics",
    description: "Logistics Segment",
    thumbnail: "/assets/images/segments/Logistics.svg",
    icon: LogisticsIcon,
  },
  {
    id: "mining",
    name: "Mining",
    description: "Mining Segment",
    thumbnail: "/assets/images/segments/Mining.svg",
    icon: MiningIcon,
  },
  {
    id: "oil",
    name: "Oil & Gas",
    description: "Oil & Gas Segment",
    thumbnail: "/assets/images/segments/Oil_gas.svg",
    icon: OilAndGasIcon,
  },
  {
    id: "defence",
    name: "Defence",
    description: "Defence Segment",
    thumbnail: "/assets/images/segments/Defence.svg",
    icon: DefenceIcon,
  },
  {
    id: "others",
    name: "Others",
    description: "Others",
    thumbnail: "/assets/images/segments/Others.svg",
    icon: OtherIcon,
  },
];

export const mpwSegmentSolution = [
  "fan_regional",
  "rail",
  "utility",
  "defence",
  "smart_city",
];
export const segmentOptions = {
  full_control_nw: "Full control of network (FM/PM/CM)",
  qui_classes: "Mission critical QCI classes",
  volte_emergencycall: "VoLTE and emergency call",
  application_core: "Core functions with 3GPP standards",
};
export const additionalFeature = {
  fan_regional: segmentOptions,
  oil: segmentOptions,
  smart_city: segmentOptions,
  logistics: segmentOptions,
  rail: segmentOptions,
  manufacturing: segmentOptions,
  defence: segmentOptions,
  utility: segmentOptions,
  industrial: segmentOptions,
  mining: segmentOptions,
  energy: segmentOptions,
  port: segmentOptions,
  airport: segmentOptions,
  others: segmentOptions,
};

export const nonSCRMCaseTypes = [
  "internal_order",
  "demand_forecast"
];

export const caseCategory = {
  BIND: "Binding",
  BUDG: "Budgetary",
};

export const caseData = {
  new: [
    "sCRMID",
    "account",
    "caseName",
    "caseCategory",
    "public_funding_required",
    "sales_channel",
    "distributor",
    "resellers",
    "indirect_resellers",
    "endCustomer",
    "market",
    "country",
    "shipping_country",
    "currency",
    "summary",
    "co_owners"
  ],
  addition_to_existing_edge: [
    "sCRMID",
    "account",
    "caseName",
    "caseCategory",
    "contract_duration",
    "public_funding_required",
    "sales_channel",
    "distributor",
    "market",
    "resellers",
    "indirect_resellers",
    "endCustomer",
    "country",
    "shipping_country",
    "currency",
    "summary",
    "co_owners",
    "network_id"
  ],
  non_commercial_network: [
    "sCRMID",
    "account",
    "caseName",
    "contract_duration",
    "public_funding_required",
    "sales_channel",
    "distributor",
    "resellers",
    "market",
    "endCustomer",
    "country",
    "shipping_country",
    "currency",
    "summary",
    "co_owners"
  ],
  internal_order: [
    //  "sCRMID",
    "account",
    "caseName",
    "contract_duration",
    "sales_channel",
    "market",
    "endCustomer",
    "country",
    "shipping_country",
    "summary",
    "co_owners"
  ],
  demand_forecast: [
    //  "sCRMID",
    "account",
    "caseName",
    "contract_duration",
    "sales_channel",
    "distributor",
    "market",
    "resellers",
    "endCustomer",
    "currency",
    "shipping_country",
    "summary",
    "co_owners",
    "country"
  ],
  frame_agreement: [
    //"sCRMID",
    "account",
    "caseName",
    "contract_duration",
    "sales_channel",
    "distributor",
    "market",
    "resellers",
    "endCustomer",
    "shipping_country",
    "currency",
    "summary",
    "co_owners",
    "country"
  ],
}; //r2
export const stateMachine = [
  {
    caseType: "new",
    subType: caseSubTypes.new[1].types[0].key, //pkg_plug_play
    fieldsRequired: {
      caseName: true,
      sCRMID: null,
      account: true,
      caseCategory: true,
      public_funding_required: null,
      ///contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: true,
      summary: false,
      co_owner: false,
      contract_duration: true,
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "caseCategory",
        "sCRMID",
        "caseDetails",
        "public_funding_required",
        "contract_duration",
        ...caseData.new,
      ],
    },
  },
  {
    caseType: "new",
    subType: caseSubTypes.new[3].types[1].key, //perimeter_nw
    fieldsRequired: {
      caseName: true,
      sCRMID: null,
      account: true,
      caseCategory: true,
      public_funding_required: null,
      //////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: true,
      summary: false,
      co_owner: false,
      contract_duration: true,
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "caseCategory",
        "sCRMID",
        "caseDetails",
        "contract_duration",
        "public_funding_required",
        ...caseData.new,
      ],
    },
  },
  {
    caseType: "new",
    subType: caseSubTypes.new[3].types[0].key, //devices_only
    fieldsRequired: {
      sCRMID: null,
      account: true,
      caseCategory: true,
      public_funding_required: null, // as per mvp its is required set to null since the default value is already given
      ////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: true,
      summary: false,
      co_owner: false,
      caseName: true,
      contract_duration: true,
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "caseCategory",
        "sCRMID",
        "caseDetails",
        "contract_duration",
        "public_funding_required",
        ...caseData.new,
      ],
    },
  },

  {
    caseType: "new",
    subType: caseSubTypes.new[2].types[0].key, //modular
    fieldsRequired: {
      sCRMID: null,
      account: true,
      caseCategory: true,
      public_funding_required: null, // as per mvp its is required set to null since the default value is already given
      ////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: null,
      shipping_country: true,
      currency: true,
      summary: false,
      co_owner: false,
      caseName: true,
      contract_duration: true,
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: ["caseDetails", "contract_duration", ...caseData.new],
    },
  },

  {
    caseType: "new",
    subType: caseSubTypes.new[0].types[0].key, //help
    fieldsRequired: {
      caseName: true,
      sCRMID: null,
      account: true,
      caseCategory: true,
      public_funding_required: null,
      ////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: true,
      summary: false,
      co_owner: false,
      contract_duration: true,
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "caseCategory",
        "caseIndustry",
        "additionalFeature",
        "sCRMID",
        "caseDetails",
        "public_funding_required",
        ...caseData.new,
      ],
    },
  },
  {
    caseType: "addition_to_existing_edge",
    subType: caseSubTypes.addition_to_existing_edge[0].types[0].key, //subscription_renew
    fieldsRequired: {
      sCRMID: null,
      caseName: true,
      account: true,
      caseCategory: true,
      public_funding_required: null, // as per mvp its is required set to null since the default value is already given,
      ////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: true,
      summary: false,
      co_owner: false,
      contract_duration: true,
      network_id : true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "sCRMID",
        "caseCategory",
        "caseDetails",
        "public_funding_required",
        "contract_duration",
        ...caseData.addition_to_existing_edge,
      ],
    },
  },
  {
    caseType: "addition_to_existing_edge",
    subType: caseSubTypes.addition_to_existing_edge[1].types[1].key, //upshell
    fieldsRequired: {
      caseName: true,
      sCRMID: null,
      account: true,
      caseCategory: true,
      public_funding_required: null, // as per mvp its is required set to null since the default value is already given
      ////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: true,
      summary: false,
      co_owner: false,
      contract_duration: true,
      network_id : true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "extensionTool",
        "caseDetails",
        "sCRMID",
        "contract_duration",
        ...caseData.addition_to_existing_edge,
      ],
    },
  },
  {
    caseType: "addition_to_existing_edge",
    subType: caseSubTypes.addition_to_existing_edge[1].types[0].key, //ext_nw
    fieldsRequired: {
      sCRMID: null,
      caseName: true,
      account: true,
      caseCategory: true,
      public_funding_required: null, // as per mvp its is required set to null since the default value is already given,
      ////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: true,
      summary: false,
      co_owner: false,
      contract_duration: true,
      network_id : true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "extensionTool",
        "caseDetails",
        "sCRMID",
        "contract_duration",
        ...caseData.addition_to_existing_edge,
      ],
    },
  },
  {
    caseType: "addition_to_existing_edge",
    subType: caseSubTypes.addition_to_existing_edge[1].types[2].key, //free_replacement
    fieldsRequired: {
      sCRMID: null,
      caseName: true,
      account: true,
      caseCategory: true,
      public_funding_required: null, // as per mvp its is required set to null since the default value is already given,
      ////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: true,
      summary: false,
      co_owner: false,
      contract_duration: true,
      network_id : true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "extensionTool",
        "caseDetails",
        "sCRMID",
        "contract_duration",
        ...caseData.addition_to_existing_edge,
      ],
    },
  },
  {
    caseType: "non_commercial_network",
    subType: caseSubTypes.non_commercial_network[0].types[0].key, //poc
    fieldsRequired: {
      caseName: true,
      sCRMID: null,
      account: true,
      caseCategory: null,
      public_funding_required: null,
      ////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: null,
      summary: false,
      co_owner: false,
      contract_duration: true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "furtherIntegration",
        "caseDetails",
        "contract_duration",
        "network_id",
        ...caseData.non_commercial_network,
      ],
    },
  },
  // {
  //   caseType: "non_commercial_network",
  //   subType: caseSubTypes.non_commercial_network[0].types[1].key, //subscription_renew
  //   fieldsRequired: {
  //     caseName: true,
  //     sCRMID: true,
  //     account: true,
  //     caseCategory: null,
  //     public_funding_required: null,
  //     ////contractDurations: true,
  //     sales_channel: true,
  //     endCustomer: true,
  //     country: true,
  //     market: true,
  //     shipping_country: true,
  //     currency: null,
  //     summary: false,
  //     co_owner: false,
  //     contract_duration: true,
  //   },
  //   allowedComp: {
  //     slice: ["findCaseSlice", "caseDetailsSlice"],
  //     form: [
  //       "furtherIntegration",
  //       "caseDetails",
  //       "contract_duration",
  //       ...caseData.non_commercial_network,
  //     ],
  //   },
  // },
  {
    caseType: "non_commercial_network",
    subType: caseSubTypes.non_commercial_network[1].types[0].key, //new_proof
    fieldsRequired: {
      caseName: true,
      sCRMID: true,
      account: true,
      caseCategory: null,
      public_funding_required: null,
      ////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: null,
      summary: false,
      co_owner: false,
      contract_duration: true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "extensionTool",
        "caseDetails",
        "contract_duration",
        ...caseData.non_commercial_network,
      ],
    },
  },
  {
    caseType: "non_commercial_network",
    subType: caseSubTypes.non_commercial_network[1].types[1].key, //new_demokit
    fieldsRequired: {
      caseName: true,
      sCRMID: true,
      account: true,
      caseCategory: null,
      public_funding_required: null,
      ////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: null,
      summary: false,
      co_owner: false,
      contract_duration: true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "extensionTool",
        "caseDetails",
        "contract_duration",
        ...caseData.non_commercial_network,
      ],
    },
  },
  {
    caseType: "non_commercial_network",
    subType: caseSubTypes.non_commercial_network[1].types[2].key, //new_lab
    fieldsRequired: {
      caseName: true,
      sCRMID: true,
      account: true,
      caseCategory: null,
      public_funding_required: null,
      ////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: null,
      summary: false,
      co_owner: false,
      contract_duration: true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "extensionTool",
        "caseDetails",
        "contract_duration",
        ...caseData.non_commercial_network,
      ],
    },
  },
  {
    caseType: "non_commercial_network",
    subType: caseSubTypes.non_commercial_network[1].types[3].key, //ext_existing
    fieldsRequired: {
      caseName: true,
      sCRMID: true,
      account: true,
      caseCategory: null,
      public_funding_required: null,
      ////contractDurations: true,
      sales_channel: true,
      endCustomer: true,
      country: true,
      market: true,
      shipping_country: true,
      currency: null,
      summary: false,
      co_owner: false,
      contract_duration: true,
      network_id : true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: [
        "extensionTool",
        "caseDetails",
        "contract_duration",
        "network_id",
        ...caseData.non_commercial_network,
      ],
    },
  },
  {
    caseType: "internal_order",
    subType: caseSubTypes.internal_order[0].types[0].key, //new_nw
    fieldsRequired: {
      sCRMID: null,
      caseName: true, //defaults = true
      account: null,
      caseCategory: null, //defaults = true
      public_funding_required: null,
      // contractDurations:false,
      sales_channel: null,
      endCustomer: null,
      country: true,
      market: true,
      shipping_country: true,
      currency: null,
      summary: null,
      co_owner: null,
      contract_duration: true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: ["extensionTool", "caseDetails", "contract_duration", ...caseData.internal_order],
    },
  },
  {
    caseType: "internal_order",
    subType: caseSubTypes.internal_order[0].types[1].key, //ext
    fieldsRequired: {
      sCRMID: null,
      caseName: true, //defaults = true
      account: null,
      caseCategory: null, //defaults = true
      public_funding_required: null,
      // contractDurations:false,
      sales_channel: null,
      endCustomer: null,
      country: true,
      market: true,
      shipping_country: true,
      currency: null,
      summary: null,
      co_owner: null,
      contract_duration: true,
      network_id : true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: ["extensionTool", "caseDetails", "contract_duration","network_id", ...caseData.internal_order],
    },
  },
  {
    caseType: "internal_order",
    subType: caseSubTypes.internal_order[0].types[2].key, //sw_small_addition
    fieldsRequired: {
      sCRMID: null,
      caseName: true, //defaults = true
      account: null,
      caseCategory: null, //defaults = true
      public_funding_required: null,
      // contractDurations:false,
      sales_channel: null,
      endCustomer: null,
      country: true,
      market: true,
      shipping_country: true,
      currency: null,
      summary: null,
      co_owner: null,
      contract_duration: true,
      network_id : true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: ["extensionTool", "caseDetails", "contract_duration","network_id", ...caseData.internal_order],
    },
  },
  {
    caseType: "internal_order",
    subType: caseSubTypes.internal_order[0].types[3].key, //subscription_renew
    fieldsRequired: {
      sCRMID: null,
      caseName: true, //defaults = true
      account: null,
      caseCategory: null, //defaults = true
      public_funding_required: null,
      // contractDurations:false,
      sales_channel: null,
      endCustomer: null,
      country: true,
      market: true,
      shipping_country: true,
      currency: null,
      summary: null,
      co_owner: null,
      contract_duration: true,
      network_id : true
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: ["extensionTool", "caseDetails", "contract_duration","network_id", ...caseData.internal_order],
    },
  },
  {
    caseType: "demand_forecast",
    subType: "none", //none
    fieldsRequired: {
      sCRMID: null,
      caseName: true, //defaults = true
      account: true,
      caseCategory: null, //defaults = true
      public_funding_required: null,
      sales_channel: true,
      endCustomer: false,
      country: null,
      market: true,
      shipping_country: null,
      currency: null,
      summary: null,
      co_owner: null,
      contract_duration: true,
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: ["caseDetails", "contract_duration", ...caseData.demand_forecast],
    },
  },
  {
    caseType: "frame_agreement",
    subType: "none", //none
    fieldsRequired: {
      sCRMID: false,
      caseName: true, //defaults = true
      account: true,
      caseCategory: null, //defaults = true
      public_funding_required: null,
      sales_channel: true,
      endCustomer: false,
      country: null,
      market: true,
      shipping_country: null,
      currency: true,
      summary: null,
      co_owner: null,
      contract_duration: true,
      distributor: false,
    },
    allowedComp: {
      slice: ["findCaseSlice", "caseDetailsSlice"],
      form: ["caseDetails", "contract_duration", ...caseData.frame_agreement],
    },
  },
];
export const salesChannelLogic = {
  DIS: {
    visible: ["distributor", "indirect_resellers", "endCustomer"],
    hidden: ["resellers"],
  },
  RES: {
    visible: ["resellers", "endCustomer"],
    hidden: ["distributor", "indirect_resellers"],
  },
  DIR: {
    visible: ["endCustomer"],
    hidden: ["distributor", "indirect_resellers", "resellers"],
  },
};
export const navBarMenu = {
  HELPDESK: "https://help.dac.nokia.com/hc/en-us",
  DAC: "https://www.dac.nokia.com/",
  ODT: "https://use.dac.nokia.com/order-delivery-home/",
  OOT: "https://use.dac.nokia.com/offer-home/",
  TRAINING: "https://use.dac.nokia.com/offer-home/",
};
export const countryList = countryListData;
export const currencyList = currency;
export const apiTimeout = 60;
export const sCRMRegex = /(^\d{2})\.([\w]{2})\.(\d{6,})/;
