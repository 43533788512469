import React from "react";
import durationIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/time.svg';
import SelectBox from "Pages/subscription/Custom/SelectBox";
import { Typography } from "@nokia-csf-uxr/ccfk";
import useCartActions from "../hooks/useCartActions";

// Generate array of objects from 3 to 60
const months = Array.from({ length: 58 }, (_, i) => ({
    id: i + 3,
    value: `${i + 3} Months`
  }));

  

const SubscriptionDurationSelection = (prop) => {
    const {handleAction} = useCartActions();
    const handleMonthSelection = (selectedItem) => {
        if(selectedItem !== ''){
          handleAction('duration', { item: prop.cartItem, duration: selectedItem });
        }else{
          handleAction('duration', { item: prop.cartItem, duration: 0 });
        }
      };
   return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap:'.2rem', marginTop: '.5rem' }}>
          <div>
          <img src={durationIcon} alt='ship_icon' />
          </div>
          <div>
            <Typography style={{ fontSize: '14px', color: '#333333', fontWeight: '400', marginLeft: '2px' }}>Duration</Typography>
          </div>
          <div style={{width:'38%'}}> 
            <SelectBox
            placeholder="Select"
            maxWidth={false}
            caseInsentiveMatching={true}
            selectedItem={prop.cartItem.duration}
            data={months}
            onUpdateSelection={handleMonthSelection}
            isLoading={false} // Assuming there is no loading state here
          />
          </div>
          {/* <div>
            <Typography style={{ fontSize: '12px', color: '#333333', fontWeight: '400', marginLeft: '2px' }}>Billed annually</Typography>
          </div> */}
        </div>
   )
}

export default SubscriptionDurationSelection;
