import React, { useState, useEffect } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import Label from '@nokia-csf-uxr/ccfk/Label';
import { setProp } from "Store/Actions";
import userStyles from "../Style/UserManagement.module.css";
import HierachyTree from "../components/HierachyTree";
import AccountListSmartSelect from "../components/AccountListSmartSelect";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import { setUserInputFields, updateSelectedPersonas, updateRoles } from "Store/contentState/userManagementSlice";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import CommonFormFields from "./CommonFormFields";
import OOT from "Constants/services/oot";
import { modifyData, getRequestedInfo } from "../utils/constants";
import CommonNameEmailField from "./CommonEmailField";

const UserFormDetails = (prop) => {
  const dispatch = useDispatch();
  const userFullName = useSelector((state: RootState) => state.userManagement.userData.fullName);
  const userEmailAddress = useSelector((state: RootState) => state.userManagement.userData.emailAddress);
  const userOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
  const organizationTypes = useSelector((state: RootState) => state.userManagement.metaData.persona);
  const modifiedOrganizationTypes = modifyData(organizationTypes, 'id', 'name');
  const filteredExternalTypes = modifiedOrganizationTypes.filter((type) => type.id !== "nokia");
  const allAccountsForExternal = useSelector((state: RootState) => state.userManagement.allAccountsForExternal);
  const supplierAccount = useSelector((state: RootState) => state.userManagement.userData.supplier_account_id);
  const accountThreeStar = useSelector((state: RootState) => state.userManagement.userData.threeStarNumber);
  const isAddingNokiaUser = useSelector((state: RootState) => state.userManagement.isAddingNokiaUser);
  const isEmailEntered = useSelector((state: RootState) => state.userManagement.isEmailEntered);
  const [allAccounts, setAllAccounts] = useState([]);
  const isEditMode = prop.mode === 'edit';


 

  const handleUserFullName = (name) => {
    dispatch(setUserInputFields({ field: 'fullName', value: name }));
  };

  
  const handleOrgTypeSelection =  (orgType) => {
    if(orgType){
      console.log(orgType, 'orgType')
      dispatch(updateSelectedPersonas([orgType]))
    }
   
  }

  useEffect(() => {
    if (userOrgType && userOrgType.length > 0 && userOrgType[0] !== "nokia") {
      fetchAccountBasedOnPersona();
    }
  }, [userOrgType]);

  

  const fetchAccountBasedOnPersona = async () => {
    try {
      let filter = "";
      let limit = 10;
      let offset = 0;
      let requested_info = getRequestedInfo(userOrgType[0]);
      console.log(requested_info, 'requested_info');
      let response = {};

      if (requested_info !== "supplier") {
        // Call getAccountsForInternalExternal if requested_info is not "supplier"
        response = await OOT.getAccountsForInternalExternal(filter, limit, offset, requested_info);
      } else {
        // Call getAccountDetailsMerchant if requested_info is "supplier"
        response = await OOT.getAccountDetailsMerchant(limit, offset, [], requested_info);
      }

      if (response['status']) {
        console.log(response['data'], 'response.data');
        if(requested_info !== "supplier"){
          setAllAccounts(response['data']);
        }
        let modifiedAccounts = modifyData(response['data'], requested_info !== "supplier" ? 'parent_account_number' : 'account_id', 'name');
        dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: modifiedAccounts }))
      }else{
        dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: [] }))
      }
    } catch (error) {
      console.log(error);
    }
  };



  const handleAccountSelection = (account) => {
    if(account){
      let filteredAccount = allAccountsForExternal.filter((item) => item.id === account);
      if(userOrgType[0] === "merchant"){
       dispatch(setUserInputFields({ field: 'supplier_account_id', value:  [filteredAccount[0].id]}));
      }else{
       let accountDetails = allAccounts.filter((item) => item.parent_account_number === account);
       dispatch(setUserInputFields({ field: 'threeStarNumber', value:  [accountDetails[0].parent_account_number]}));
       dispatch(setUserInputFields({ field: 'oneStarNumber', value:  [accountDetails[0].customer_account_number]}));
       dispatch(setUserInputFields({ field: 'business_name', value:  [accountDetails[0].business_name]}));
      }
    }
     
  }



  return (
    <>
      <div className={userStyles.dFlex}>  
        <div className={`${userStyles.marginBottom} ${userStyles.width20}`}>
          <Label className={userStyles.userLabel}>Full name <span style={{ color: '#C91514' }}>*</span></Label>

          <TextInput
            id="full-name"
            disabled={prop.mode === "edit" && prop.selectedData.name.trim() !== ""}
            value={userFullName}
            variant={"outlined"}
            placeholder="Enter full name"
            onChange={(e) => handleUserFullName(e.target.value)}
          />

        </div>

       <CommonNameEmailField mode={prop.mode}/>

        {
          ((isEmailEntered && isAddingNokiaUser) || prop.mode === "edit") ? (
            <div className={`${userStyles.marginBottom} ${userStyles.width20}`}>
              <Label className={userStyles.userLabel}>Organization type <span style={{ color: '#C91514' }}>*</span></Label>
              <TextInput
                id="organization-type"
                disabled={true}
                value={userOrgType[0]}
                variant={"outlined"}
                placeholder=""
              />
            </div>
          ) : (
            isEmailEntered && (<div className={`${userStyles.marginBottom} ${userStyles.width20}`}>
              <Label className={userStyles.userLabel}>Organization type <span style={{ color: '#C91514' }}>*</span></Label>
              <SelectBox
                placeholder="Select"
                maxWidth={true}
                caseInsentiveMatching={true}
                selectedItem={userOrgType[0]}
                data={filteredExternalTypes}
                onUpdateSelection={handleOrgTypeSelection}
                isLoading={false}
              />
            </div>)
          )
        }

        {(isAddingNokiaUser || (userOrgType[0] && !isAddingNokiaUser)) && <CommonFormFields mode={prop.mode} />}
      </div>

      <div className={userStyles.dFlex}>
        {((isEmailEntered && isAddingNokiaUser) || (prop.mode === 'edit' && userOrgType[0] === 'nokia')) && <div style={{ display: 'flex' }}>
          <HierachyTree mode={prop.mode}/>
          <AccountListSmartSelect />
        </div>}


        {((isEmailEntered && !isAddingNokiaUser && userOrgType[0] && allAccountsForExternal) || (prop.mode === 'edit' && userOrgType[0] !== 'nokia')) && <div className={userStyles.dFlex}><div className={userStyles.marginBottom}>
          {/* <div style={{ width: '100%' }}> */}
            <Label className={userStyles.userLabel}>Account <span style={{ color: '#C91514' }}>*</span></Label>
            <SelectBox
              placeholder="Select account"
              maxWidth={true}
              caseInsentiveMatching={true}
              selectedItem={userOrgType[0] === "merchant" ? supplierAccount[0] : accountThreeStar[0]}
              data={allAccountsForExternal}
              onUpdateSelection={handleAccountSelection}
              isLoading={false} />
          {/* </div> */}
        </div>
        </div>}
      </div>
    </>
  )
}

export default UserFormDetails;