import React, { useState, useEffect } from 'react';
import Carousel, {
  CarouselIndicator,
  CarouselIndicatorContainer,
  CarouselContent
} from '@nokia-csf-uxr/ccfk/Carousel';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/mainStore';
import productDefaultImage from '../../../Assets/Icons/defProdImage.png';



const ImageCarousel = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const selectedProductId = useSelector((state:RootState) => state.marketplace.selectedDeviceID);
    const selectedData = useSelector((state:RootState) => state.marketplace.selectedDeviceSpecification);
    const [imageUrls, setImageUrls] = useState([]);

    const baseImgUrl = selectedData[0]?.image_base_url ? selectedData[0]?.image_base_url : "https://cnsecestorage.blob.core.windows.net/cnsececontainer/details/"
    useEffect(() => {
      const fetchDynamicImages = async () => {
        const paths = [];
        let index = 1;
        try {
          while (index <=5) {
            const path = `${baseImgUrl}${selectedProductId}/${selectedProductId}_${index}.png`;
            const response = await fetch(path, { 
              method: 'GET', 
            });
            if (response.ok) {
              paths.push(path);
              index++;
            } else {
              break;
            }
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
  
        if (paths.length === 0) {
          paths.push(productDefaultImage);
        }

        setImageUrls(paths);
      };
  
      if (selectedProductId) {
        fetchDynamicImages();
      }
    
  }, [selectedProductId]);
  
    const onSelectChanged = (activeIndex) => {
      setSelectedIndex(activeIndex);
    };
  
    const renderSlides = () => {
      return imageUrls.map((slideData, index) => {
        const keyValue = `${index}-slide`;
        return (
          <div key={keyValue} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:'170px' }}>
            <img src={slideData} alt={`carousel-image-${index}`} style={{ height: '100%' }} />
          </div>
        );
      });
    };
  
    const renderIndicators = () => {
      return imageUrls.map((_, index) => (
        <CarouselIndicator
          key={`indicator-${index}`}
          aria-label={`carousel indicator, ${index + 1} of ${imageUrls.length}, press space to activate`}
          index={index}
          onSelect={onSelectChanged}
          selected={selectedIndex === index}
        />
      ));
    };
  
    return (
      <div style={{ margin: '1rem' }}>
        <Carousel>
          <CarouselContent activeIndex={selectedIndex}>
            {renderSlides()}
          </CarouselContent>
          <CarouselIndicatorContainer style={{ marginTop: '1rem' }}>
            {renderIndicators()}
          </CarouselIndicatorContainer>
        </Carousel>
      </div>
    );
  };
  
  export default ImageCarousel;