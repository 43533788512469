/* eslint-disable */
import React, { useState, useRef } from 'react';
import TabsSection from '../marketplace/common/TabsSection';
import Card from '@nokia-csf-uxr/ccfk/Card';
import OrderList from './OrderList';
import classes from './Style/Merchant.module.css'
import CalendarIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/calendar.svg'
import OrderDialogModal from './OrderDialogModal';

const OrdersBody = () => {

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [showEditOrderrDialog, setShowEditOrderrDialog] = useState(false);
  const [selectdOrder, setSelectedOrder] = useState({})


  const customTabData = [
    { tab: { children: 'All Orders', label: 'Tab One description' } },
    { tab: { children: 'Cancelled', label: 'Tab Two description' } },
    { tab: { children: 'Unpaid', label: 'Tab Three description' } },
    { tab: { children: 'Archived', label: 'Tab Three description' } }
  ];


  const columnDefsList = [
    {
      headerName: 'Order ID', field: 'sales_order_id',
      cellRenderer: (params) => {
        return (
          <div>
            <p onClick={() => handleEditUser(params.data)} >{params.data.sales_order_id}</p>
          </div>
        );
      }
    },
    { headerName: 'Customer', field: 'name', minWidth: 240 },
    { headerName: 'Status', field: 'approval_status', minWidth: 240, flex: 2 },
    { headerName: 'Order date', field: 'so_createdAt', minWidth: 240, flex: 2 },
    { headerName: 'Items', field: 'invoiced_quantity', minWidth: 240, flex: 2 },
    { headerName: 'Total(EUR)', field: 'invoiced_amount_transactional_currency', minWidth: 240, flex: 2 },
  ]


  const handleEditUser = (data) => {
    setShowEditOrderrDialog(true);
    setSelectedOrder(data)
  }


  let tabContentComponents = [
    <OrderList key={0} type="allorders" isActive={activeTabIndex === 0} index={activeTabIndex} columnDefsList={columnDefsList} />,
    <OrderList key={1} type="cancelled" isActive={activeTabIndex === 1} index={activeTabIndex} columnDefsList={columnDefsList} />,
    <OrderList key={2} type="unpaid" isActive={activeTabIndex === 2} index={activeTabIndex} columnDefsList={columnDefsList} />,
    <OrderList key={3} type="archived" isActive={activeTabIndex === 3} index={activeTabIndex} columnDefsList={columnDefsList} />
  ];

  const handleTabSelect = (index) => {
    tabContentComponents = []
    console.log('Tab selected:', index);
    setActiveTabIndex(index);
  };


  return (
    <>

      {/* {showEditOrderrDialog && (
        <OrderDialogModal onClose={() => setShowEditOrderrDialog(false)} selectedUserData={selectdOrder} mode="View order" />
      )} */}
      <Card style={{ padding: '1rem', height: '20vh' }}>

        <div className={classes.gridOrderContainer}>
          <div className={classes.orderThisWeek}>
            <p>This Week</p>
            <div className={classes.orderThisWeekCalendar}>
              <img src={CalendarIcon} width={"70%"} />
            </div>
          </div>
          <div className={classes.verticalDivider} style={{ marginLeft: '-1.1em', marginBottom: '-15px', marginTop: '-15px' }}></div>
          <div className={classes.orderDivCountBlock}>
            Total Orders
            <div className={classes.progresssectionblock}>
              <div className={classes.progresscircle + ' ' + classes.grey}>
                <span className={classes.progresscircleinner + ' ' + classes.greybackgroudradius}></span>
                <span className={classes.ordernumbercount}>190</span>
              </div>
            </div>
          </div>
          <div className={classes.orderDivCountBlock}>
            New
            <div className={classes.progresssectionblock}>
              <div className={classes.progresscircle + ' ' + classes.green}>
                <span className={classes.progresscircleinner + ' ' + classes.greenbackgroudradius}></span>
                <span className={classes.ordernumbercount}>154</span>
              </div>
            </div>
          </div>
          <div className={classes.orderDivCountBlock}>
            Shipped
            <div className={classes.progresssectionblock}>
              <div className={classes.progresscircle + ' ' + classes.blue}>
                <span className={classes.progresscircleinner + ' ' + classes.bluebackgroudradius}></span>
                <span className={classes.ordernumbercount}>190</span>
              </div>
            </div>
          </div>
          <div className={classes.orderDivCountBlock}>
            Pending
            <div className={classes.progresssectionblock}>
              <div className={classes.progresscircle + ' ' + classes.orange}>
                <span className={classes.progresscircleinner + ' ' + classes.orangebackgroudradius}></span>
                <span className={classes.ordernumbercount}>14</span>
              </div>
            </div>
          </div>
          <div className={classes.orderDivCountBlock}>
            Delayed
            <div className={classes.progresssectionblock}>
              <div className={classes.progresscircle + ' ' + classes.red}>
                <span className={classes.progresscircleinner + ' ' + classes.redbackgroudradius}></span>
                <span className={classes.ordernumbercount}>4</span>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div style={{ paddingTop: '20px' }}>
        <Card style={{ padding: '1rem' }}>
          <TabsSection tabData={customTabData} tabContentComponents={tabContentComponents}  //@ts-ignore
            onTabChange={handleTabSelect} pageTabName={"merchant"} />
        </Card>
      </div>
    </>
  );
};

export default OrdersBody;