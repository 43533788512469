import React, {useState} from "react";
import sellerLogo from '../../../Assets/Icons/seller-logo.svg'
import Chip, {
  ChipLabel,
  ChipIcon
} from '@nokia-csf-uxr/ccfk/Chip';
import typeIcon from '../../../Assets/Icons/product_type_icon.svg';
import classes from '../../marketplace/Style/ProductCard.module.css';
import CartItemQuantityModifier from "./CartItemQuantityModifier";
import { Typography } from "@nokia-csf-uxr/ccfk";
import warrantyIcon from '../../../Assets/Icons/warranty_icon.svg';
import factoryIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/factory.svg';
import { getSupplierImage, getFulFillmentType, getSoldByInfo} from "../Utils/commons";
import useCartActions from "../hooks/useCartActions";
import SubscriptionDurationSelection from "../Components/SubscriptionDurationSelection";

const CartItemLeftContainer = (prop) => {
  const { product_sub_type_id, product_type_id } = prop.listItem;
  const imageSource = getSupplierImage(prop.listItem.is_agent_model, prop.listItem.supplier_text);
  const {handleAction} = useCartActions();
  
  // Generate array of objects from 3 to 60
  const months = Array.from({ length: 58 }, (_, i) => ({
    id: i + 3,
    value: `${i + 3} Months`
  }));

  const getLabel = (type, subtype) => {
    if (
      (type === 'Software' && subtype === 'Applications') ||
      (type === 'Services' && subtype === 'Subscription')
    ) {
      return 'Subscription';
    } else {
      return type;
    }
  };


  const label = getLabel(product_type_id, product_sub_type_id);


   

  return (
    <>
      <div style={{ display: 'flex', gap: '.3rem' }}>
        <img src={imageSource} alt="Icon" className={classes.productSupplierLogo}/>
        <Chip size="small" style={{ backgroundColor: '#fff', border: '1px solid #000', borderRadius: '15px' }}>
          <ChipIcon><img src={typeIcon} /></ChipIcon>
          <ChipLabel label={label} style={{ fontSize: '12px' }} />
        </Chip>
        {(prop.listItem.is_agent_model === 0 && prop.listItem.product_type_id === 'Hardware') && <Chip size="small" style={{ backgroundColor: '#E4F0FF', border: '1px solid #8EC2FF', marginRight: '.5rem', borderRadius: '15px' }}>
          <ChipIcon><img src={warrantyIcon} /></ChipIcon>
          <ChipLabel label={"Warranty 1 years"} />
        </Chip>}
      </div>
      <div style={{ color: '#333333', fontSize: '16px', fontWeight: '700',  marginTop: '5px'}}>{prop.listItem.product_description}</div>
      <div style={{ fontSize: '14px', fontWeight: '400', marginTop: '5px', color:'#777777' }}>{prop.listItem.id}</div>
      <CartItemQuantityModifier item={prop.listItem} />
      {/* <div style={{ marginTop: '.4rem' }}>
                                                <div style={{ backgroundColor: "#E4F0FF", border: '1px solid #0049B4', padding: '2px', borderRadius: '2px' }}>
                                                    <Typography style={{ fontSize: "10px" }}>Unlock the additional discount when purchasing 8 or more item</Typography>
                                                </div>
        
                                            </div> */}


     {getSoldByInfo(prop.listItem) && <div style={{ marginTop: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={factoryIcon} alt='ship_icon' />
        <Typography style={{ fontSize: '14px', color: '#333333', fontWeight: '400', marginLeft: '2px' }}>Sold by: <span style={{ fontWeight: 'normal', color: '#757575' }}>{getSoldByInfo(prop.listItem)}</span></Typography>
      </div>} 

      {label === 'Subscription' && (
       
        <SubscriptionDurationSelection cartItem={prop.listItem}/>
      )} 
    </>
  )
}

export default CartItemLeftContainer;