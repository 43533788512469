//@ts-nocheck
import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types"
// import { AppToolbar, Breadcrumbs, Snackbar } from "@nokia-csf-uxr/ccfk";
// import {
//   Breadcrumb,
//   BreadcrumbCompacts,
//   BreadcrumbCompactsHeader,
//   BreadcrumbIcon,
//   LinkSeparator
// } from "@nokia-csf-uxr/ccfk/Breadcrumbs";
import breadcrumIcon from '../../../Assets/Icons/breadcrum_icon.svg'
//@ts-ignore
const BreadCrum = ({ items, separator = '>' }) => {
  // console.log(items, 'itemssss')
  return (
    <div>
      {items.length !== 0 && (
        items.map((item, index) => (
          <React.Fragment key={index}>
            {item.path !== '' ? <Link style={{textDecoration: 'none', color: '#000', fontSize: '16px', fontWeight: 400}} to={item.path}>{item.title}</Link> : <span style={{textDecoration: 'none', color: '#000', fontSize: '16px', fontWeight: 400}}>{item.title}</span>}
            {index < items.length - 1 && <span style={{marginLeft: '.7rem', marginRight: '.7rem'}}><img src={breadcrumIcon}/></span>}
          </React.Fragment>
        ))
      )}
    </div>

    
  );
}

  export default BreadCrum;

  BreadCrum.propTypes = {
    items : PropTypes.array,
    separator:PropTypes.any
  }