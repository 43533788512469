import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import "App.css";
import { Login } from "Pages/Login";

// import { AdvancedTheme } from "@nokia-csf-uxr/ccfk";
import AdvancedTheme from '@nokia-csf-uxr/ccfk/AdvancedTheme';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
// import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import "@nokia-csf-uxr/nokia-design-system-tokens/css/_variables.css";
import "@nokia-csf-uxr/nokia-design-system-tokens/global-style.css";
import { InteractionType } from "@azure/msal-browser";
import { Landing } from "Pages/Landing";
const EnsoBp = () => {
  useMsalAuthentication(InteractionType.Redirect); //to auto redired login
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { refetchOnWindowFocus: false },
    },
  });
  return (
    <>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          
          <AdvancedTheme
            advancedTheme="CCFK FreeForm - Light"
            addGlobalStyle={false}
            addGlobalVariables={true}
          >
            <AuthenticatedTemplate>
              <Landing />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Login />
            </UnauthenticatedTemplate>
          </AdvancedTheme>
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
};

export default EnsoBp;
