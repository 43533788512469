import askeyLogo from '../../../Assets/Icons/askey-logo.png';
import handheldLogo from '../../../Assets/Icons/handheld-logo.png';
import sellerLogo from '../../../Assets/Icons/seller-logo.svg';



export const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}



export const formatDate = (date, format = 'dd.mm.yyyy') => {
  if (!date) return '';

  const day = date.getDate().toString().padStart(2, '0');
  const month = date.getMonth(); // We'll use this to get the month name
  const year = date.getFullYear().toString();

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  if (format === 'yyyy-mm-dd') {
    return `${year}-${(month + 1).toString().padStart(2, '0')}-${day}`;
  }

  if (format === 'dd-MMM-yyyy') {
    return `${day}-${monthNames[month]}-${year}`;
  }

  // Default to 'dd.mm.yyyy'
  return `${day}.${(month + 1).toString().padStart(2, '0')}.${year}`;
};

// Utility function to calculate expiry date
export const calculateExpiryDate = (createdDate, expiryDate) => {
  // if (expiryDate) {
  //     return new Date(expiryDate);
  // }
  const newExpiryDate = new Date(createdDate);
  newExpiryDate.setMonth(newExpiryDate.getMonth() + 3);
  return newExpiryDate;
};


//update few paramaters of offers before displaying in the below
export const updatedOffersList = (data) => {
  const updateData = data.map((item) => {
    const createdDate = item.offer_created_date ? new Date(item.offer_created_date) : new Date(item.createdAt);
    const expiryDate = item.field_offer_expiry_date !== null ? new Date(item.field_offer_expiry_date) : null;

    const formattedDate = formatDate(createdDate, 'dd-MMM-yyyy');
    const formattedExpDate = formatDate(calculateExpiryDate(createdDate, expiryDate), 'dd-MMM-yyyy');
    const formattedCost = `${formatNumberWithCommas(item.field_ofr_order_cart_total)} ${item.field_ofr_order_currency}`
    return {
      ...item,
      offer_created_date: formattedDate,
      offer_expiry_date: formattedExpDate,
      cost: formattedCost
    };
  });

  return updateData;
};


//get supplier icon based on agent_model 
export const getSupplierImage = (is_agent_model, supplier_text) => {
  let imageSource = '';
  if (is_agent_model === 0) {
    imageSource = sellerLogo;
  } else if (is_agent_model === 1) {
    if (supplier_text.toLowerCase().includes('askey')) {
      imageSource = askeyLogo;
    } else if (supplier_text.toLowerCase().includes('handheld') || supplier_text.toLowerCase().includes('mildef')) {
      imageSource = handheldLogo;
    }
  }

  return imageSource;
}


//get fulfillMent type
export const getFulFillmentType = (product) => {
  let shipmentType = '';
  if (product.is_agent_model === 1) {
    if (product.fulfillment_mode === 'Merchant') {
      shipmentType = product.supplier_text;
    }else if(product.fulfillment_mode === 'DAC'){
      shipmentType = "Nokia";
    } else {
      shipmentType = "";
    }
  } else {
    shipmentType = "Nokia";
  }
  return shipmentType;
}

export function getAccountCurrency(currency, currencyIsoCode) {
  if (currency !== null) {
    return currency;
  } else if (currencyIsoCode === 'USD' || currencyIsoCode === 'JPY') {
    return currencyIsoCode;
  } else if (currencyIsoCode === 'AUD' || currencyIsoCode === 'MXN' || currencyIsoCode === 'CAD') {
    return 'USD'
  }
  else {
    return 'EUR';
  }
}

export const shortenText = (text, size = 25, suffix="...") => {
  if(text.length > size){
    return text.substring(0, (size-suffix.length)) + suffix;
  }else{
    return text;
  }
}

export const getSoldByInfo = (product) => {
  let soldBy = "";
  if(product.is_agent_model === 1){
    soldBy = product.supplier_text
  }else if(product.is_agent_model === 0){
    soldBy = "Nokia"
  }else{
    soldBy = ""
  }
  return soldBy;
}
