import { createSlice } from "@reduxjs/toolkit";
import { TPopupVariantTypes } from "Components/Elements/DialogModal";
import {
  countryList,
  currencyList,
  ROLE,
  segmentSolution,
  STATUS,
} from "Components/Misc/CaseDetails_System";
import setObj from "lodash/set";
import { setProp } from "Store/Actions/index";
import { setPropT } from "Store/Actions/ts/index";
// import { setProp } from "./Actions";
// import { setPropT } from "./Actions/ts";
const initialSystemData = {
  isLoading: false,
  currentState: {
    fieldsRequired: {
      distributor: false,
      indirect_resellers: false,
      resellers: false,
      endCustomer: false,
    },
    allowedComp: {
      form: [],
    },
    caseType: "",
    //authSelector.currentState.allowedComp.form
  },
  segmentSolution: segmentSolution,
  countryList: countryList,
  caseTypes: [
    { id: "new", name: "New Case" },
    {
      id: "addition_to_existing_edge",
      name: "Addition to Exisiting Network",
    },
    { id: "non_commercial_network", name: "Non-Commertial (Demo/Lab)" },
    { id: "internal_order", name: "Internal" },
    { id: "demand_forecast", name: "Demand Forecast" },
    { id: "frame_agreement", name: "Frame Agreements or Commitments" },
  ],
  currencyList: currencyList,
  account: [],
  endCustomer: [],
  resellers: [],
  indirect_resellers: [],
  distributor: [],
  networkids : [],
  stack: {
    warn: [],
    error: [],
  },
  popup: {
    msg: "", show: false,

  },
  modal: {
    msg: "",
    show: false,
    variant: "CONFIRM" as TPopupVariantTypes,
    action: [
      /*
 {
                 label: "Close", variant: "call-to-action", onAction: () => {
                   dispatch(showModal({
                     msg: "",
                     panel: { title: '', msg: '' },
                     show: !authSelector.system.modal.show,
                   }))
                 
                 }
  }
      */
    ],
    panel: {
      msg: '',
      title: '',
    },
    title: ""
  },
};

const initialOOTData = {
  status: STATUS.HIDE,
  auth: {
    token: "1c1718326c211058c2e9ae4cd2013115dfa9c0f5c9e8c9e8799b51f10ecdd8d9",
    secret: "e8428fbd69aefdf59f016431176984f8f9a18de6f350b5c10b68722a4c84d77f",
    userId: 20751,
  },
  data: {
    sales_channel: null,
    market: null,
    currency: null,
    end_customer: {
      name: ""
    },
    case_name: "",
    networkids : []
  },
};

const initialData = {
  role: ROLE.INTERNAL,
  tester: false, //A tester can swap b/w roles
  login: {
    displayName: "..",
    adToken: null,
    mail: "",
    id: ""
  },
  system: initialSystemData,
  OOT: initialOOTData,
  status: STATUS.IDLE
};

const authSlice = createSlice({
  name: "authSlice",
  initialState: initialData,
  reducers: {
    showPopup(state, action) {
      //{msg:"",show:bool}
      state.system.popup.msg = action.payload.msg;
      state.system.popup.show = action.payload.show;
    },
    showModal(state, action) {

      //{msg:"",show:bool}
      state.system.modal.msg = action.payload.msg;
      state.system.modal.title = action.payload.title;
      state.system.modal.show = action.payload.show;
      state.system.modal.variant = action.payload.variant;
      if (action.payload.hasOwnProperty('panel')) {
        state.system.modal.panel.msg = action.payload.panel.msg;
        state.system.modal.panel.title = action.payload.panel.title;
      }
      if (action.payload.hasOwnProperty('action')) {
        /*
        Contains [] of {show: false,text: "",func: ""}
        */
        state.system.modal.action = action.payload.action
      }

    },

    changeAppState(state, action) {
      //const found ={...action.payload}
      state.system.currentState = action.payload;
      // console.log('ANT',action.payload)
    },
    showLoader(state, action) {
      state.system.isLoading = action.payload;
    },
    resetAuth(state) {
      // state.status = STATUS.HIDE;
      state.OOT.data["case_name"] = ""; //makes the loadedTool == 'None';
      delete state.OOT.data["case_name"];
      //state.OOT = initialOOTData;
      state.system = initialSystemData;

      state.OOT.status = STATUS.HIDE;
    },
    setOOTAuth(state, action) {
      // state.OOT.auth = action.payload;
      state.OOT.data = action.payload;
    },
    setOOTStatus(state, action) {
      state.OOT.status = action.payload;
    },
    setendCustomer(state, action) {
      state.system.endCustomer = action.payload;
    },
    setNetworkIds(state, action) {
      state.system.networkids = action.payload;
    },
    setAccount(state, action) {

      state.system.account = action.payload;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
    setUser(state, action) {
      if (action.payload) {
        state.login = action.payload;
        state.login.adToken = JSON.parse(action.payload.adToken);
        if (action.payload.mail.includes(".ext")) {
          //if email is ext make it PARTNER
          state.role = ROLE.PARTNER;
        } else {
          state.role = ROLE.INTERNAL; //default : internal
        }
      } else {
        state.system.stack.warn.push({
          errorMsg: "Synchronizing User",
          errorCode: null,
        });
      }
    },
    patchToken(state, action) {
      state.login.adToken.idToken = "";
      state.login.adToken.idToken = action.payload.id_token;
      state.login.adToken.accessToken = "";
      state.login.adToken.accessToken = action.payload.access_token;
    },
    updateAccount(state, action) {
      if (action.payload.type === "account") {
        //  console.log('partrick-1',state.system.account,action.payload)
        state.system.account.push(action.payload);
        //console.log('partrick-2',state.system.account,action.payload)
      }
      if (action.payload.type === "end_customer") {
        state.system.endCustomer.push(action.payload);
      }
      if (action.payload.type === "resellers") {
        // alert('updated')
        state.system.resellers.push(action.payload);
      }
      if (action.payload.type === "indirect_resellers") {
        state.system.indirect_resellers.push(action.payload);
      }
      if (action.payload.type === "distributor") {
        state.system.distributor.push(action.payload);
      }
    },
    setError(state, action) {
      if (["error", "warn"].includes(action.payload.key)) {
        //isExist === undefined
        state.system.stack.error = [];
        state.system.stack.warn = [];
        state.system.stack[action.payload.key].push(action.payload.value);
      }
      if (action.payload === "reset") {
        state.system.stack.error = [];
        state.system.stack.warn = [];
      }
    },
    silentLogin(state, action) { },
    handleSellToParty(state, action) {

      state.system.account = action.payload;
      state.system.distributor = action.payload;
      state.system.indirect_resellers = action.payload;
      state.system.resellers = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(setProp, (state, action: setPropT) => {
      if (action.payload.slice === authSlice.name) {
        setObj(state, action.payload.key, action.payload.value);
      }
    })
  }
});

export default authSlice.reducer;

export const {
  setOOTAuth,
  showLoader,
  setUser,
  setRole,
  patchToken,
  setError,
  setOOTStatus,
  resetAuth,
  updateAccount,
  setendCustomer,
  setAccount,
  changeAppState,
  showPopup,
  showModal,
  handleSellToParty,
  setNetworkIds
} = window['authSlice'] = authSlice.actions;
