import React from "react";
import CommonEmailField from "./CommonEmailField";
import CommonFormFields from "./CommonFormFields";
import userStyles from "../Style/UserManagement.module.css"



const WhiteListedClient = (prop) => {
    return (
        <>
        <div className={userStyles.dFlex}>
            <CommonFormFields mode={prop.mode} />
            <CommonEmailField mode={prop.mode} />
        </div>
        </>
    )

}

export default WhiteListedClient;