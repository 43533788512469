
//@ts-nocheck
/*
--DEFINE PRODUCTS SCEHMA--
- The product's cost,quantity are set to String type, made to number on Boq componenet.
For cost :Number(parseFloat(Number('2592.9333').toFixed(2)).toFixed(2))=>2592.93
For Qty: Math.ceil(0.3) =>1

--ATTRBIUTES--
This are custom added to products in cartBOQ as an identifier containing additional information
cui_type:"product/extension" - if extension products inside array will be recursiviely added, and are to be deleted if the product is removed auto.
cui_linked_product:  defines which product id made the current product to be added
cui_optional:@bool : defines wheather the product added can be deleted  from boq ?
cui_product_category defiens the product cateogry to which it is linked. "PWLS-SK-ACP:BBU"/"PWLS-SK-ACP:POW" | Currently avaialble in Connectivity-Catalog only.

cui_serviceId: Denotes which field only requires {{userentry}} which directly changes the cost,for other fields like {{PM_dur_Months}} & {{HSE_Value_Dropwon}} it doesn't work. since its a part of qtyFormula. |Services//TODO: Think about generic workaround on this.
cui_serviceId_assoc_fields:Notes: unmustache - if qtyFormula is coming empty,the {{userentry}} to be done directly to the cost. |Services
*/

import { RootState } from "Store/mainStore";
import { extractBand, mustache, roundCount, unmustache } from "Utils";


export class PTDAC {
    store: RootState;
    response = { 'business': {}, 'applications': {}, 'connectivity': {}, 'edge': {}, 'devices': {}, 'warranty': {}, 'services': {} };
    constructor(store) {
        this.store = store;
    }
    protected getCapacityLogic() {
        //getdata
        const annual_capacity = this.store.business.data.view.PTDAC.annual_capacity
        //extractData from Machine
        let contSize = Object.entries(this.store.stateMachine.PTDAC.rules.business.setCapacity).map(([size, value]) => {
            if (value.range.max >= annual_capacity && value.range.min <= annual_capacity) {
                return size;
            }
        })
        const [size] = contSize.filter(Boolean);
        this.response['business']['getCapacityLogic'] = { response_code: 1, response_msg: 'Container Size', data: size }
        return this;
    }
    protected getMandatoryAppLogic() {
        let mandatoryApp = [];
        let useCase = this.store.applications.data.options.PTDAC.segment_solution.use_case;
        let applications_devices = this.store.applications.data.options.PTDAC.segment_solution.applications_devices;
        if (useCase.length >= 1) {
            Object.entries(applications_devices).forEach(([key, value]) => {
                if (value.length > 0 && key) {
                    // Get Value from StateMachine
                    let mA = value.map((sol) => this.store.stateMachine.PTDAC.rules.applications.setMandatoryApp[key][sol].mandatory_app)
                    mandatoryApp.push(mA.flat());
                }
            });
            let mandatoryAppSet = Array.from(new Set(mandatoryApp.flat()));
            if (mandatoryAppSet.length === 0) {
                this.response['applications']['getMandatoryAppLogic'] = { response_code: 0, response_msg: "Segment Solution Use Cases: Should be set to decided mandatory app", error: { type: 'pending', field: [] } }
                return;
            }
            this.response['applications']['getMandatoryAppLogic'] = { response_code: 1, response_msg: "Done:Mandatory apps found", data: Array.from(new Set(mandatoryApp.flat())) }
            return this;
        }
        this.response['applications']['getMandatoryAppLogic'] = { response_code: 0, response_msg: "Segment Solution Use Cases: Should be set to decided mandatory app", error: { type: 'pending' } }
        return;

    }

    protected getApplicationTabLogic() {
        //get
        const commercial_model = this.store.business.data.view.PTDAC.commercial_model.toLowerCase(); //capex/opex
        //extract
        let sRA = this.store.stateMachine.PTDAC.rules.applications.setRecommendedApplication;
        if (Object.keys(sRA).length === 0) {
            this.response['applications']['getrecommendedApplicationLogic'] = this.response['applications']['getrecommendedDevicesLogic'] = this.response['applications']['getcatalogLogic'] =
                // { response_code: 0, response_msg: "fetching:Awaiting Api Call to getApplicationTab", error: {type:'fetching'} }
                { response_code: 0, response_msg: "Please wait, fetching data for you.", error: { type: 'fetching' } }
            return;
        }

        const mandatoryAppLogic = this.response['applications']['getMandatoryAppLogic'];
        if (!mandatoryAppLogic.response_code) {
            this.response['applications']['getrecommendedApplicationLogic'] = { response_code: 0, response_msg: "Segment Solution Use Cases: Unable to parse quantity until mandatory app is selected", error: { type: 'pending' } }
            return;
        }
        if (!this.response['business']['getCapacityLogic'].response_code) {
            // this.response['applications']['getrecommendedApplicationLogic'] = { response_code: 0, response_msg: "Unable to get Capacity require for fillers in quantity calculation", error: {type:'pending'} }
            this.response['applications']['getrecommendedApplicationLogic'] = { response_code: 0, response_msg: "Please provide the compulsory input", error: { type: 'pending' } }
            return;
        }
        let globalMandatoryAppsList = this.store.applications.data.options.PTDAC.segment_solution.applications_devices.a1.concat(this.store.applications.data.options.PTDAC.segment_solution.applications_devices.a2)
        let identifiedCapacity = this.response['business']['getCapacityLogic'].data;
        let preDefinedRouters = ['a13', 'a21', 'a22']; //Todo: Hardcoded from backend the routers.
        let NoOfRouters = globalMandatoryAppsList.reduce((a, c) => a + (preDefinedRouters.includes(c) ? 1 : 0), 0);

        let fillers = {
            // TODO : Code Deepnashi + Pratima discussion something related to no. of NIDM/IOT  applications etc.
            number_of_related_devices: globalMandatoryAppsList.length,
            no_of_selection: globalMandatoryAppsList.length,
            dur_years: this.store.business.data.view.PTDAC.contract_duration,
            dur_months: this.store.business.data.view.PTDAC.contract_duration,
            n: identifiedCapacity === 'S' ? 50 : identifiedCapacity === 'M' ? 200 : identifiedCapacity === 'L' ? 300 : identifiedCapacity === 'XL' ? 600 : null,
            m: identifiedCapacity === 'S' ? 25 : identifiedCapacity === 'M' ? 100 : identifiedCapacity === 'L' ? 250 : identifiedCapacity === 'XL' ? 500 : null,
            routers: NoOfRouters
        }

        let applicationQuantityMap = new Map();
        let devicesQuantityMap = new Map();

        if (mandatoryAppLogic.response_code) {
            const recAppList = [];
            mandatoryAppLogic.data.forEach(mP => {
                recAppList.push(this.store.stateMachine.PTDAC.rules.applications.setRecommendedApplication[mP][commercial_model]);
                // if (mP === 'NIDM-IOT') {
                //     recAppList.push(this.store.stateMachine.PTDAC.rules.applications.setRecommendedApplication[mP][commercial_model]);
                // } else if (mP === 'NDAC-GRE') {
                //     recAppList.push(this.store.stateMachine.PTDAC.rules.applications.setRecommendedApplication[mP][commercial_model]);
                // }
            });
            if (recAppList.length === 0) {
                // this.response['applications']['getrecommendedApplicationLogic'] = { response_code: 0, response_msg: "Segment Solution Use Cases: Error: No mandatory app selected yet.", error: {type:'pending'} }
                this.response['applications']['getrecommendedApplicationLogic'] = { response_code: 0, response_msg: "Please select the mandatory application.", error: { type: 'pending' } }
                return;
            }



            //parsing Devices quanitty
            globalMandatoryAppsList.forEach((useCase) => {
                let selectionRules = this.store.stateMachine.PTDAC.rules.applications.setRecommendedDevices?.rule.allIds[useCase];
                if (typeof selectionRules != 'undefined') {
                    let prodOccurance = 0;
                    let calculatedQty = 0;
                    selectionRules.forEach(prod => {

                        if (!devicesQuantityMap.has(prod.si_code)) {
                            prodOccurance = 1;
                            calculatedQty = eval(mustache(prod.formula, fillers))
                            devicesQuantityMap.set(prod.si_code, calculatedQty)
                        } else {
                            prodOccurance++;
                            calculatedQty = (eval(mustache(prod.formula, fillers)) + devicesQuantityMap.get(prod.si_code));
                            devicesQuantityMap.set(prod.si_code, calculatedQty);
                        }

                    });
                    // number_of_related_devices is need for recomm-application,since all values of calculatedQty will be same, settign the last one.
                    fillers.number_of_related_devices = calculatedQty
                }
            })
            //parsing Applications  quanitty
            recAppList.forEach((resp, respIndex) => {
                if (resp.productIds.length >= 1) {
                    //vCPU Consuption: add vcpu of all products
                    fillers['vcpu_consumption'] = resp.products.reduce((a, c) => roundCount(c.vcpu_consumption) + a, 0)
                    resp.productIds.forEach((product_id, index) => {
                        let prodctQtyFormula = resp.rule[product_id].formula;
                        let parsedProductQtyFormula = eval(mustache(prodctQtyFormula, fillers));
                        if (!applicationQuantityMap.has(product_id)) {
                            applicationQuantityMap.set(product_id, parsedProductQtyFormula);
                        }
                    });
                }
            });
            //patching Calculate Quantites to the final Product Array
            let flatProducts = recAppList.flatMap((prod) => prod.products);
            let calculatedApplicationProducts = flatProducts.map((product) => {
                let qty = Math.ceil(applicationQuantityMap.get(product.product_id))
                return Object.assign({}, product, { quantity: qty, cost: roundCount(product.list_price) * qty })
            })
            if (calculatedApplicationProducts.length === 0) {
                // this.response['applications']['getrecommendedDevicesLogic'] = {response_code: 0, response_msg: "Exception: No products found for Recomm. Devices",error:{type:'exception'}}
                this.response['applications']['getrecommendedDevicesLogic'] = { response_code: 0, response_msg: "For current selection, no product available for Recommended Devices", error: { type: 'exception' } }
                return
            }
            //@ts-ignore
            let calculatedDevicesProducts = this.store.stateMachine.PTDAC.rules.applications.setRecommendedDevices?.products.flatMap((product) => {
                let qty = Math.ceil(devicesQuantityMap.get(product.product_id));
                // let productLinkedExtensionData = [];
                // globalMandatoryAppsList.forEach((mp) => {
                //     if (product.hasOwnProperty('extensionData')) {
                //         if (product.extensionData.hasOwnProperty(mp)) {
                //             productLinkedExtensionData.push(...(product.extensionData[mp].extensionData))
                //             if (product.extensionData[mp].hasOwnProperty('extensionData')) {
                //                 iterate(product.extensionData[mp], (child) => {
                //                     productLinkedExtensionData.push((child))
                //                 })
                //             }
                //         }
                //     }
                // })
                // let updatedProduct = {
                //     ...product,
                //     extensionData: productLinkedExtensionData
                // }
                return (devicesQuantityMap.get(product.product_id)) ? Object.assign({}, product, { quantity: qty, cost: roundCount(product.list_price) * qty }) : []
            })
            this.response['applications']['getrecommendedApplicationLogic'] = {
                response_code: 1, response_msg: "Done:Recommendation Applications Ready", data: {
                    products: calculatedApplicationProducts,
                    dna: {
                        applications: applicationQuantityMap,
                    }
                }
            }
            this.response['applications']['getrecommendedDevicesLogic'] = {
                response_code: 1, response_msg: "Done: Call to getApplicationTab, Devices: Ready", data: {
                    products: calculatedDevicesProducts,
                    dna: {
                        devices: devicesQuantityMap
                    }
                }
            }
            //Step1: flat the catalog data to array of products
            let catalog = this.store.stateMachine.PTDAC.rules.applications?.catalog;
            let resCatalog = {};
            let productsToAdd = [];
            // if (Object.keys(catalog.data).length === 0) {
            //     // this.response['applications']['getcatalogLogic'] = { response_code: 0, response_msg: 'Exception: No Products are aligned to Catalog Menu', error: {type:'exception'} }
            //     this.response['applications']['getcatalogLogic'] = { response_code: 0, response_msg: 'No product is available in catalogue.', error: { type: 'exception' } }
            //     return;
            // }
            const userPrepareCart = this.store.cartBoq.data.system.prepare.catalog.map((e) => e.product_id);
            globalMandatoryAppsList.forEach((usersele) => {
                Object.entries(catalog.data).forEach(([menu, value]) => {
                    resCatalog[menu] = [];
                    let pov = [...(value.flatMap((z) => z.products))];
                    let result = [];
                    pov.forEach((element, ei) => {

                        result[ei] = { ...element };
                        // overwritten cost field of each product to list_price, as we are reading cost when dialog is loaded and in prepare items
                        result[ei].cost = result[ei].list_price;
                        if (element.hasOwnProperty('extensionData')) {
                            if (element.extensionData[usersele]) {
                                if (element.extensionData[usersele].hasOwnProperty('extensionData') && element.extensionData[usersele].extensionData.length > 0) {
                                    element.extensionData[usersele].extensionData.forEach((lvl1, l1i) => {
                                        result[ei].extensionData = [];
                                        result[ei].extensionData[l1i] = {};

                                        var qty = eval(mustache(lvl1.formula, fillers))

                                        let mlvl1 = Object.assign({}, lvl1, { quantity: qty, type: "product", cost: roundCount(lvl1.list_price) * roundCount(qty) });
                                        if (userPrepareCart.includes(lvl1.product_id)) {
                                            productsToAdd.push(mlvl1)
                                        }
                                        result[ei].extensionData[l1i] = mlvl1;
                                        if (lvl1.hasOwnProperty('extensionData') && element.extensionData[usersele].extensionData[l1i].extensionData.length > 0) {
                                            lvl1.extensionData.forEach((lvl2, l2i) => {
                                                result[ei].extensionData[l1i].extensionData = []
                                                result[ei].extensionData[l1i].extensionData[l2i] = {}
                                                var qty = eval(mustache(lvl2.formula, fillers));
                                                let mlvl2 = Object.assign({}, lvl2, { quantity: qty, type: "product", cost: roundCount(lvl2.list_price) * roundCount(qty) });
                                                if (userPrepareCart.includes(lvl2.product_id)) {
                                                    productsToAdd.push(mlvl2)
                                                }
                                                result[ei].extensionData[l1i].extensionData[l2i] = mlvl2;
                                            });
                                        }
                                    });
                                }
                            } else {
                                result[ei].extensionData = [];
                            }
                        }
                    })
                    resCatalog[menu] = [...resCatalog[menu], result].flat();
                }
                );
            })

            this.response['applications']['getcatalogLogic'] = {
                response_code: 1, response_msg: "Done: Call to getApplicationTab, Devices: Ready", data: {
                    menus: catalog.menus,
                    catalog: resCatalog,
                    set: {
                        products: productsToAdd,
                    }
                }
            }
            return this;
        }
        // this.response['applications']['getrecommendedApplicationLogic'] = { response_code: 0, response_msg: "Segment Solution Use Cases: Awaiting : mandatoryAppLogic ", data: {type:'pending'} }
        this.response['applications']['getrecommendedApplicationLogic'] = { response_code: 0, response_msg: "Please wait, fetching the logics of mandatory applications", data: { type: 'pending' } }
        return;


    }

    protected rAPConnectivityTab() {
        let isConnectivityTab = this.store.stateMachine.PTDAC.rules.connectivity.hasOwnProperty('recommendedAccessPoint');
        let identifiedCapacity = this.response['business']['getCapacityLogic'].data;
        if (!isConnectivityTab) {
            // this.response['connectivity']['getrAPConnectivityTabLogic'] = { response_code: 0, response_msg: 'Fetching: Awaiting connectivityTab Api Call', error: {type:'fetching'} }
            this.response['connectivity']['getrAPConnectivityTabLogic'] = { response_code: 0, response_msg: 'Please wait, fetching data for you.', error: { type: 'fetching' } }
            return;
        }
        //@ts-ignore
        let rAP = this.store.stateMachine.PTDAC.rules.connectivity?.recommendedAccessPoint;
        if (!rAP.products || (rAP.products && rAP.products.length === 0)) {
            // this.response['connectivity']['getrAPConnectivityTabLogic'] = { response_code: 0, response_msg: 'Error: No Products for Band is specified', error: {type:'exception'} }
            this.response['connectivity']['getrAPConnectivityTabLogic'] = { response_code: 0, response_msg: 'No product available for selected band.', error: { type: 'exception' } }
            return;
        }
        let rule = rAP.rule;
        let products = [...rAP.products];
        let rAPProducts = [];
        let extraWithRecommended = {};
        let fillers = {};
        products.forEach((prod) => {
            //calculate the qty
            let parsedQty = 1;
            let productType = prod.recommendedAccess_productType;
            if (rule.qtyFormula.allIds[productType].hasOwnProperty('calculation')) {
                fillers = {
                    //use for prod.capex/opex.extensionData
                    Ca: (this.store.business.data.view.PTDAC.area === 0) ? rule.qtyFormula.allIds[productType].Ca : this.store.business.data.view.PTDAC.area,
                    A: (this.store.business.data.view.PTDAC.area === 0) ? rule.qtyFormula.allIds[productType]['A'][identifiedCapacity] : this.store.business.data.view.PTDAC.area,
                    Nc: rule.qtyFormula.allIds[productType].hasOwnProperty('Nc') ? rule.qtyFormula.allIds[productType].Nc : roundCount(prod.fillers.Nc), //TODO: Remove the true condition when  mergePratima_withNc branch is completed
                    //use for prod.extensionData if exists.
                    unitcost: 1
                }

                let formula = rule.qtyFormula.allIds[productType].calculation;
                parsedQty = eval(mustache(formula, fillers));
            }
            var qty = parsedQty < 0 ? 1 : Math.ceil(parsedQty);
            // add extra items based on capex and opex model
            let selectedCommericalModel = (this.store.business.data.view.PTDAC.commercial_model).toLowerCase();
            //Step2: Add Calculated Qty to each product for extra items;
            let currentProductIdRule = rule.extraCatalog.allIds[selectedCommericalModel][productType]?.product_id;
            let extensionData = [];
            if (prod.hasOwnProperty('extensionData')) {
                (prod.extensionData).forEach(ext => {
                    //updating the unitCost for extensionData
                    // TODO:IMPROVEMENT - make unit to unitCost
                    fillers['unitCost'] = ext.fillers.unit[identifiedCapacity];
                    extensionData.push(new Object({ ...ext, quantity: eval(mustache(ext.formula, fillers)) }))
                });
            }
            if (prod.hasOwnProperty(selectedCommericalModel)) {
                prod[selectedCommericalModel].products.map((extraProd, index) => {
                    if (currentProductIdRule.includes(extraProd.product_id)) {
                        //Step 4: Update the fillers 
                        // let prodCountWithoutExta = products.filter((prod) => (!currentProductIdRule.includes(prod.product_id))).length;
                        //TODO:ASK PRATIMA
                        // let fillers = {
                        //     total_micro_count: prodCountWithoutExta,
                        //     total_microrrh_count: prodCountWithoutExta,
                        //     total_minmacro_count: prodCountWithoutExta,
                        // };
                        // let qty = Math.ceil(eval(mustache(currenctQuantityFormulaRule, fillers)));
                        extensionData.push(
                            new Object({ ...extraProd, quantity: 1, cost: roundCount(prod.list_price) * qty, cui_type: "extension" })
                        );

                    }
                });
            }
            rAPProducts.push(new Object({ ...prod, quantity: qty, cost: roundCount(prod.list_price) * qty, extensionData }));


        })
        this.response['connectivity']['getrAPConnectivityTabLogic'] = { response_code: 1, response_msg: 'ready: rAP Products ready', data: { products: rAPProducts, extraAPProducts: extraWithRecommended, boqPresentIncr: [] } }
        return this;
    }
    protected catalogConnectivityTab() {

        let isConnectivityTab = this.store.stateMachine.PTDAC.rules.connectivity.hasOwnProperty('catalog');
        if (!isConnectivityTab) {
            this.response['connectivity']['getcatalogConnectivityTabLogic'] = { response_code: 0, response_msg: 'Fetching: Awaiting connectivityTab Api Call', error: { type: 'fetching' } }
            return;
        }
        //@ts-ignore
        let catalog = this.store.stateMachine.PTDAC.rules.connectivity?.catalog;
        if (catalog && !catalog.data) {
            this.response['connectivity']['getcatalogConnectivityTabLogic'] = { response_code: 0, response_msg: 'pending: Awaiting connectivityTab Api Call', error: null }
            return;
        }
        let revisedCatalogPorducts = { ...catalog.data }; // to play with
        let revisedCatalogPorductsWithQty = {}; // to play and change values
        let productsToAddBasedUponMenu = []
        let selectedCommericalModel = (this.store.business.data.view.PTDAC.commercial_model).toLowerCase();
        let selectedRFB = this.store.connectivity.data.options.PTDAC.radio_frequency_band

        let indoorBBUPOWSolutions = [];

        //Step1: flat the catalog data to array of products
        Object.entries(catalog.data).forEach(([menu, prodData]) => {
            //@ts-ignore
            let selectedProdDataProducts = prodData.map((prod) => (prod.hasOwnProperty(selectedCommericalModel)) ? [...prod.products, ...prod[selectedCommericalModel].products] : [...prod.products]);
            prodData.forEach((prod) => {
                if (["PWLS-SK-ACP:BBU", "PWLS-SK-ACP:POW"].includes(prod.product_category_code)) {
                    let updatedProducts = [];
                    if (["PWLS-SK-ACP:BBU"].includes(prod.product_category_code)) {
                        updatedProducts.push(...(prod.products).map((p) => new Object({ ...p, cui_product_category: "PWLS-SK-ACP:BBU" })));
                    }
                    if (["PWLS-SK-ACP:POW"].includes(prod.product_category_code)) {
                        updatedProducts.push(...(prod.products).map((p) => new Object({ ...p, cui_product_category: ["PWLS-SK-ACP:POW"] })));
                    }
                    //meaning the menu is eventually OTHERS
                    indoorBBUPOWSolutions.push(...updatedProducts);
                }
            })

            // productsToAddBasedUponMenu[menu] = (prodData.flatMap((prod) => (prod.hasOwnProperty(selectedCommericalModel)) ? prod[selectedCommericalModel].products : []));
            productsToAddBasedUponMenu[menu] = [];
            revisedCatalogPorducts[menu] = selectedProdDataProducts[0];
            //Step2: Add Calculated Qty to each product to revisedCatalogPorducts @list of menus flatted;
            let currentProductIdRule = catalog.rule.extraCatalog.allIds[selectedCommericalModel][menu]?.product_id; //this is an []
            // let currenctQuantityFormulaRule = catalog.rule.extraCatalog.allIds[selectedCommericalModel][menu]?.qtyFormula;

            // Step 3: check the new list with the currenctProductIDRule array
            // For Others : excluding : PWLS-SK-ACP:BBU",PWLS-SK-ACP:POW", apply band filter to all. (ex: PICO)
            revisedCatalogPorductsWithQty[menu] = revisedCatalogPorducts[menu].map((product, index) => {
                if (currentProductIdRule && currentProductIdRule.includes(product.product_id)) {
                    // changed logic of qty : before qty = total no of micros , now qty = total selected micros
                    //Step 4: Update the fillers 
                    // let prodCountWithoutExta = revisedCatalogPorducts[menu].filter((prod) => (!currentProductIdRule.includes(prod.product_id))).length;

                    // let fillers = {
                    //     total_micro_count: prodCountWithoutExta,
                    //     total_microrrh_count: prodCountWithoutExta,
                    //     total_minmacro_count: prodCountWithoutExta,
                    // };
                    // let qty = Math.ceil(eval(mustache(currenctQuantityFormulaRule, fillers)))
                    productsToAddBasedUponMenu[menu].push(Object.assign({}, product, { quantity: 1, cost: roundCount(product.list_price) * 1 }))
                    return Object.assign({}, product, { quantity: 1, cost: roundCount(product.list_price) * 1, extensionData: productsToAddBasedUponMenu[menu] })
                }
                // overwritten cost field of each product to list_price, as we are reading cost when dialog is loaded and in prepare items
                let totProducts = Object.assign({}, product, { cost: Number(product.list_price), extensionData: productsToAddBasedUponMenu[menu] });
                return totProducts;
            }).filter((e) => extractBand(e.product_name) == (selectedRFB)).concat(indoorBBUPOWSolutions);


        });

        this.response['connectivity']['getcatalogConnectivityTabLogic'] = {
            response_code: 1, response_msg: 'Done: Data Transforming to be used by Menu', data: {
                catalog: revisedCatalogPorductsWithQty,
                menus: catalog.menus.flatMap((tab) => Object.keys(revisedCatalogPorductsWithQty).includes(tab.id) ? tab : []),
                // set: {
                //     products: productsToAddBasedUponMenu
                // }
            }
        }
        return this;
    }
    private spareAdditionConnectivityTab() {
        //Rule:1:Spares for Catalog items <- this makes sure sure is on connectivity
        if (!this.response['connectivity']['getcatalogConnectivityTabLogic'].response_code) {
            // this.response['connectivity']['getspareAdditionConnectivityTab'] = { response_code: 0, response_msg: "pending: Awaiting catalog to be ready", error: {type:'pending'} }
            this.response['connectivity']['getspareAdditionConnectivityTab'] = { response_code: 0, response_msg: "Please wait, fetching catalogue data.", error: { type: 'pending' } }
            return;
        }
        //Rule:2: No subscription/lic or maintenance added to Catalog prepare
        let cartBoQPrepare = this.store.cartBoq.data.system.prepare.catalog.filter((prod) => prod.type === "product");

        // if (cartBoQPrepare.length == 0) {
        //     this.response['connectivity']['getspareAdditionConnectivityTab'] = { response_code: 0, response_msg: "pending: No items added to Boq of type product to filter spares", error: {type:'pending'} }
        //     return;
        // }
        //Rule:3:10% of access points
        this.response['connectivity']['getspareAdditionConnectivityTab'] = {
            response_code: 1, response_msg: "Done: Spares for following access points found", data: {
                spares: (cartBoQPrepare.length === 0) ? [] : cartBoQPrepare.map((prod) => new Object({ ...prod, list_price: roundCount(prod.list_price) * 0.1, cost: roundCount((prod.list_price)) * 0.1, type: "spares" }))
            }
        }
        return this;

    }
    private userSelectionProductsConnectivityTab() {
        //Rule:1:Check weather pvt users is set
        let private_wireless_users = this.store.connectivity.data.options.PTDAC.private_wireless_users;
        let commercial_model = this.store.business.data.view.PTDAC.commercial_model.toLowerCase(); //capex/opex
        if (!private_wireless_users) {
            this.response['connectivity']['userSelectionProductsConnectivityTab'] = { response_code: 0, response_msg: "Please select value for Private Wireless users", error: null }
            return;
        }
        // //Rule:2:Check if prepare.suggest.devices is empty.
        // if (commercial_model == "capex" && this.store.cartBoq.data.system.prepare.suggest.devices.length == 0) {
        //     this.response['connectivity']['userSelectionProductsConnectivityTab'] = { response_code: 0, response_msg: "No Products is avaialble in the catalog", error: { type: 'pending' } }
        //     return;
        // }
        //Rule:3:Add the following products from the rule-set.
        let skipConnectivityRule = this.store.stateMachine.PTDAC.rules.connectivity.userSelectionProducts;
        let skipConnectivityRuleProducts = skipConnectivityRule.rule.allIds[commercial_model][private_wireless_users].product_id;
        let skipConnectivityRuleQtyFormula = skipConnectivityRule.rule.allIds[commercial_model][private_wireless_users].qtyFormula;
        let fillers = {
            conditional: roundCount(skipConnectivityRule.rule.fillers.conditional.default), //- 499 - When in connectiviy use default =1, else in edge tab if gr=yes ,then make it  to 2.
            max_user: Math.max(...(private_wireless_users.split('-').map((e) => parseInt(e)))),
            dur_years: this.store.business.data.view.PTDAC.contract_duration,
            dur_months: this.store.business.data.view.PTDAC.contract_duration,
            device: this.store.cartBoq.data.system.prepare.suggest.devices.length,
        };
        let productsToAdd = [];
        skipConnectivityRule.products.forEach(prod => {
            if (skipConnectivityRuleProducts.includes(prod.product_id)) {
                /* Adding product only if the products are part of products given in rule-set */
                var qty = eval(mustache(skipConnectivityRuleQtyFormula, fillers));
                productsToAdd.push(new Object({
                    ...prod,
                    quantity: qty,
                    cost: roundCount(prod.list_price)
                }))
            }
        });


        this.response['connectivity']['userSelectionProductsConnectivityTab'] = {
            response_code: 1, response_msg: "Done: userSelectionProducts, Add the following products, based upon pvt_wireless users", data: {
                products: productsToAdd
            }
        }
        return this;
    }
    protected spareAndUserSelectionLogic() {
        /* Combining them since they both work parallely. */
        this.spareAdditionConnectivityTab();
        this.userSelectionProductsConnectivityTab();
    }
    protected connectorSwitchEdgeTabLogic() {
        let connector_switch = this.store.edge.data.options.PTDAC.connector_switch;
        if (!connector_switch) {
            // this.response['edge']['connectorSwitchEdgeTabLogic'] = { response_code: 0, response_msg: "Pending: Awaiting user to select connector_switch", error: {type:'pending'} }
            this.response['edge']['connectorSwitchEdgeTabLogic'] = { response_code: 0, response_msg: "Please select value of connector switch.", error: { type: 'pending' } }
            return;
        }
        let containerSize = (this.response['business']['getCapacityLogic']?.response_code) ? this.response['business']['getCapacityLogic'].data : false;
        if (!containerSize) {
            // this.response['edge']['connectorSwitchEdgeTabLogic'] = { response_code: 0, response_msg: "Pending: Awaiting containerSize to be decided", error: {type:'pending'} }
            this.response['edge']['connectorSwitchEdgeTabLogic'] = { response_code: 0, response_msg: "Please enter annual capacity value.", error: { type: 'pending' } }
            return;
        }
        let connectorSwitchRule = this.store.stateMachine.PTDAC.rules.edge;
        if (!connectorSwitchRule.hasOwnProperty('switch')) {
            // this.response['edge']['connectorSwitchEdgeTabLogic'] = { response_code: 0, response_msg: "Pending: Awaiting get-EdgeTab Api Call", error: {type:'pending'} }
            this.response['edge']['connectorSwitchEdgeTabLogic'] = { response_code: 0, response_msg: "Please wait, fetching the data", error: { type: 'pending' } }
            return;
        }
        let geo_redundancy = connectorSwitchRule.switch.rule.allIds[connector_switch][containerSize].geo_redundancy
        let product_ids = connectorSwitchRule.switch.rule.allIds[connector_switch][containerSize].product_id//[]
        let productQtyFormula = connectorSwitchRule.switch.rule.allIds[connector_switch][containerSize].qtyFormula;
        let productsToAdd = [];
        let productsToRemove = connectorSwitchRule.switch.products.filter((prod) => !(product_ids.includes(prod.product_id)));
        //Rule:1 check the the products_ids exist in the products array, if yes append to productsToAdd
        connectorSwitchRule.switch.products.forEach(prod => {
            if (product_ids.includes(prod.product_id)) {
                /* There is no formula only Constant so adding Numbers*/
                var qty = eval(mustache(productQtyFormula, {}));
                productsToAdd.push(new Object({ ...prod, quantity: qty, cost: roundCount(prod.list_price) * qty }))
            }

        });
        this.response['edge']['connectorSwitchEdgeTabLogic'] = {
            response_code: 1, response_msg: "Done: Set the following product to boq & set geo_redundancy", data: {
                geo_redundancy: geo_redundancy,
                products: productsToAdd,
                remove: {
                    products: productsToRemove,
                },

            }
        }
        return this;
    }
    protected switchVariantEdgeTabLogic() {
        if (!this.response['edge']['connectorSwitchEdgeTabLogic'].response_code) {
            /* This will make sure containerSize,connector_switch coming from connectorSwitchEdgeTabLogic is set */
            // this.response['edge']['switchVariantEdgeTabLogic'] = { response_code: 0, response_msg: "Pending: Awaiting connectorSwitchEdgeTabLogic to be decided", error: {type:'pending'} }
            this.response['edge']['switchVariantEdgeTabLogic'] = { response_code: 0, response_msg: "Something wrong in connector switch logic", error: { type: 'pending' } }
            return;
        }
        let switchVariantRule = this.store.stateMachine.PTDAC.rules.edge.set_switchVariant;
        // let connector_switch = this.store.edge.data.options.PTDAC.connector_switch;
        let containerSize = (this.response['business']['getCapacityLogic']?.response_code) ? this.response['business']['getCapacityLogic'].data : false;
        let product_ids = switchVariantRule.rule.allIds[containerSize].product_id;
        let switch_capacity = switchVariantRule.rule.allIds[containerSize].switch_capacity;
        let productQtyFormula = switchVariantRule.rule.allIds[containerSize].qtyFormula;
        let productsToAdd = [];
        let productsToRemove = switchVariantRule.products.filter((prod) => !(product_ids.includes(prod.product_id)));
        //Rule:1 check the the products_ids exist in the products array, if yes append to productsToAdd
        switchVariantRule.products.forEach(prod => {
            if (product_ids.includes(prod.product_id)) {
                /* There is no formula only Constant so adding Numbers*/
                var qty = eval(mustache(productQtyFormula, {}));
                productsToAdd.push(new Object({ ...prod, quantity: qty, cost: roundCount(prod.list_price) * qty }))
            }

        });
        this.response['edge']['switchVariantEdgeTabLogic'] = {
            response_code: 1, response_msg: "Done: Set the following product to boq & set switch_capacity", data: {
                set: {
                    switch_capacity: switch_capacity, //switch variant
                    products: productsToAdd,
                },
                remove: {
                    products: productsToRemove,
                }

            }
        }
        return this;

    }
    protected mIXESubscriptionEdgeTabLogic() {
        let mIXESubscriptionRule = this.store.stateMachine.PTDAC.rules.edge.MIXE_subscription;

        const commercial_model = this.store.business.data.view.PTDAC.commercial_model.toLowerCase();
        let productsToAdd = [];
        if (!this.response['applications']['getMandatoryAppLogic'].response_code) {
            // this.response['edge']['mIXESubscriptionTabLogic'] = { response_code: 0, response_msg: "Pending: Awaiting for getMandatoryAppLogic to be decided", error: {type:'pending'} }
            this.response['edge']['mIXESubscriptionTabLogic'] = { response_code: 0, response_msg: "Need to select Solution use case.", error: { type: 'pending' } }
            return;
        }
        if (!this.response['edge']['switchVariantEdgeTabLogic'].response_code) {
            // this.response['edge']['mIXESubscriptionTabLogic'] = { response_code: 0, response_msg: "Pending: Awaiting:switchVariantEdgeTabLogic:To Get  switch_varaint for fillers", error: {type:'pending'} }
            this.response['edge']['mIXESubscriptionTabLogic'] = { response_code: 0, response_msg: "Failed to get switch variant.", error: { type: 'pending' } }
            return;
        }
        let switch_capacity = this.response['edge']['switchVariantEdgeTabLogic'].data.set.switch_capacity;
        let prodSpecificApp = this.response['applications']['getMandatoryAppLogic'].data.find((app) => mIXESubscriptionRule.fillers.total_cpu[switch_capacity][app]);
        let appQty = (prodSpecificApp) ? roundCount(mIXESubscriptionRule.fillers.total_cpu[switch_capacity][prodSpecificApp]) : 0;
        let fillers = {
            total_cpu: 0,  // total_cpu (per product vcpu + NIDMIOT/EMM/GRE) , IGNORE CAP connectivity.
            dur_years: this.store.business.data.view.PTDAC.contract_duration,
            dur_months: this.store.business.data.view.PTDAC.contract_duration,
        }
        mIXESubscriptionRule.rule.allIds[commercial_model].forEach(prod => {
            //TODO: HardCoded: we are assuming we always get product_id in MIXE_subscription 0 index and qty as its is.
            let product_id = prod.product_id[0];
            let qtyFormula = prod.qtyFormula;
            //modifying the fillers 1. get the product data of product_id from products[]
            let prodData = mIXESubscriptionRule.products.find((p) => p.product_id === product_id);
            fillers['total_cpu'] = roundCount(prodData.vcpu_capacity) + roundCount(appQty)
            var qty = eval(mustache(qtyFormula, fillers));
            productsToAdd.push(...
                /*We are first filtering product and then adding qty here */
                ((mIXESubscriptionRule.products.filter((prod) => prod.product_id === product_id)).map((prod) => new Object({ ...prod, quantity: qty, cost: roundCount(prod.list_price) * qty })))
            );
        });
        this.response['edge']['mIXESubscriptionTabLogic'] = {
            response_code: 1, response_msg: `Done:Auto Add the following products, Do we got Quantity for any product = 0 => ${productsToAdd.some((e) => e.quantity == 0)} `, data: {
                set: {
                    products: productsToAdd,
                }
            }
        }
        return this;
    }
    protected mxieServerEdgeTabLogic() {
        let mxieServerRule = this.store.stateMachine.PTDAC.rules.edge.MXIE_server;
        //Rule: Add products based on MS selection by user
        let mixe_server = this.store.edge.data.options.PTDAC.mxie_server;
        let productsToAdd = [];
        if (!mixe_server) {
            // this.response['edge']['mxieServerEdgeTabLogic'] = { response_code: 0, response_msg: `Error: Mixe server to be selected`, error: {type:'pending'} }
            this.response['edge']['mxieServerEdgeTabLogic'] = { response_code: 0, response_msg: `Please select mxie server type.`, error: { type: 'pending' } }
            return;
        }
        productsToAdd = mxieServerRule.allIds[mixe_server].products.map((prod) => new Object({ ...prod, quantity: 1, cost: Number(prod.list_price) }))
        let costBySize = []
        //Rule:Show the price of each LMS & SMS variant
        mxieServerRule.byIds.map((size) => costBySize[size] = mxieServerRule.allIds[size].total_cost)
        this.response['edge']['mxieServerEdgeTabLogic'] = {
            response_code: 1, response_msg: `Done: Add these products, user-selection: ${mixe_server}`, data: {
                set: {
                    products: productsToAdd,
                    total_cost: Object.assign({}, costBySize),
                    mixe_server: mixe_server
                }
            }
        }
        return this;
    }
    protected mxieConsumptionEdgeTabLogic() {
        if (!this.response['edge']['mxieServerEdgeTabLogic'].response_code) {
            //This ensures the user has selected on of LMS/SMS & then we can reduce on the products generated  for the below logic to work
            // this.response['edge']['mxieConsumptionEdgeTabLogic'] = { response_code: 0, response_msg: "Pending:Awaiting user to select LMS/SMS varaint", error: null }
            this.response['edge']['mxieConsumptionEdgeTabLogic'] = { response_code: 0, response_msg: "Not able to calculate MXIE server consumption", error: null }
            return;
        }
        let mxieServerEdgeProducts = this.response['edge']['mxieServerEdgeTabLogic'].data?.set?.products;
        if (mxieServerEdgeProducts.length === 0) {
            // this.response['edge']['mxieConsumptionEdgeTabLogic'] = { response_code: 0, response_msg: `Exception:No products found for this ${this.mxieServerEdgeTabLogic.data?.set?.mixe_server} varaint`, error: {type:'exception'} }
            this.response['edge']['mxieConsumptionEdgeTabLogic'] = { response_code: 0, response_msg: `For ${this.mxieServerEdgeTabLogic.data?.set?.mixe_server} varaint, no products found for mxie server.`, error: { type: 'exception' } }
            return;
        }
        /*
        @enum (pod,memory,storage,vcpu)
        a,b,c,d = (pod,memory,storage,vcpu)consumption of individual products
        x,y,z,q =  (pod,memory,storage,vcpu)capacity of indiividual products
        */
        //Rule-1:From Application Tab get @params a,b,c,d consumption
        let a = this.response['applications']['getrecommendedApplicationLogic'].data.products.reduce((a, c) => a + Number(c.pod_consumption), 0)
        let b = this.response['applications']['getrecommendedApplicationLogic'].data.products.reduce((a, c) => a + Number(c.memory_consumption), 0)
        let c = this.response['applications']['getrecommendedApplicationLogic'].data.products.reduce((a, c) => a + Number(c.storage_consumption), 0)
        let d = this.response['applications']['getrecommendedApplicationLogic'].data.products.reduce((a, c) => a + Number(c.vcpu_consumption), 0)

        //Rule-2: From Edge Tab get @params x,y,z,q
        let x = this.response.edge['mxieServerEdgeTabLogic'].data.set.products.reduce((a, c) => a + Number(c.pod_capacity), 0)
        let y = this.response.edge['mxieServerEdgeTabLogic'].data.set.products.reduce((a, c) => a + Number(c.memory_capacity), 0)
        let z = this.response.edge['mxieServerEdgeTabLogic'].data.set.products.reduce((a, c) => a + Number(c.storage_capacity), 0)
        let q = this.response.edge['mxieServerEdgeTabLogic'].data.set.products.reduce((a, c) => a + Number(c.vcpu_capacity), 0)

        //Rule-3 From Edge Tab get alpha,beta,gamma,delta for LMS
        let mxieServerRule = this.store.stateMachine.PTDAC.rules.edge.MXIE_server;
        let alpha = mxieServerRule.allIds['LMS'].products.reduce((a, c) => a + Number(c.pod_capacity), 0);
        let beta = mxieServerRule.allIds['LMS'].products.reduce((a, c) => a + Number(c.memory_capacity), 0);
        let gamma = mxieServerRule.allIds['LMS'].products.reduce((a, c) => a + Number(c.storage_capacity), 0);
        let delta = mxieServerRule.allIds['LMS'].products.reduce((a, c) => a + Number(c.vcpu_capacity), 0);
        //Rule -4 Calculate % for 4 params by comparing each individually
        let consumption = [a, b, c, d];
        let capacity = [x, y, z, q];
        let xcapacity = [alpha, beta, gamma, delta];
        let graph = [];

        let mixe_server = this.store.edge.data.options.PTDAC.mxie_server; //options.PTDAC.mxie_server
        let automationTest = capacity.every((val, i) => {
            if (consumption[i] > capacity[i]) {
                // Rule-5:Set LMS if SMS selected,if LMS selected by default throw popup
                if (mixe_server !== 'SMS') {
                    return false;
                }
                graph[i] = roundCount((consumption[i] / xcapacity[i]) * 100)
            } else {
                graph[i] = roundCount((consumption[i] / capacity[i]) * 100)
            }
            return true;
        })
        if (!automationTest) {
            // this.response['edge']['mxieConsumptionEdgeTabLogic'] = {
            //     response_code: 0, response_msg: 'Exception:MS selected is not SMS', error: {
            //         error_msg: "Overloaded",
            //         type:'exception'
            //     }
            // }
            // return; 
            this.response['edge']['mxieConsumptionEdgeTabLogic'] = {
                response_code: 0, response_msg: 'Consumption is overloaded.', error: {
                    error_msg: "Overloaded",
                    type: 'exception'
                }
            }
            return;
        }
        // TODO Rule-6: If the graph coordinates contain % higher than 80% show a popup
        if (graph.some((e) => e > 80)) {
            // this.response['edge']['mxieConsumptionEdgeTabLogic'] = {
            //     response_code: 0, response_msg: 'Exception:MS selected is not SMS', error: {
            //         error_msg: "Capacity cannot be more than 80%",
            //         type:'exception',
            //         dna: {
            //             capacity: capacity,
            //             xcapacity: xcapacity,
            //             consumption: consumption,
            //             graph: graph

            //         }
            //     }
            // }
            // return;
            this.response['edge']['mxieConsumptionEdgeTabLogic'] = {
                response_code: 0, response_msg: 'Consumption is overloaded.', error: {
                    error_msg: "Capacity cannot be more than 80%",
                    type: 'exception',
                    dna: {
                        capacity: capacity,
                        xcapacity: xcapacity,
                        consumption: consumption,
                        graph: graph

                    }
                }
            }
            return;
        }
        let data = [{
            id: "storageValue",
            label: "Storage",
            value: 0
        }, {
            id: "memoryValue",
            label: "Memory",
            value: 0
        },
        {
            id: "vCPUValue",
            label: "vCPU",
            value: 0
        },
        {
            id: "podsValue",
            label: "Pods",
            value: 0
        }
        ];

        let storageValue = 0, memoryValue = 0, vCPUValue = 0, podsValue = 0;
        podsValue = graph[0]
        memoryValue = graph[1]
        storageValue = graph[2]
        vCPUValue = graph[3]

        this.response['edge']['mxieConsumptionEdgeTabLogic'] = {
            response_code: 1, response_msg: `Done: Graph Coordinates generated for this mxie_server`, data: {
                set: {
                    mixe_server: 'LMS',//Only if SMS is selected.
                    barGraph: {
                        data: data.map((e) => new Object({
                            ...e,
                            value: (e.id === "podsValue") ? podsValue : (e.id === "memoryValue") ? memoryValue : (e.id === "storageValue") ? storageValue : (e.id === "vCPUValue") ? vCPUValue : -1
                        }))
                    }
                }, dna: {
                    capacity: capacity,
                    xcapacity: xcapacity,
                    consumption: consumption,
                    graph: graph

                }
            }
        }
        return this;

    }
    protected simCardDevicesTabLogic() {
        let selectedCountry = this.store.caseDetails?.data?.country;
        if (!this.store.stateMachine.PTDAC.rules.devices.hasOwnProperty('sim_card')) {
            this.response['devices']['simCardDevicesTabLogic'] = { response_code: 0, response_msg: "Please wait...", error: { type: 'pending' } }
            return;
        }
        let devicesRule = this.store.stateMachine.PTDAC.rules.devices.sim_card;
        let identifiedCapacity = this.response['business']['getCapacityLogic'].data;
        if (!selectedCountry) {
            // this.response['devices']['simCardDevicesTabLogic'] = { response_code: 0, response_msg: "Pending: Please select a country", error: {type:'pending'} }
            this.response['devices']['simCardDevicesTabLogic'] = { response_code: 0, response_msg: "Please select a country in create case page.", error: { type: 'pending' } }
            return;
        }

        let key = (selectedCountry === 'JP') ? 'JP' : 'OtherCountry';
        let sim_card = devicesRule.rule.allIds[key][identifiedCapacity].simcard;
        let product_id = devicesRule.rule.allIds[key][identifiedCapacity].product_id;
        let qtyFormula = devicesRule.rule.allIds[key][identifiedCapacity].qtyFormula;
        var qty = eval(mustache(qtyFormula, {}));
        this.response['devices']['simCardDevicesTabLogic'] = {
            response_code: 1, response_msg: "Done: set the following values for sim card and products to Boq", data: {
                set: {
                    products: devicesRule.products.flatMap((prod) => (prod.product_id === product_id) ? new Object({ ...prod, quantity: qty, cost: roundCount(prod.list_price) * qty }) : []),
                    sim_card: sim_card,
                }
            }
        }
        return this;

    }
    protected listServicesTabLogic() {
        /*
           This will return list of PRODUCT services
        */
        if (this.store.cartBoq.data.cart.items.length == 0) {
            this.response.services['listServicesTabLogic'] = { response_code: 0, response_msg: "Cart Looks empty ! Please add to proceed.", error: { type: 'pending' } }
            return;
        }
        let servicesTabRule = this.store.stateMachine.PTDAC.rules.services.mpw_services;
        if (!servicesTabRule.hasOwnProperty('NDAC_services')) {
            this.response.services['listServicesTabLogic'] = { response_code: 0, response_msg: "Please wait, fetching the data.", error: { type: 'pending' } }
            return;
        }
        //selections
        let userSelections = {
            ...this.store.services.data.options.PTDAC.userSelections
        }
        let NDAC_servicesToAdd = { allIds: [], byIds: {} };
        let PTDAC_servicesToAdd = { allIds: [], byIds: {} };
        // let servicesID = Object.keys(servicesTabRule);
        servicesTabRule.NDAC_services.rule.byIds.forEach((service, index) => {
            //Rule:1: create new normalizer with values patched
            NDAC_servicesToAdd['allIds'][index] = service;
            //Rule:2: get the value of qtyFormula & total_cost from fillers for serviceDetails and patch the values with fillers.
            let identifiedCapacity = this.response['business']['getCapacityLogic'].data;
            //Rule:3 Calculate Qty first and merge with fillers to calculate rest()
            let serviceFillers = servicesTabRule.NDAC_services.rule.allIds[service].fillers;
            let fillers = {
                ...serviceFillers,
                subcon_implmentation: userSelections.subcon_implmentation,
                travelcost: userSelections.travelcost,
                sitesurvey: userSelections.sitesurvey,
                no_of_installation_locations: (userSelections.no_of_installation_locations),
                PM_dur_months: (userSelections.PM_dur_months) ? userSelections.PM_dur_months : serviceFillers.PM_dur_months,
                HSE_Value: (userSelections.HSE_Value)
            };
            if ((Object.keys(servicesTabRule.NDAC_services.rule.allIds[service].fillers).length !== 0)) {
                if (servicesTabRule.NDAC_services.rule.allIds[service].fillers.hasOwnProperty('PM_dur_months')) {
                    fillers = {
                        ...fillers,
                        PM_dur_months: Boolean(userSelections.PM_dur_months) ? userSelections.PM_dur_months : servicesTabRule.NDAC_services.rule.allIds[service].fillers.PM_dur_months[identifiedCapacity],// has to be replaced by user input if exists.
                    }
                } else if (servicesTabRule.NDAC_services.rule.allIds[service]?.fillers.hasOwnProperty('HSE_Value')) {
                    fillers = {
                        ...fillers,
                        HSE_Value: servicesTabRule.NDAC_services.rule.allIds[service]?.fillers?.HSE_Value[userSelections.HSE_Value]
                    }
                }
            }
            let calculatedQty = (sI) => ((Object.keys(servicesTabRule.NDAC_services.rule.allIds[service].fillers).length == 0) && (!Boolean(servicesTabRule.NDAC_services.rule.allIds[service].serviceDetails[sI].cui_serviceId))) ? servicesTabRule.NDAC_services.rule.allIds[service].serviceDetails[sI].qtyFormula : Math.ceil(eval(mustache(servicesTabRule.NDAC_services.rule.allIds[service].serviceDetails[sI].qtyFormula, fillers)))
            //TODO: For Logiccost UnitCost is not coming
            let calculatedUnitCost = (sI) => (servicesTabRule.NDAC_services.rule.allIds[service].serviceDetails[sI].unitCost) ? servicesTabRule.NDAC_services.rule.allIds[service].serviceDetails[sI].unitCost : 1;
            let modifiedserviceDetails = servicesTabRule.NDAC_services.rule.allIds[service].serviceDetails.flatMap((prod, sI) => {

                let userCountry = this.store.caseDetails.data.country
                if (prod.id === 'S7' && (!(['US', 'EU', 'JP'].includes(userCountry)))) {
                    //Rule: If country of network deployment is other than US, EU, Japan than Logistic cost field will show-up and is manually filled by users
                    return [];
                }
                fillers = {
                    ...fillers,
                    HSE_Value: servicesTabRule.NDAC_services.rule.allIds[service]?.fillers.hasOwnProperty('HSE_Value') ? servicesTabRule.NDAC_services.rule.allIds[service]?.fillers?.HSE_Value[userSelections.HSE_Value] : 0,
                    qty_formula: calculatedQty(sI),
                    unitCost: calculatedUnitCost(sI),
                }
                let cui_serviceId = servicesTabRule.NDAC_services.rule.allIds[service].serviceDetails[sI].cui_serviceId;
                var qty = (cui_serviceId) ? 1 : Math.ceil(calculatedQty(sI));
                var cost = (cui_serviceId) ? userSelections[cui_serviceId] : roundCount(eval(mustache(servicesTabRule.NDAC_services.rule.allIds[service].serviceDetails[sI].hasOwnProperty('total_cost') ? servicesTabRule.NDAC_services.rule.allIds[service].serviceDetails[sI]?.total_cost : "1", fillers)))

                return new Object({
                    ...prod,
                    ...fillers,
                    product_name: `${servicesTabRule.NDAC_services.rule.allIds[service].serviceDetails[sI].service}`,
                    product_id: `${servicesTabRule.NDAC_services.rule.allIds[service].serviceDetails[sI].id}:${service}`,

                    cui_type: "service",
                    cui_serviceId_assoc_fields: Boolean(prod.qtyFormula) ? unmustache(prod.qtyFormula).filter((e) => Object.keys(userSelections).includes(e)) : unmustache(prod.unitCost).filter((e) => Object.keys(userSelections).includes(e)),
                    cui_userSelection: prod['service'] == "HSE Management" ? "HSE_Value" : (service === "P585836") ? "PM_dur_months" : cui_serviceId ? cui_serviceId : false, // 2. In P585836 all pm_dur_months has to be replaced 1. for HSE - a seprate userSelection HSE_Value_Dropdown is required to evaluate HSE_Value 3. Reset others we will take the {{userEntry}} .

                    category_code: service,
                    quantity: qty,
                    list_price: (cui_serviceId) ? roundCount(cost) : roundCount(prod.unitCost),
                    cost: roundCount(cost),
                })
            })

            NDAC_servicesToAdd['byIds'][service] = {};
            NDAC_servicesToAdd['byIds'][service] = Object.assign({}, servicesTabRule.NDAC_services.rule.allIds[service], {
                serviceDetails: modifiedserviceDetails
            })
        });
        servicesTabRule.PTDAC_services.rule.byIds.forEach((service, index) => {
            //Rule:1: create new normalizer with values patched
            PTDAC_servicesToAdd['allIds'][index] = service;
            //Rule:2 Based of no. of aps get the value.
            let noofApSelected = roundCount(this.store.cartBoq.data.system.prepare.suggest.aps.length);
            let radioType =
                noofApSelected < 2
                    ? "AP-Small"
                    : noofApSelected < 5
                        ? "AP-Medium"
                        : noofApSelected < 10
                            ? "AP-Large"
                            : "AP-Mega";

            let fillers = {
                design_planning: servicesTabRule.PTDAC_services.rule.allIds[service].fillers.hasOwnProperty('design_planning') && servicesTabRule.PTDAC_services.rule.allIds[service].fillers['design_planning'][radioType],
                design_tool: servicesTabRule.PTDAC_services.rule.allIds[service].fillers.hasOwnProperty('design_tool') && servicesTabRule.PTDAC_services.rule.allIds[service].fillers['design_tool'][radioType],
                radio_design_tool: servicesTabRule.PTDAC_services.rule.allIds[service].fillers.hasOwnProperty('radio_design_tool') && servicesTabRule.PTDAC_services.rule.allIds[service].fillers['radio_design_tool'][radioType],
                detailed_design: servicesTabRule.PTDAC_services.rule.allIds[service].fillers.hasOwnProperty('detailed_design') && servicesTabRule.PTDAC_services.rule.allIds[service].fillers['detailed_design'][radioType],
                HLD_LLD: servicesTabRule.PTDAC_services.rule.allIds[service].fillers.hasOwnProperty('HLD_LLD') && servicesTabRule.PTDAC_services.rule.allIds[service].fillers['HLD_LLD'][radioType],
                acceptance_remote_support: servicesTabRule.PTDAC_services.rule.allIds[service].fillers.hasOwnProperty('acceptance_remote_support') && servicesTabRule.PTDAC_services.rule.allIds[service].fillers['acceptance_remote_support'][radioType],
                acceptance_testing: servicesTabRule.PTDAC_services.rule.allIds[service].fillers.hasOwnProperty('acceptance_testing') && servicesTabRule.PTDAC_services.rule.allIds[service].fillers['acceptance_testing'][radioType],
            }
            //Rule:3 Evaluating the serviceDetails
            let modifiedserviceDetails = servicesTabRule.PTDAC_services.rule.allIds[service].serviceDetails.flatMap((prod, sI) => {
                let cui_serviceId = prod.cui_serviceId;
                let qty = (cui_serviceId) ? 1 : Math.ceil(eval(mustache(prod.hasOwnProperty('qtyFormula') ? prod.qtyFormula : "1", fillers)));
                fillers = {
                    ...fillers,
                    qty_formula: qty,
                    unitCost: prod.unitCost,
                }
                let cost = (cui_serviceId) ? userSelections[cui_serviceId] : roundCount(eval(mustache(prod.hasOwnProperty('total_cost') ? prod?.total_cost : "1", fillers)));
                return new Object({
                    ...prod,
                    product_name: `${prod.service}`,
                    product_id: `${prod.id}:${service}`,
                    cui_type: "service",
                    category_code: service,
                    quantity: qty,

                    cui_serviceId_assoc_fields: Boolean(prod.qtyFormula) ? unmustache(prod.qtyFormula).filter((e) => Object.keys(userSelections).includes(e)) : unmustache(prod.unitCost).filter((e) => Object.keys(userSelections).includes(e)),
                    cui_userSelection: (cui_serviceId),
                    list_price: (cui_serviceId) ? roundCount(cost) : roundCount(prod.unitCost),
                    cost: roundCount(cost)
                })
            })
            PTDAC_servicesToAdd['byIds'][service] = {};
            PTDAC_servicesToAdd['byIds'][service] = Object.assign({}, servicesTabRule.PTDAC_services.rule.allIds[service], {
                serviceDetails: modifiedserviceDetails
            })

        })
        this.response.services['listServicesTabLogic'] = {
            response_code: 1, response_msg: "Done: Awaiting get-servicesTab Api Call", done: {
                services: {
                    allIds: [...NDAC_servicesToAdd.allIds, ...PTDAC_servicesToAdd.allIds],
                    byIds: { ...NDAC_servicesToAdd.byIds, ...PTDAC_servicesToAdd.byIds },
                    normalized: Object.values({ ...NDAC_servicesToAdd.byIds, ...PTDAC_servicesToAdd.byIds }).map((e) => e.serviceDetails).flat()
                },
            }
        }
        return this;
    }
    // protected listProfServicesTabLogic() {
    //     let profServicesTabRule = this.store.stateMachine.PTDAC.rules.services.ndac_professional_services;
    //     let productsToAdd = [];
    //     Object.entries(profServicesTabRule).forEach(([productID, services]) => {
    //         if (services.hasOwnProperty('mandatory') && services.mandatory.length > 0) {
    //             (services.mandatory.forEach((prod) => {
    //                 productsToAdd.push(new Object({
    //                     ...prod,
    //                     cui_type: "service",
    //                     cui_linked_product: productID,
    //                     cost: prod.quantity * roundCount(prod.list_price),
    //                     cui_optional: false,
    //                 }))
    //             }));

    //         }
    //         if (services.hasOwnProperty('optional') && services.optional.length > 0) {
    //             (services.optional.forEach((prod) => {
    //                 productsToAdd.push(new Object({
    //                     ...prod,
    //                     cui_type: "service",
    //                     cui_linked_product: productID,
    //                     cost: prod.quantity * roundCount(prod.list_price),
    //                     cui_optional: true,
    //                 }))
    //             }));
    //         }
    //     })
    //     this.response.services['listProfServicesTabLogic'] = {
    //         response_code: 1, response_msg: "NDAC Professional Services will be added on add to cart as per your current BOQ", data: {
    //             set: {
    //                 products: productsToAdd,
    //             }
    //         }
    //     }
    // }
    protected plansWarrantyTab() {
        /*
        Returns the products based on  (basic,prem,prof) availibilty linked to account when based on user -selection
        */
        let subscriptionPlansOptions = this.store.stateMachine.PTDAC.refs.subscriptionPlansOptions;
        let plansToAdd = { byPlan: {}, allIds: [] };
        let warrantyTabRule = this.store.stateMachine.PTDAC.rules.warranty;
        if (!warrantyTabRule.hasOwnProperty('NDAC-SERV-PS-SLA')) {
            this.response['warranty']['plansWarrantyTab'] = {
                response_code: 0,
                response_msg: "Pending: Awaiting get-sla_warranty Api Call",
                error: { type: 'pending' }
            }
            return;
        }
        subscriptionPlansOptions.allIds.forEach((plan) => {
            let isPlanAllowed = warrantyTabRule["NDAC-SERV-PS-SLA"].find((e) => e.product_name.toLowerCase().includes(plan));
            if (isPlanAllowed) {
                //Rule:2 unwanted plans are removed, now Add the products to available plans;
                plansToAdd.byPlan[plan] = { ...subscriptionPlansOptions.byPlan[plan] }
                plansToAdd.byPlan[plan] = Object.assign({}, plansToAdd.byPlan[plan], { products: { ...isPlanAllowed, quantity: 1, cost: Number(isPlanAllowed.list_price) } })
                plansToAdd.allIds.push(plan);

            }
        })
        //Rule3: Merge the plan by ids filtered above.
        plansToAdd.byPlan = { ...(_.pick(plansToAdd.byPlan, plansToAdd.allIds)) }

        this.response['warranty']['plansWarrantyTab'] = {
            response_code: 1,
            response_msg: 'Plans are ready, Products are aligned to available plans.',
            data: {
                plans: plansToAdd
            }
        }
        return this;
    }
    protected extendDurationWarrantyTab() {
        /*
        Returns the products to be added when users extends the warranty by specific duration
        Here we assume the first value at pos 0 is default of 12 months and if 2nd value at pos 1 is set then extend is selected
        [12,extendDuration]
        */
        if (!this.response['warranty']['plansWarrantyTab'].response_code) {
            this.response.warranty['extendDurationWarrantyTab'] = {
                response_code: 0,
                // response_msg: "Pending: Awaiting plansWarrantyTab to be executed",
                response_msg: "No warrenty plans are available",
                error: { type: 'pending' }
            }
            return;
        }
        /*Rule:1 Check if extendDuration is set */
        if (this.store.warranty.data.options.PTDAC.warranty.length !== 2) {
            this.response.warranty['extendDurationWarrantyTab'] = {
                response_code: 0,
                response_msg: "Pending: Awaiting extendDuration to be set",
                error: { type: 'pending' }
            }
            return;
        }
        let warrantyTabRule = this.store.stateMachine.PTDAC.rules.warranty;
        this.response.warranty['extendDurationWarrantyTab'] = {
            response_code: 1,
            response_msg: "Done: Add the following products on ATC",
            data: {
                products: warrantyTabRule["NDAC-SERV-WAR"].flatMap((e) => new Object({ ...e, quantity: 1, cost: e.list_price ? e.list_price : 0 })),
            }
        }
        return this;

    }
    navigateBusiness() {
        this.getCapacityLogic()
        return this;
    }
    navigateApplication() {
        this.getMandatoryAppLogic()
            ?.getApplicationTabLogic() //Geting application tab + Parsing
        return this;
    }
    navigateConnectivity() {
        this.rAPConnectivityTab()
        this.catalogConnectivityTab()?.spareAndUserSelectionLogic();
        return this;
    }
    navigateEdge() {
        this.connectorSwitchEdgeTabLogic()?.switchVariantEdgeTabLogic()?.mIXESubscriptionEdgeTabLogic()?.mxieServerEdgeTabLogic()?.mxieConsumptionEdgeTabLogic();
        return this;
    }
    navigateDevices() {
        this.simCardDevicesTabLogic();
        return this;
    }
    navigateServices() {
        this.listServicesTabLogic();//?.listProfServicesTabLogic();
        return this;
    }
    navigateWarranty() {
        this.plansWarrantyTab()?.extendDurationWarrantyTab();
        return this;
    }
}