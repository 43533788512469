
import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
import { setPropT } from "Store/Actions/ts";
import { roundCount, updateExistingProduct } from "Utils";
import _ from "lodash";
const cartBoqSlice = createSlice({
    name: 'cartBoq',
    initialState: {
        data: {
            system: {
                prepare: { catalog: [], suggest: { aps: [], devices: [] } },
            },
            cart: {
                items: [],
                total_cost: 0,
            }
        },
        status: STATUS.IDLE
    },
    reducers: {
        addCartItem(state, action) {
            let cartItems = ((state.data.cart.items));
            if (Array.isArray(action.payload)) {
                action.payload.forEach((product) => {
                    cartItems = updateExistingProduct(cartItems, product, "+");
                    console.log('roti', cartItems)
                });
            } else {
                const existingProductIndex = _.findIndex(cartItems, { product_id: action.payload.product_id });
                if (existingProductIndex !== -1) {
                    cartItems = updateExistingProduct(cartItems, action.payload, "+");

                } else {
                    cartItems.push(action.payload);
                }
            }
            state.data.cart.items = _.uniqBy(cartItems, v => [v.product_id, v.type].join());
            //@ts-ignore
            state.data.cart.total_cost = _.sumBy(cartItems, c => Number(c.cost));
        },
        removeCartItem(state, action) {
            let cartItems = ((state.data.cart.items));
            if (Array.isArray(action.payload)) {
                action.payload.forEach((product) => {
                    cartItems = updateExistingProduct(cartItems, product, "-");
                });
            } else {
                const existingProductIndex = _.findIndex(cartItems, { product_id: action.payload.product_id });
                if (existingProductIndex !== -1) {
                    cartItems = updateExistingProduct(cartItems, action.payload, "-");
                } else {
                    cartItems.push(action.payload);
                }
            }
            state.data.cart.items = _.uniqBy(cartItems, v => [v.product_id, v.type].join());
            //@ts-ignore
            state.data.cart.total_cost = _.sumBy(cartItems, c => Number(c.cost));
        },
        updateCartItem(state, action) {
            /*
            This updates all array of products with the quantity, if not added then it adds with the qty.
            #[{product,quantity}]
            */
            let cartItems = ((state.data.cart.items));
            action.payload.products.forEach(product => {
                const existingProductIndex = _.findIndex(cartItems, { product_id: product.product_id });
                if (existingProductIndex !== -1) {
                    cartItems[existingProductIndex].quantity = action.payload.quantity;
                    cartItems[existingProductIndex].cost = action.payload.quantity *roundCount(cartItems[existingProductIndex].list_price);
                } else {
                    cartItems.push(new Object({ ...product, quantity: action.payload.quantity,cost:action.payload.quantity*roundCount(product.list_price) }))
                }
            });
            state.data.cart.items = _.uniqBy(cartItems, v => [v.product_id, v.type].join());
            //@ts-ignore
            state.data.cart.total_cost = _.sumBy(cartItems, c => Number(c.cost));
        },
        prepareCart(state, action) {
            //@selected_catalog : {"method":"",product:"",type:""}

            if (action.payload.method === "add") {
                let cartItems = [];
                switch (action.payload.type) {
                    case "catalog":
                        cartItems = state.data.system.prepare.catalog;

                        state.data.system.prepare.catalog.push(action.payload.product);
                        state.data.system.prepare.catalog = _.uniqBy(cartItems, v => [v.product_id, v.type].join());
                        break;

                    case "suggest.aps":
                        cartItems = state.data.system.prepare.suggest.aps;
                        state.data.system.prepare.suggest.aps.push(action.payload.product);
                        state.data.system.prepare.suggest.aps = _.uniqWith(cartItems, _.isEqual);
                        break;
                    case "suggest.devices":
                        cartItems = state.data.system.prepare.suggest.devices;
                        state.data.system.prepare.suggest.devices.push(action.payload.product);
                        state.data.system.prepare.suggest.devices = _.uniqWith(cartItems, _.isEqual);
                        break;
                }
                return;
            }
            if (action.payload.method === "remove") {
                let res = [];
                switch (action.payload.type) {
                    case "catalog":
                        res = state.data.system.prepare.catalog.filter((e) => e.product_id !== action.payload.product.product_id);
                        state.data.system.prepare.catalog = res;
                        break;
                    case "suggest.aps":
                        res = state.data.system.prepare.suggest.aps.filter((e) => e.product_id !== action.payload.product.product_id);
                        state.data.system.prepare.suggest.aps = res;
                        break;
                    case "suggest.devices":
                        res = state.data.system.prepare.suggest.devices.filter((e) => e.product_id !== action.payload.product.product_id);
                        state.data.system.prepare.suggest.devices = res;
                        break;
                }
                return;
            }

            if (action.payload.method === "incQty") {

                switch (action.payload.type) {
                    case "catalog":
                        let findCartItemIndex = state.data.system.prepare.catalog.findIndex((e) => e.product_id === action.payload.product.product_id);
                        if (findCartItemIndex !== -1 && (!state.data.system.prepare.catalog[findCartItemIndex].hasOwnProperty('quantity'))) {
                            state.data.system.prepare.catalog[findCartItemIndex]['quantity'] = 1;
                        }
                        let item_cost = Number(state.data.system.prepare.catalog[findCartItemIndex].list_price);// state.data.system.prepare.catalog[findCartItemIndex].quantity);

                        if (findCartItemIndex !== -1 && ((state.data.system.prepare.catalog[findCartItemIndex].quantity >= 0))) {
                            state.data.system.prepare.catalog[findCartItemIndex].quantity = state.data.system.prepare.catalog[findCartItemIndex].quantity + 1;
                            state.data.system.prepare.catalog[findCartItemIndex].cost = (state.data.system.prepare.catalog[findCartItemIndex].quantity) * (item_cost);
                        }
                        break;
                }
                return;
            }
            if (action.payload.method === "decQty") {
                switch (action.payload.type) {
                    case "catalog":
                        let findCartItemIndex = state.data.system.prepare.catalog.findIndex((e) => e.product_id === action.payload.product.product_id);
                        let item_cost = Number(state.data.system.prepare.catalog[findCartItemIndex].list_price);// / state.data.system.prepare.catalog[findCartItemIndex].quantity);
                        if (findCartItemIndex !== -1 && (state.data.system.prepare.catalog[findCartItemIndex].quantity > 0)) {
                            state.data.system.prepare.catalog[findCartItemIndex].quantity = state.data.system.prepare.catalog[findCartItemIndex].quantity - 1;
                            state.data.system.prepare.catalog[findCartItemIndex].cost = (state.data.system.prepare.catalog[findCartItemIndex].quantity) * (item_cost);
                        }
                        break;

                }
                return;
            }
            state.data.system.prepare.catalog = action.payload;

        }
    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: setPropT) => {
            if (action.payload.slice === cartBoqSlice.name) {
                _.set(state.data, action.payload.key, action.payload.value);
            }
        })
    }
})
export default cartBoqSlice.reducer;
export const { removeCartItem, addCartItem, updateCartItem, prepareCart } = cartBoqSlice.actions;