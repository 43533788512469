import React, { useEffect } from "react";
import classes from './../marketplace/Style/Cart.module.css'
import CartItemsList from "./CartItemsList";
import CartSummary from "./CartSummary";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import CartEmptyPage from "./Components/CartEmptyPage";
import { setProp } from "Store/Actions";
import OOT from "Constants/services/oot";
import Loader from "Components/Elements/Loader";


const CartBody = () => {
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    
    const cart = marketplaceSelector.cart;

    if (cart.length === 0) {
        return <CartEmptyPage />;
    }

    if (marketplaceSelector.isCartLoading) {
        return <Loader />;
    }

    return (
        <div className={classes.cartBody}>
            <div className={classes.cartList}>
                <div style={{ borderBottom: '1px solid #0000001F' }}>
                    <div style={{ fontWeight: 'bold', fontSize: '1rem', padding: '.5rem' }}>Items in the cart</div>
                </div>
                <CartItemsList />
            </div>
            <div className={classes.cartSummary}>
                <div style={{ borderBottom: '1px solid #0000001F' }}>
                    <div style={{ fontWeight: 'bold', fontSize: '1rem', padding: '.5rem' }}>Cart summary</div>
                </div>
                <CartSummary />
            </div>
        </div>
    );
};

export default CartBody;

