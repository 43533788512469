/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import Button from '@nokia-csf-uxr/ccfk/Button';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import OOT from 'Constants/services/oot';
import { setQuotations } from 'Store/contentState/marketplaceSlice';
import deleteIcon from '../../../Assets/Icons/delete.svg';
import eyeIcon from '../../../Assets/Icons/eye_icon.svg';
import QuotationsView from './QuotationsView';
import UploadPO from '../orders/UploadPO';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import OrderInvoice from './OrderInvoice';
import OfferViewDialog from '../OfferViewDialog';
import { updatedOffersList } from '../Utils/commons';





const ActiveQuotations = () => {
  const [showQuoteViewDialog, setShowQuoteViewDialog] = useState(false);
  const [showUploadPODialog, setShowUploadPODialog] = useState(false);
  const [offer, setOffer] = useState({})


  const dispatch = useDispatch();
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
 
  const gridApi = useRef(null);


  useEffect(() => {
    if (marketplaceSelector.filters.selection.partner && marketplaceSelector.accountDetails.length > 0 && marketplaceSelector.filters.selection.customer) {
      //getQuotations();
      getOffers();
    } else {
      dispatch(setQuotations([]))
    }
  }, [marketplaceSelector.filters.selection.partner, marketplaceSelector.accountDetails, marketplaceSelector.filters.selection.customer])

 

  const getOffers = async () => {
    try {
      const oneStarId = marketplaceSelector.filters.selection.partner;
      const endCustomer = marketplaceSelector.accountDetails[0]['customer_one_star_num'];
      let filter = "offer_header_data.field_ofr_order_source = 'marketplace' and offer_header_data.field_ofr_order_status = 'pendingapproval'"
      const res = await OOT.getQuotationAPI(oneStarId, endCustomer, filter)
      if(res.status){
        // console.log(res.data, 'offers')
        const result = updatedOffersList(res.data);
        const updatedResult = result.map((item) => {
          return {
            ...item,
            "endCustomerName":marketplaceSelector.accountDetails[0]?.end_customer_name,
          }
        })
        dispatch(setQuotations(updatedResult))
      }else{
        dispatch(setQuotations([]))
      }
    } catch (error) {
      console.error(error)
    }
  }

  

  const handleOrder = (offer) => {
    setOffer(offer);
    setShowUploadPODialog(true)
  }


  const handleQuotation = async (offer) => {
    setOffer(offer);
    setShowQuoteViewDialog(true);
  }

  return (

    <>

      {showQuoteViewDialog && (
        <OfferViewDialog onClose={() => setShowQuoteViewDialog(false)} selectedOffer={offer} offerType="Quotation"/>
      )}

      {showUploadPODialog && (
        <UploadPO onClose={() => setShowUploadPODialog(false)} selectedOffer={offer} orderMode="quotationOrder" modalTitle="Upload purchase order (PO)"/>
      )}
      <div id='quotations' style={{ width: '100%', height: '100%', marginTop: '10px' }}>
        <DataGrid
          // variant={"compact"}
          suppressMovableColumns={false}
          suppressColumnMoveAnimation={false}
          suppressRowClickSelection={true}
          isRowDrag={false}
          wrapperProps={{
            style: {
              width: "100%"
              // width: 600,
            }
          }}
          sideBar
          disableAutoloadStyles
          suppressContextMenu
          onGridReady={(params) => {
            gridApi.current = params.api;
          }}
          rowData={marketplaceSelector.quotations}
          columnDefs={[{ headerName: 'Quotation ID', 
          field: 'offer_id', 
          cellRenderer: (params) => (
            <a href={`${process.env.REACT_APP_OOT}/offer/edit/${params.data.offer_id}`} target="_blank" rel="noopener noreferrer" style={{  color:'#333333'}}>
                {params.data.offer_id}
            </a>
        ),
          cellStyle: { 'borderLeft': '3px solid', 'borderLeftColor': 'var(--g-color-app-banner-background)' } },

          { headerName: 'Quotation name', field: 'offer_title', minWidth: 240, flex: 2 },
          {
            headerName: 'Account',
            field: 'name',
            minWidth: 115,
            //flex: 1,

          },
          {
            headerName: 'End customer',
            field: 'endCustomerName',
            minWidth: 115,
            //flex: 1,

        },
          {
            headerName: 'Created date',
            field: 'offer_created_date',
            // minWidth: 115,
            //flex: 1,

          },
          {
            headerName: 'Expiry date',
            field: 'offer_expiry_date',
            // minWidth: 115,
            //flex: 1,

          },
          {
            headerName: 'Owner',
            field: 'owner_email',
            minWidth: 115,
            //flex: 1,

          },
          {
            headerName: 'Make an order',
            field: '',
            minWidth: 30,
            cellRenderer: (params) => <div><Button variant={'secondary'} onClick={() => handleOrder(params.data)}><ButtonText>Request</ButtonText></Button></div>
          },
          {
            minWidth: 30,
            // flex: 0.3,
            hide: !true,
            cellRenderer: (params) => <div style={{ display: 'flex', justifyContent: 'center' }}><img src={eyeIcon} onClick={() => handleQuotation(params.data)} /></div>,
          },
          ]}
          key={'quotations'}
          defaultColDef={{
            editable: false,
            sortable: true,
            flex: 0,
            minWidth: 30,
            filter: true,
            resizable: false,
          }}
          overlayNoRowsTemplate='No quotations are available'
          isInlineRowActions={false}
          domLayout='autoHeight'
          onFirstDataRendered={(params) => {
            params.columnApi.autoSizeAllColumns();
            // onFirstDataRendered(params);
          }}
        />
      </div>

      {/* <PDFDownloadLink document={<OrderInvoice pdfData={offer} accountData={marketplaceSelector.accountDetails}/>} fileName="Quotation.pdf" style={{textDecoration: 'none'}}>
              {({ loading }) => (loading ? 'Loading document...' : <ButtonText>Download</ButtonText>)}
            </PDFDownloadLink> */}

    </>
  );
}

export default ActiveQuotations;