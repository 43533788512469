import React, { useState, useEffect, useRef } from "react";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedPersonas, updateSelectedViewAccess, setUsersLoading, setUsers, clearUserModel, updateDataElement, updateRoles, setUserInputFields } from "Store/contentState/userManagementSlice";
import { RootState } from "Store/mainStore";
import OOT from "Constants/services/oot";

import _findIndex from 'lodash/findIndex';
// FF icon
import DataElementList from "./DataElementList";
import InlineFeedbackMessage from '@nokia-csf-uxr/ccfk/InlineFeedbackMessage';
import paginationSlice from "Store/contentState/paginationSlice";
import { showModal } from "Store/auth";
import { useUserActions } from "./hooks/useUserActions";

import CloseIconLatest from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import { setProp } from "Store/Actions"

import userStyles from "./Style/UserManagement.module.css";



import UserTypeSelection from "./UserDialogContainer/UserTypeSelection";
import UserFormDetails from "./UserDialogContainer/UserFormDetails";
import MachineFormDetails from "./UserDialogContainer/MachineFormDetails";
import FooterButtons from "./UserDialogContainer/FooterButtons";
import CommonFormFields from "./UserDialogContainer/CommonFormFields";
import WhiteListedClient from "./UserDialogContainer/WhiteListedClient";

const DIALOG_HEIGHT = "90%";
const DIALOG_WIDTH = "90%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};

type IUserModalProp = {
    onClose: () => void;
    mode: string;
    selectedUserData: any;
    typeOfUser: string;
};

const UserDialogNew = ({ onClose, mode, selectedUserData, typeOfUser }: IUserModalProp) => {
    const dispatch = useDispatch();
    const authSlice = useSelector((state: RootState) => state.authSlice);
    const userSliceData = useSelector((state: RootState) => state.userManagement.userData);
    const selectedUserType = useSelector((state: RootState) => state.userManagement.selectedUserType);
    const selectedOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
    const [disableBtn, setDisableBtn] = useState(false);
    const [userSuccessMsg, setUserSuccessMsg] = useState("");
    const [userErrorMsg, setErrorMsg] = useState("");
    const [successShow, setSuccessShow] = useState(false);
    const [errorShow, setErrorShow] = useState(false);

    const getAllUsersList = useUserActions(typeOfUser)

    const handleDialogClose = () => {
        dispatch(clearUserModel());
        dispatch(setProp({ 'slice': 'userManagement', 'key': 'selectedUserType', 'value': 'user' }));
        if (authSlice.system.modal.variant == "ERROR") {
            dispatch(
                showModal({
                    msg: "",
                    panel: { title: "", msg: "" },
                    show: false
                })
            );
        }
        onClose();

    }

    const handleSubmit = async () => {
        // Submit logic goes here
        setDisableBtn(true);
        let dataPayload = {};
        if (mode === 'add') {
            dataPayload = {
                "id": userSliceData.id,
                "fullName": userSliceData.fullName,
                "email": userSliceData.emailAddress,
                "userType": selectedUserType === 'user' ? userSliceData.orgType : ['nokia'],
                "type": selectedUserType === 'user' ? 'user' : 'machine',
                "viewAccess": userSliceData.viewAccess,
                "dataElement": userSliceData.dataElement,
                "oneStarNum": userSliceData.oneStarNumber || [],
                "threeStarNum": userSliceData.threeStarNumber || [],
                "userRoles": userSliceData.roles,
                "markets": userSliceData.markets,
                "business_name": userSliceData.business_name,
                "supplier_account_id": userSliceData.supplier_account_id,
                "whitelisted_ip": userSliceData.whitelisted_ip
            };
        } else {
            dataPayload = {
                "id": selectedUserData.id,
                "email": selectedUserData.email,
                "fullName": selectedUserData.name !== "" ? selectedUserData.name : userSliceData.fullName,
                "oneStarNum": userSliceData.oneStarNumber,
                "threeStarNum": userSliceData.threeStarNumber,
                "userRoles": userSliceData.roles,
                "role": {
                    "view": userSliceData.viewAccess,
                    "roles": userSliceData.roles,
                    "markets": userSliceData.markets,
                    "persona": userSliceData.orgType,
                    "one_star": userSliceData.oneStarNumber || [],
                    "three_star": userSliceData.threeStarNumber || [],
                    "data-element": userSliceData.dataElement,
                    "business_name": userSliceData.business_name,
                    "supplier_account_id": userSliceData.supplier_account_id,
                    "whitelisted_ip": userSliceData.whitelisted_ip
                },
                "type": selectedUserType === 'user' ? 'user' : 'machine',
            }
        }
        try {
            const response = await OOT.addEditUser(dataPayload, mode, selectedUserType)
            if (response.status) {
                setSuccessShow(true);
                setUserSuccessMsg(response.msg);
                setTimeout(() => {
                    getAllUsersList();
                    handleDialogClose();
                }, 3000)
                // getAllUsers();
            } else {
                setErrorShow(true);
                setErrorMsg(response.errorMessage);
            }
        } catch (error) {
            setErrorShow(true);
            setErrorMsg(error.message);
        } finally {
            setDisableBtn(false);
        }

    };

    function isButtonDisabledWithValidations() {
        const isNokia = selectedOrgType.includes("nokia");
        const isUser = selectedUserType === "user";
        const isMachine = selectedUserType === "machine";
        const isWhitelistedClient = selectedUserType === "whitelisted_client";

        // Check mandatory fields for the "user" type when the organization type is "company".
        if (isNokia && isUser) {
            return (
                !userSliceData.fullName ||
                !userSliceData.emailAddress ||
                !userSliceData.roles ||
                !userSliceData.threeStarNumber.length ||
                !userSliceData.markets ||
                Object.keys(userSliceData.roles).length === 0
            );
        }

        // Check mandatory fields for the "user" type when the organization type is NOT "company".
        if (!isNokia && isUser) {
            return (
                !userSliceData.fullName ||
                !userSliceData.emailAddress ||
                !userSliceData.roles ||
                !userSliceData.threeStarNumber.length ||
                !userSliceData.orgType.length ||
                Object.keys(userSliceData.roles).length === 0
            );
        }

        // Check mandatory fields for the "machine" type.
        if (isMachine) {
            const { ip_list, ip_ranges } = userSliceData.whitelisted_ip;
            return (
                !userSliceData?.fullName ||
                !userSliceData?.roles || !userSliceData.emailAddress ||
                (ip_list?.length === 0 || ip_ranges?.length === 0)
                // Object.keys(userSliceData.roles).length === 0
            );
        }

        if (isWhitelistedClient) {

            return (
                !userSliceData.fullName || !userSliceData.emailAddress
                // Object.keys(userSliceData.roles).length === 0
            );
        }

        // Default case, button should not be disabled
        return false;
    }



    useEffect(() => {
        if (mode === 'edit' && selectedUserData) {
            console.log('selectedUserData:', selectedUserData);

            dispatch(setProp({ 'slice': 'userManagement', 'key': 'selectedUserType', 'value': typeOfUser }));
            // Initialize state with selectedUserData
            dispatch(setUserInputFields({ field: 'id', value: selectedUserData.id }));
            dispatch(setUserInputFields({ field: 'fullName', value: selectedUserData.name }));
            dispatch(setUserInputFields({ field: 'emailAddress', value: selectedUserData.email }));
            dispatch(setUserInputFields({ field: 'orgType', value: selectedUserData.userType || [] }));
            dispatch(setUserInputFields({ field: 'viewAccess', value: selectedUserData.viewAccess || [] }));
            dispatch(setUserInputFields({ field: 'dataElement', value: selectedUserData.dataElement || {} }));
            dispatch(setUserInputFields({ field: 'roles', value: selectedUserData.userRoles || {} }));
            dispatch(setUserInputFields({ field: 'oneStarNumber', value: selectedUserData.oneStarNumber || [] }));
            dispatch(setUserInputFields({ field: 'threeStarNumber', value: selectedUserData.threeStarNumber || [] }));
            dispatch(setUserInputFields({ field: 'whitelisted_ip', value: selectedUserData.whitelisted_ip || {} }));
            dispatch(setUserInputFields({ field: 'markets', value: selectedUserData.markets || {} }));
            dispatch(setUserInputFields({ field: 'business_name', value: selectedUserData.business_name || [] }));

        }

    }, [mode, selectedUserData, dispatch]);


    return (
        <>
            <Dialog isOpen={true} ariaHideApp={false} style={{ content: CONFIRM_ALERT_STYLE }}>
                <DialogTitle
                    title={mode === "add" ? `Add ${selectedUserType}` : `Edit ${typeOfUser}`}
                    buttonContent={<CloseCloseIcon />}
                    buttonProps={{ onClick: handleDialogClose }}
                />
                <DialogContent isTopDividerVisible={true} isBottomDividerVisible={true}>
                    <div className={userStyles.marginTop}>

                        {
                            mode === 'add' && <UserTypeSelection />
                        }

                        {
                            (selectedUserType === 'user' || typeOfUser === 'user') && <UserFormDetails mode={mode} typeOfUser={typeOfUser} selectedData={selectedUserData}/>
                        }

                        {
                            (selectedUserType === 'machine' || typeOfUser === 'machine') && <MachineFormDetails mode={mode} typeOfUser={typeOfUser} />
                        }
                        {
                            selectedUserType === 'whitelisted_client' && <WhiteListedClient mode={mode} typeOfUser={typeOfUser} />
                        }


                      <div style={{ position: 'absolute', bottom: '20px', width: '50%' }}>
                        {userSuccessMsg && <InlineFeedbackMessage
                            variant="success"
                            size="medium"
                            closeButton
                            show={successShow}
                            onClose={() => setSuccessShow(false)}
                        >
                            {userSuccessMsg}
                        </InlineFeedbackMessage>}
                        {userErrorMsg && <InlineFeedbackMessage
                            variant="error"
                            size="medium"
                            closeButton
                            show={errorShow}
                            onClose={() => setErrorShow(false)}
                        >
                            {userErrorMsg}
                        </InlineFeedbackMessage>}
                        </div>

                    </div>

                </DialogContent>
                <DialogFooter>
                    <FooterButtons onDialogClose={handleDialogClose} onSubmit={handleSubmit} disabled={isButtonDisabledWithValidations() || disableBtn} />
                </DialogFooter>
            </Dialog>
        </>
    )
}


export default UserDialogNew;