import React, { useState, useEffect, useCallback, useRef } from 'react';
import Pagination from "../marketplace/common/Pagination";
import { RootState } from "Store/mainStore";
import OOT from "Constants/services/oot";
import { setLoader, setOrders } from "Store/contentState/merchantSlice";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "Components/Elements/Loader";
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import userStyles from '../admin/user-management/Style/UserManagement.module.css';
import Chip, { ChipLabel } from '@nokia-csf-uxr/ccfk/Chip';

const OrderList = (prop) => {
    const dispatch = useDispatch();
    const merchantSelector = useSelector((state: RootState) => state.merchant);
    const paginationSelector = useSelector((state: RootState) => state.pagination);
    const { currentPage, limitPerpage } = paginationSelector.paginationProps;
    const globalSearchText = useSelector((state: RootState) => state.common.globalSearchText);
    const gridApi = useRef(null);
    const [activeTab, setActiveTab] = useState("");


    const tabList = ["allorders", "cancelled", "unpaid", "archived"]

    const getActiveTab = () => {
        let activeTab = tabList.filter((tab) => tab == prop.type && prop.isActive)
        return activeTab[0]
    }


    useEffect(() => {
        setActiveTab(getActiveTab())
        const offset = (currentPage - 1) * limitPerpage;
        console.log("okoko", getActiveTab())
        if (getActiveTab()) {
            getOrderList(offset);
        }
    }, [globalSearchText, prop.isActive, currentPage, limitPerpage])


    const prepareFilterConditions = () => {
        const filterConditions = [];

        // Global search text filter
        if (globalSearchText) {
            filterConditions.push(`(product_description_id LIKE '%${globalSearchText}%')`);
        }

        return filterConditions.length > 0 ? filterConditions.join(' AND ') : '';
    };


    const getOrderList = useCallback(async (offset) => {
        dispatch(setLoader({ key: "isProductsLoading", value: true }));
        const filterConditions = prepareFilterConditions();
        try {
            const response = await OOT.getAllMerchantOrders(filterConditions, limitPerpage, offset);
            if (response.data.length !== 0) {
                dispatch(setOrders(response.data));
            } else {
                dispatch(setOrders([]));
            }

        } catch (error) {
            console.error(error);
        } finally {
            dispatch(setLoader({ key: "isProductsLoading", value: false }));
        }
    }, [currentPage, limitPerpage, globalSearchText]);

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    if (merchantSelector.isProductsLoading) {
        return (
            <div style={{ marginTop: '50px' }}>
                <Loader />
            </div>
        )
    }

    return (
        <>
            <div style={{ width: '100%', height: '100%', marginTop: '10px' }}>
                <DataGrid
                    // variant={"compact"}
                    suppressMovableColumns={false}
                    suppressColumnMoveAnimation={false}
                    suppressRowClickSelection={false}
                    isRowDrag={false}
                    wrapperProps={{
                        style: {
                            width: "100%"
                            // width: 600,
                        }
                    }}
                    sideBar
                    disableAutoloadStyles
                    suppressContextMenu
                    onGridReady={(params) => {
                        gridApi.current = params.api;
                    }}
                    rowData={merchantSelector.orders}
                    columnDefs={prop.columnDefsList}
                    key={'Orders'}
                    defaultColDef={{
                        editable: false,
                        sortable: true,
                        flex: 0,
                        minWidth: 30,
                        filter: true,
                        resizable: false,
                    }}
                    overlayNoRowsTemplate='No users are available'
                    isInlineRowActions={false}
                    domLayout='autoHeight'
                    onFirstDataRendered={(params) => {
                        params.columnApi.autoSizeAllColumns();
                        // onFirstDataRendered(params);
                    }}
                    getRowClass={(params) => {
                        return params.data.isDisabled ? userStyles.disabledRow : '';
                    }}
                />
            </div>
            <Pagination disableProp={merchantSelector.products} />
        </>
    )
}

export default OrderList;