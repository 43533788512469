import React, {useState} from "react";
import classes from '../marketplace/Style/ProductSpecification.module.css';
import ProductDescription from "./ProductDescription";
import ProductCarousel from "./Components/ProductCarousel";
import ProductSpecification from "./ProductSpecification";
import { useQuery } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import CircularProgressIndicatorIndeterminate from "@nokia-csf-uxr/ccfk/CircularProgressIndicatorIndeterminate";
import { getAccountCurrency } from "./Utils/commons";
import SnackBar from "./common/SnackBar";



const ProductSpecificationBody = (prop) => {
  const dispatch = useDispatch();
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCloseSnackBar = () => {
    setSnackbarMessage(''); // Clear the snackbar message after it closes
};
  return (
    <>
      {
        !marketplaceSelector.isSelectedProductLoading && (
          <div className={classes.productSpecificationContainer}>
            <div className={classes.productDescription}>
              <ProductDescription />
            </div>


            <div className={classes.productCarouselSpecification}>
              <div>
                <ProductCarousel/>
              </div>
              <div>
                <ProductSpecification onAddToCartSnackbar={(message) => setSnackbarMessage(message)}/>
              </div>

            </div>


          </div>
        )
      }


      {marketplaceSelector.isSelectedProductLoading && (
        <div className={classes.optionLoader}>
          <CircularProgressIndicatorIndeterminate
            strokeWidth={6}
            role="alert"
          />
        </div>
      )}

     <SnackBar message={snackbarMessage} onClose={handleCloseSnackBar} />

    </>
  )
}

export default ProductSpecificationBody;