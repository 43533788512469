import React from "react";
import { NavLink } from "react-router-dom";

import { SideDrawerItem } from "@nokia-csf-uxr/ccfk/SideDrawer";
import UserSettingsIcon from "@nokia-csf-uxr/ccfk-assets/icons/latest/user-settings.svg";
import marketplaceCartIcon from "../Assets/Icons/marketplace_cart_icon.svg";
import CalendarIcon from "@nokia-csf-uxr/ccfk-assets/icons/latest/calendar.svg";
import quotationIcon from "../Assets/Icons/quotations_icon.svg";
import adminIcon from '../Assets/Icons/user_icon.svg';
import ordersIcon from '../Assets/Icons/orders_icon.svg';
import rolesIcon from '../Assets/Icons/roles_icon.svg';
import { resetPaginationsProps } from "Store/contentState/paginationSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import { clearGlobalSearchText } from "Store/contentState/commonSlice";
import DashboardIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/dashboard.svg';
import matrix from '../Assets/Icons/matrix.png'



const getIcon = (navItem) => {
  switch (navItem) {
    case 'marketplace':
      return marketplaceCartIcon;
    case 'quotations':
      return quotationIcon;
    case 'orders':
      return ordersIcon;
    case 'subscription':
      return CalendarIcon;
    case 'account':
      return UserSettingsIcon;
    case 'admin':
      return adminIcon;
      case 'roles':
      return rolesIcon;
    case 'merchant':
      return DashboardIcon;
    case 'products':
      return matrix;  

    default:
      return null; // Return a default icon or null if no match is found
  }
};



const LoadNavLinks = (navItem) => {
  const dispatch = useDispatch();
  const globalSearchText = useSelector((state: RootState) => state.common.globalSearchText);


  const handleTabCommonPropsReset = () => {
    // Check if the clicked navItem is one of the ones you want to trigger resetPaginationProps for
    if(globalSearchText){
      dispatch(clearGlobalSearchText())
    }
    if (navItem === 'marketplace' || navItem === 'admin') {
      dispatch(resetPaginationsProps()); // Dispatch resetPaginationProps action
    }
  }
  return (
    <NavLink key={navItem} to={`/${navItem}`} onClick={handleTabCommonPropsReset}>
      {({ isActive }) => (
        <SideDrawerItem
          icon={<img alt={`Icon ${navItem}`} src={getIcon(navItem)} />}
          label={navItem === 'admin' ? "Users": navItem === 'merchant' ? "Dashbord" : navItem.charAt(0).toUpperCase() + navItem.slice(1)}
          selected={isActive}
        />
      )}
    </NavLink>
  );
};

export default LoadNavLinks;
