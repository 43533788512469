import React from "react";
import { Button } from "@nokia-csf-uxr/ccfk"; // Replace with your actual Button import

type FooterButtonsProps = {
    onDialogClose: () => void;
    onSubmit: () => void;
    disabled: boolean;
};

const FooterButtons = ({ onDialogClose, onSubmit, disabled }: FooterButtonsProps) => {
    return (
        <>
            <Button onClick={onDialogClose}>Cancel</Button>
            <Button variant="call-to-action" onClick={onSubmit} disabled={disabled}>
                Submit
            </Button>
        </>
    );
};

export default FooterButtons;