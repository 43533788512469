import React from "react";
import OOT from "Constants/services/oot";
import { setUsers, setUsersLoading } from "Store/contentState/userManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import { get } from "lodash";


export const useUserActions = (typeOfUser) => {
    const dispatch = useDispatch();
    const paginationSelector = useSelector((state: RootState) => state.pagination);
    const userManagementSelector = useSelector((state: RootState) => state.userManagement);
    const globalSearchText = useSelector((state: RootState) => state.common.globalSearchText);
    const { limitPerpage, offset } = paginationSelector.paginationProps;

    const getAllUsersList = async () => {
        dispatch(setUsersLoading(true));
        try {
            const response = await OOT.getAllUsers(`1=1 order by users.email, users.type desc`, limitPerpage, offset, typeOfUser);
            if (response.status) {
                const updatedUserList = response.data.map((user) => ({
                    id: user.id,
                    name: user.name || '',
                    email: user.email || '',
                    status: user.active === 1 ? 'Active' : 'Inactive',
                    userTypeInList: user.role.persona.includes("nokia") ? 'Nokia' : 'External',
                    userType: user.role.persona,
                    viewAccess: user.role.view,
                    userRoles: user.role.roles,
                    dataElement: user.role['data-element'],
                    oneStarNumber: user.role.one_star,
                    type: user.type,
                    threeStarNumber: user.role.three_star,
                    markets: user.role.markets,
                    whitelisted_ip: user.role.whitelisted_ip,
                    business_name: user?.role?.business_name || [],
                }));
                dispatch(setUsers(updatedUserList));
                dispatch(setUsersLoading(false));
            } else {
                dispatch(setUsersLoading(false));
                // console.log("No users available");
            }
        } catch (error) {
            dispatch(setUsersLoading(false));
            console.error(error);
        }
    }
    

    const prepareFilterConditions = () => {
        const filterConditions = [];
    
        // Global search text filter
        if (globalSearchText) {
          filterConditions.push(`(users.name LIKE '%${globalSearchText}%' OR users.email LIKE '%${globalSearchText}%')`);
        }
    
        // Role name filter
        const selectedRole = userManagementSelector.userFilters.role; // Assume this is a string like 'owner'
    
        if (selectedRole) {
          // Generate the SQL query to check for the selected role in the JSON column
          const roleCondition = `JSON_EXTRACT(user_roles.role, '$.roles.${selectedRole}') IS NOT NULL`;
          filterConditions.push(roleCondition);
        }
    
        // Combine all conditions into a single query
        return filterConditions.length > 0 ? filterConditions.join(' AND ') : '1=1';
      };

    

      return getAllUsersList
    


}