import React, { useEffect } from "react";
import UsersList from "./UsersList";
import AdminFilter from "../AdminFilter";
import { Card, Typography } from '@nokia-csf-uxr/ccfk';


const Users = (prop) => {
    return (
        <div>
            {/* <Card style={{ padding: '.5rem', marginTop:'10px' }}> */}
            <AdminFilter data={prop}/>
            <UsersList data={prop}/>
        </div>
    )
}

export default Users;