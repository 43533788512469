//@ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import { Button, Typography } from "@nokia-csf-uxr/ccfk";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import { RootState } from "Store/mainStore";
import { TextInput } from "@nokia-csf-uxr/ccfk";
import Label from '@nokia-csf-uxr/ccfk/Label';
import { useDispatch, useSelector } from "react-redux";
import FileUploader from "../common/FileUploader";
import RadioButton from '@nokia-csf-uxr/ccfk/RadioButton';
import OOT from "Constants/services/oot";
import { setQuotationRefernce, updatePOReferenceNumber, updateUploadedFile, clearUploadPO, resetOrderResponseProps, updateBillingAddress, updateShippingAddress, setOrderResponseProps } from '../../../Store/contentState/marketplaceSlice'
import InlineFeedbackMessage from '@nokia-csf-uxr/ccfk/InlineFeedbackMessage';
import { setProp } from "Store/Actions";
import useOrderPropsData from "../hooks/useOrderPropsData";
import classes from '../../marketplace/Style/Cart.module.css';
import OrderResponse from "../quotations/OrderResponse";
import { pdf } from '@react-pdf/renderer';
import MyDocument from "../MyDocument";
import { formatDate } from "../Utils/commons";
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import infoCircleIcon from '../../../Assets/Icons/info-circle-small-fill-white.svg';
import { mapKeys } from "lodash";

const DIALOG_HEIGHT = "90%";
const DIALOG_WIDTH = "50%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};

type IUploadPOCloseProp = {
    onClose: () => void;
};

const UploadPO = ({ onClose, selectedOffer, orderMode, modalTitle }: IUploadPOCloseProp & { selectedOffer: any } & { orderMode: string } & { modalTitle: string }) => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const uploadPODetails = useSelector((state: RootState) => state.marketplace.uploadPODetails);
    const selectedBillingAddress = useSelector((state: RootState) => state.marketplace.uploadPODetails.billingAddress);
    const selectedShippingAddress = useSelector((state: RootState) => state.marketplace.uploadPODetails.shippingAddress);
    const [poFile, setPOFile] = useState(null);
    const accountData = useSelector((state: RootState) => state.marketplace.accountDetails);
    const orderTypeDetails = useSelector((state: RootState) => state.marketplace.orderType);
    const cart = useSelector((state: RootState) => state.marketplace.cart);
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const ownerName = useSelector((state: RootState) => state.authSlice.login.displayName);
    const generateDataProps = useOrderPropsData();
    const [addressWarning, setAddressWarning] = useState(false);
    const [shippingCountryError, setShippingCountryError] = useState(false);



    useEffect(() => {
        // Pre-select billing and shipping addresses if orderMode is quotationOrder
        if (orderMode === 'quotationOrder') {
            const billingAddress = accountData[0]['accountBillingAddress'].find(addr => addr.id === selectedOffer.oot_billing_address_id);
            const shippingAddress = accountData[0]['customer_address'].find(addr => addr.id === selectedOffer.oot_shipping_address_id);

            if (billingAddress) {
                dispatch(updateBillingAddress(billingAddress));
            }
            if (shippingAddress) {
                dispatch(updateShippingAddress(shippingAddress));
            }
        }
        // Check if shipping address is empty initially
        if (accountData[0]['customer_address'].length === 0 || accountData[0]['accountBillingAddress'].length === 0) {
            setAddressWarning(true); // Show warning if shipping address is empty
        } else {
            setAddressWarning(false); // Hide warning if shipping address is not empty
        }
    }, [accountData[0]['customer_address'], orderMode, selectedOffer]);

    // const handlePOReferenceNumberChange = (e) => {
    //     dispatch(updatePOReferenceNumber(e.target.value));
    // };




    const handleOnClose = () => {
        dispatch(clearUploadPO());
        dispatch(resetOrderResponseProps());
        onClose();
    }


    const handleBillingAddressChange = (event, address) => {
        // Check if the address is already selected
        dispatch(updateBillingAddress(address));

    };

    const handleShippingAddressChange = (address) => {
        dispatch(updateShippingAddress(address));
        if (address['address']['country_code'] !== '' && address['address']['country_code'] === marketplaceSelector.filters.selection.shippingCountry) {
            setShippingCountryError(false);
        } else {
            setShippingCountryError(true);
            // setAddressWarning(true)
        }
    };

    const setUploadedFileList = (fileList) => {
        if (
            fileList.length > 0 &&
            fileList[0].file &&
            fileList[0].file.status === "complete"
        ) {

            setPOFile(fileList[0].file);
        }
    };



    const generateDynamicPO = async () => {
        const blobObj = await pdf(<MyDocument
            data={cart}
            accountDetails={marketplaceSelector.accountDetails[0]}
            cartResponse={marketplaceSelector.cartResponse}
            owner={ownerName}
            createdDate={formatDate(new Date(), 'dd-MMM-yyyy')}
            billingAddress={marketplaceSelector.uploadPODetails.billingAddress}
            shippingAddress={marketplaceSelector.uploadPODetails.shippingAddress}
            orderType={orderTypeDetails} />).toBlob();
        return blobObj;
    }

    const requestOrder = async () => {
        let updatedPOFile = poFile;
        if (orderTypeDetails.orderNow && poFile === null) {
            updatedPOFile = await generateDynamicPO();
        }
        setIsSubmitting(true);
        dispatch(setOrderResponseProps({
            isOrderLoading: true,
            isOrderCreated: false,
            isOrderErrorOccur: false,
        }))
        try {
            let formData = new FormData();
            let dataProps;

            if (orderTypeDetails.orderNow || orderMode === 'quotationOrder') {
                if (updatedPOFile) {
                    formData.append('fileContent', updatedPOFile);
                }
                if (orderMode === 'directOrder') {
                    dataProps = generateDataProps(cart, accountData);
                } else if (orderMode === 'quotationOrder') {
                    dataProps = generateDataProps(selectedOffer['products'], accountData, 'quotationOrder', selectedOffer['offer_id']);
                }

                // Handle generateQuote scenario
                if (orderTypeDetails.generateQuote) {
                    // Only send dataProps, not fileContent
                    formData.delete('fileContent');
                }

                formData.append('offerData', JSON.stringify(dataProps));
            } else {
                dataProps = generateDataProps(cart, accountData);
                formData.append('offerData', JSON.stringify(dataProps));
            }
            let response;
            if (orderTypeDetails.orderNow|| orderMode === 'quotationOrder') {
                response = await OOT.uploadPOMarketplace(formData);
            } else {
                response = await OOT.saveOffer({ data: dataProps, source: 'marketplace' });
            }
            if (response.status) {
                dispatch(setOrderResponseProps({
                    isOrderLoading: false,
                    isOrderCreated: true,
                    isOrderErrorOccur: false,
                }));
                // dispatch(clearUploadPO());
            } else {
                dispatch(setOrderResponseProps({
                    isOrderLoading: false,
                    isOrderCreated: false,
                    isOrderErrorOccur: true,
                }));
            }
        } catch (error) {
            dispatch(setOrderResponseProps({
                isOrderLoading: false,
                isOrderCreated: false,
                isOrderErrorOccur: true,
            }));
        }

    }


   

    function isSubmitDisabled() {
        const isQuotationReferenceMissing = orderTypeDetails.generateQuote && (!marketplaceSelector.quotationRefernce || marketplaceSelector.quotationRefernce.trim() === '');
        const isPOFileRequired =  orderMode === 'quotationOrder';
        const isPOFileIncomplete = isPOFileRequired && (!poFile || poFile.status !== "complete");
        const isBillingAddressMissing = !uploadPODetails.billingAddress || Object.keys(uploadPODetails.billingAddress).length === 0; // Add your specific checks if needed
        const isShippingAddressMissing = !uploadPODetails.shippingAddress || Object.keys(uploadPODetails.shippingAddress).length === 0; // Add your specific checks if needed
        const orderTypeNotSelected = orderMode === "directOrder" && !(orderTypeDetails.generateQuote ||  orderTypeDetails.orderNow)
        return isQuotationReferenceMissing || isPOFileIncomplete || shippingCountryError || addressWarning || isBillingAddressMissing || isShippingAddressMissing || orderTypeNotSelected;
    }



    const handleDeleteCallback = () => {
        setPOFile(null);
    };

    return (
        <>
            <Dialog
                isOpen={true}
                ariaHideApp={false}
                style={{ content: CONFIRM_ALERT_STYLE }}
            >
                <DialogTitle
                    title={modalTitle}
                // buttonContent={<CloseCloseIcon />}
                // buttonProps={{
                //     onClick: handleOnClose,
                // }}
                />
                <DialogContent isTopDividerVisible={false} isBottomDividerVisible={false}>
                    {addressWarning &&
                        <div style={{ padding: '5px' }}>
                            <InlineFeedbackMessage
                                variant="error"
                                size="medium"
                                // closeButton
                                show={true}
                                onClose={() => setAddressWarning(false)}
                            >
                                There are no address defined, update the address to proceed further
                            </InlineFeedbackMessage>

                        </div>}




                    {!(marketplaceSelector.orderResponseProps.isOrderLoading || marketplaceSelector.orderResponseProps.isOrderCreated || marketplaceSelector.orderResponseProps.isOrderErrorOccur) && (

                        <div style={{ width: '100%' }}>
                            {/* {poFile && (<button onClick={downnloadUploadedFile}>Download</button>)} */}
                            {orderMode === "directOrder" && <div style={{ marginBottom: '1rem', width: '50%' }}>

                                <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px', color: "#757575", marginBottom: '5px' }}>Order Type</Label>
                                <div style={{ display: 'flex', gap: '.5rem' }}>
                                    <div>
                                        <RadioButton
                                            checked={orderTypeDetails.orderNow}
                                            onChange={(event) => {
                                                dispatch(setProp({
                                                    slice: 'marketplace', key: 'orderType', value: {
                                                        orderNow: true,
                                                        generateQuote: false
                                                    }
                                                }))
                                                //generateDynamicPO()
                                            }

                                            }
                                            inputProps={{
                                                'aria-label': 'Green',
                                                value: 'radioButtonGreen',
                                                name: 'favourite',
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Typography>Order now</Typography>
                                    </div>
                                    <div>
                                        <Tooltip
                                            closeOnReferenceHidden={false}
                                            placement="right"
                                            // fallbackPlacements={['right', 'left']}
                                            modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
                                            trigger="hover"
                                            tooltip={"Optionally upload the PO and order or order without uploading PO. "}
                                        >
                                            <img src={infoCircleIcon} />
                                        </Tooltip>
                                    </div>
                                </div>
                                {/* <div style={{ display: 'flex', gap: '.5rem' }}>
                                    <div>
                                        <RadioButton
                                            checked={orderTypeDetails.uploadPOOrder}
                                            onChange={(event) =>
                                                dispatch(setProp({
                                                    slice: 'marketplace', key: 'orderType', value: {
                                                        generatePOOrder: false,
                                                        uploadPOOrder: true,
                                                        generateQuote: false
                                                    }
                                                }))
                                            }
                                            inputProps={{
                                                'aria-label': 'Green',
                                                value: 'radioButtonGreen',
                                                name: 'favourite',
                                            }}
                                        />
                                    </div>
                                    <div><Typography>Upload PO and Order</Typography></div>
                                    <div>
                                        <Tooltip
                                            closeOnReferenceHidden={false}
                                            placement="right"
                                            // fallbackPlacements={['right', 'left']}
                                            modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
                                            trigger="hover"
                                            tooltip={"Upload approved PO and proceed to order"}
                                        >
                                            <img src={infoCircleIcon} />
                                        </Tooltip>
                                    </div>

                                </div> */}
                                <div style={{ display: 'flex', gap: '.5rem' }}>
                                    <div>
                                        <RadioButton
                                            checked={orderTypeDetails.generateQuote}
                                            onChange={(event) => {
                                                dispatch(setProp({
                                                    slice: 'marketplace', key: 'orderType', value: {
                                                        generatePOOrder: false,
                                                        uploadPOOrder: false,
                                                        generateQuote: true
                                                    }
                                                }))
                                                // if(poFile!==null){
                                                //     setPOFile(null)
                                                // }
                                            }
                                            }
                                            inputProps={{
                                                'aria-label': 'Green',
                                                value: 'radioButtonGreen',
                                                name: 'favourite',
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Typography>Generate quote</Typography>
                                    </div>
                                    <div>
                                        <Tooltip
                                            closeOnReferenceHidden={false}
                                            placement="right"
                                            // fallbackPlacements={['right', 'left']}
                                            modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
                                            trigger="hover"
                                            tooltip={"Generate quote now and later upload PO to order"}
                                        >
                                            <img src={infoCircleIcon} />
                                        </Tooltip>
                                    </div>
                                </div>


                            </div>}
                            {/* <div style={{ marginBottom: '1rem', width: '50%' }}>
                                <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px', color: "#757575" }}>PO Reference Id</Label>
                                <TextInput id="po-referenceid"
                                    style={{}}
                                    value={uploadPODetails.poReferenceNumber}
                                    variant={"outlined"}
                                    placeholder=""
                                    onChange={handlePOReferenceNumberChange}

                                />
                            </div> */}

                            {orderTypeDetails.generateQuote && <div style={{ marginBottom: '1rem', width: '50%' }}>
                                <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px', color: "#757575" }}>Quote reference</Label>
                                <TextInput id="quote-reference"
                                    style={{}}
                                    value={marketplaceSelector.quotationRefernce}
                                    variant={"outlined"}
                                    placeholder=""
                                    onChange={(e) => dispatch(setQuotationRefernce(e.target.value))}

                                />
                            </div>}
                            {(orderTypeDetails.orderNow || orderMode === "quotationOrder") && <div style={{ marginBottom: '1rem', width: '50%' }}>
                                <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px', color: "#757575", marginBottom: '5px' }}>Upload purchase order</Label>
                                <FileUploader
                                    accept={[".pdf"]}
                                    //@ts-nocheck
                                    setUploadedFiles={setUploadedFileList}
                                    maxFiles={1}
                                    multiple={false}
                                    fileRequirementTitle={
                                        "Accepts .pdf file only"
                                    }
                                    onDeleteCallback={handleDeleteCallback}
                                />
                            </div>}
                           
                            <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px', color: "#757575" }}>Billing address</Label>
                                {orderMode === 'quotationOrder'
                                    ? (
                                        selectedBillingAddress && selectedBillingAddress.address && (

                                            <div style={{ display: 'flex', gap: '.5rem', padding: '.5rem' }} key={selectedBillingAddress.id}>

                                                <div>
                                                    <RadioButton
                                                        checked={true}
                                                        inputProps={{
                                                            'aria-label': 'Billing Address',
                                                            value: selectedBillingAddress.id,
                                                            name: 'billingAddress',
                                                        }}
                                                        disabled
                                                    />
                                                </div>
                                                <div>
                                                    <Typography>{selectedBillingAddress.address.organization}</Typography>
                                                    <Typography>{selectedBillingAddress.address.address_line1}</Typography>
                                                    <Typography>{selectedBillingAddress.address.address_line2}</Typography>
                                                    <Typography>{selectedBillingAddress.address.locality}, {selectedBillingAddress.address.postal_code}</Typography>
                                                    <Typography>{selectedBillingAddress.address.country}</Typography>
                                                    <Typography>{selectedBillingAddress.address.given_name} {selectedBillingAddress.address.family_name}</Typography>
                                                    <Typography>{selectedBillingAddress.email_address}</Typography>
                                                    <Typography>{selectedBillingAddress.phone_number}</Typography>
                                                </div>
                                            </div>

                                        )
                                    )
                                    : (
                                        accountData[0]?.accountBillingAddress && accountData[0]?.accountBillingAddress?.map((item) => (

                                            <div style={{ display: 'flex', gap: '.5rem', padding: '.5rem' }} key={item.id}>

                                                <div>
                                                    <RadioButton
                                                        checked={selectedBillingAddress === item}
                                                        onChange={(event) => handleBillingAddressChange(event, item)}
                                                        inputProps={{
                                                            'aria-label': 'Billing Address',
                                                            value: item.id,
                                                            name: 'billingAddress',
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                <div><Typography>{item.address.organization}</Typography></div>
                                                    <div><Typography>{item.address.address_line1}</Typography></div>
                                                    <div><Typography>{item.address.address_line2}</Typography></div>
                                                    <div><Typography>{item.address.locality}, {item.address.postal_code}</Typography></div>
                                                    <div><Typography>{item.address.country}</Typography></div>
                                                    <div><Typography>{item.address.given_name} {item.address.family_name}</Typography></div>
                                                    <div><Typography>{item.email_address}</Typography></div>
                                                    <div><Typography>{item.phone_number}</Typography></div>
                                                </div>
                                            </div>

                                        ))
                                    )
                                }
                            </div>


                            <div className={classes.horizontalDivider}></div>

                            {
                                shippingCountryError &&
                                <InlineFeedbackMessage
                                    variant="error"
                                    size="medium"
                                    // closeButton
                                    show={true}
                                    onClose={() => setAddressWarning(false)}
                                >
                                    Selected shipping country does not match
                                </InlineFeedbackMessage>
                            }


                            <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                            <Label style={{ fontSize: '14px', fontWeight: '700', lineHeight: '21px', color: "#757575" }}>Shipping address</Label>
                            {orderMode === 'quotationOrder'
                                ? (
                                    selectedShippingAddress && selectedShippingAddress.address && (
                                        <div style={{ display: 'flex', gap: '.5rem', padding: '.5rem' }} key={selectedShippingAddress.id}>
                            
                                            <div>
                                                <RadioButton
                                                    checked={true}
                                                    inputProps={{
                                                        'aria-label': 'Shipping Address',
                                                        value: selectedShippingAddress.id,
                                                        name: 'shippingAddress',
                                                    }}
                                                    disabled
                                                />
                                            </div>
                                            <div>
                                                
                                                <Typography>{selectedShippingAddress.address.organization}</Typography>
                                                <Typography>{selectedShippingAddress.address.address_line1}</Typography>
                                                <Typography>{selectedShippingAddress.address.address_line2}</Typography>
                                                <Typography>{selectedShippingAddress.address.locality}, {selectedShippingAddress.address.postal_code}</Typography>
                                                <Typography>{selectedShippingAddress.address.country}</Typography>
                                                <Typography>{selectedShippingAddress.address.given_name} {selectedShippingAddress.address.family_name}</Typography>
                                                <Typography>{selectedShippingAddress.email_address}</Typography>
                                                <Typography>{selectedShippingAddress.phone_number}</Typography>
                                            </div>
                                        </div>
                                    )
                                )
                                : (
                                    accountData[0]['customer_address'] && accountData[0]['customer_address']?.map((item) => (
                                        <div style={{ display: 'flex', gap: '.5rem', padding: '.5rem' }} key={item.id}>
        
                                            <div>
                                                <RadioButton
                                                    checked={selectedShippingAddress === item}
                                                    onChange={(event) => {
                                                        event.persist();
                                                        handleShippingAddressChange(item);
                                                    }}
                                                    inputProps={{
                                                        'aria-label': 'Shipping Address',
                                                        value: item.id,
                                                        name: 'shippingAddress',
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <Typography>Customer Shipping address</Typography>
                                                <Typography>{item.address.organization}</Typography>
                                                <Typography>{item.address.address_line1}</Typography>
                                                <Typography>{item.address.address_line2}</Typography>
                                                <Typography>{item.address.locality}, {item.address.postal_code}</Typography>
                                                <Typography>{item.address.country}</Typography>
                                                <Typography>{item.address.given_name} {item.address.family_name}</Typography>
                                                <Typography>{item.email_address}</Typography>
                                                <Typography>{item.phone_number}</Typography>
                                            </div>
                                        </div>
                                    ))
                                )
                            }
                           </div>

                          

                        </div>
                    )}


                    <div>
                        <OrderResponse orderMode={orderMode} selectedOffer={selectedOffer} onClose={onClose}/>
                    </div>
                </DialogContent>
                {!(marketplaceSelector.orderResponseProps.isOrderLoading || marketplaceSelector.orderResponseProps.isOrderCreated || marketplaceSelector.orderResponseProps.isOrderErrorOccur) && (
                    <DialogFooter>
                        <Button onClick={handleOnClose}>Cancel</Button>
                        {/* disabled={isSubmitDisabled} */}
                        <Button variant="call-to-action" onClick={requestOrder} disabled={isSubmitDisabled() || isSubmitting}>Order</Button>
                    </DialogFooter>
                )}

                {
                    marketplaceSelector.orderResponseProps.isOrderErrorOccur && (
                        <DialogFooter>
                            <Button onClick={handleOnClose} variant="call-to-action">Close</Button>

                        </DialogFooter>
                    )
                }
            </Dialog>

        </>
    )

}

export default UploadPO;