
export const mandatoryApp = {
    allids: ['NIDM-IOT', 'NDAC-GRE','NIDM EMM'],
    byIds: {
        'NIDM-IOT': { id: 'NDAC-IOT', value: 'Nokia Industrial Device Management (NIDM-IOT)' },
        'NDAC-GRE': { id: 'NDAC-GRE', value: 'NDAC-GRE' },
        'NIDM EMM':{ id: 'NIDM EMM', value: 'Nokia Industrial Device Management (NIDM-EMM)' },
    }
}
export const segmentSolutions = {
    allIds: ['a1','a11','a12','a13','a2','a21','a22'],
    byIds: {
        'a1': { id: 'a1', value: 'Wireless data, manned operations', uid: 'manned_operation'},
        'a11': { id: "a11", uid: "dongles_vmt", value: "Dongles for VMT retrofit", },
        'a12': { id: "a12", uid: "workpads_vmt", value: "workpads for VMT/handheld", },
        'a13': { id: "a13", uid: "routers_che", value: "Routers for CHE Connection", },
        'a2':   {id: 'a2', uid: "semi_automated_operations", value: "Wireless data, semi-automated operations",},
        'a21': { id: "a21", uid: "router_remote", value: "Router (For remote control traffic:Profinet / Profisafe)" },
        'a22': { id: "a22", uid: "router_video", value: "Router (For video and other non - Profinet traffic)", }
    }
}
export const annualCapacity = {
    allIds: ['S', 'M', 'L', 'XL'],
    byIds: {
        'S': {
            id: "S",value:"Small", x: { range: { mid: 0, max: 0.75 } }
        },
        'M': {
            id: "M", value:"Medium",x: { range: { mid: 0.75, max: 2.00 } }
        },
        'L': {
            id: "L",value:"Large", x: { range: { mid: 2, max: 4 } }
        },
        'XL': {
            id: "XL",value:"XL", x: { range: { mid: 4, max: 'any' } }
        },

    }
}
