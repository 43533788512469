import React, { useState } from 'react';
import Card from '@nokia-csf-uxr/ccfk/Card';
import TabsSection from '../marketplace/common/TabsSection';
import ProductList from './ProductList';
import Chip, { ChipLabel } from '@nokia-csf-uxr/ccfk/Chip';
import { RootState } from "Store/mainStore";
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from "xlsx";

const MerchantProductsBody = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const merchantSelector = useSelector((state: RootState) => state.merchant);

  const customTabData = [
    { tab: { children: 'All Products', label: 'Tab One description' } },
    { tab: { children: 'Draft', label: 'Tab Two description' } },
    { tab: { children: 'Pending approval', label: 'Tab Three description' } }
  ];



  const columnDefsList = [
    {
      headerName: '',
      field: 'product_image',
      minWidth: 115,
      cellRenderer: (params) => {
        return (
          <div>
            <img
              src={params.data.product_image}
              style={{ height: '50px', objectFit: "contain", maxWidth: "50%" }} />
          </div>
        );

      }

    },
    { headerName: 'Product ID', field: 'product_id', minWidth: 240 },
    { headerName: 'Product Description', field: 'product_description_id', minWidth: 240, flex: 2 },
    { headerName: 'Product sub-type', field: 'product_type_id', minWidth: 240, flex: 2 },
    {
      headerName: 'Status',
      field: 'product_supplier_status_text',
      minWidth: 115,
      cellRenderer: (params) => {
        return (
          <div>
            {params.data.product_supplier_status_text === 'Active' && <Chip size="small" style={{ backgroundColor: '#E4F0FF', border: '1px solid #8EC2FF', marginRight: '.5rem', borderRadius: '15px' }}><ChipLabel label={"Live"} /></Chip>}
            {params.data.product_supplier_status_text === 'Inactive' && <Chip size="small" style={{ backgroundColor: '#FFF1E4', border: '1px solid #FFD0A5', marginRight: '.5rem', borderRadius: '15px' }}><ChipLabel label={params.data.product_supplier_status_text} /></Chip>}
          </div>
        );

      }

    },
    { headerName: 'Category', field: 'product_category_id', minWidth: 240, flex: 2 },
  ]

  let tabContentComponents = [
    <ProductList key={0} type="allproducts" isActive={activeTabIndex === 0} index={activeTabIndex} columnDefsList={columnDefsList} />,
    <ProductList key={1} type="draftproducts" isActive={activeTabIndex === 1} index={activeTabIndex} columnDefsList={columnDefsList} />,
    <ProductList key={2} type="pendingproducts" isActive={activeTabIndex === 2} index={activeTabIndex} columnDefsList={columnDefsList} />
  ];


  const getActiveSubscription = (subscriptionData) => {
    const activeSubscription = subscriptionData.filter(
      (row) => row
    );
    return activeSubscription;
  };

  const prepareExcelExportData = () => {
    let eeFileNmae = customTabData[activeTabIndex].tab.children ?? "Export";
    eeFileNmae += `_${Date.now()}`;
    let selectedFields = columnDefsList.map((row) => row.field);
    const filteredColDef = columnDefsList.filter((row) =>
      selectedFields.includes(row.field)
    );
    console.log(filteredColDef);
    const xlsxExportData = getActiveSubscription(merchantSelector.products).map((row) => {
      const exportRow = {};
      filteredColDef.forEach(
        (selecteRow) => (selecteRow.headerName ? exportRow[selecteRow.headerName] = row[selecteRow.field] : "")
      );
      return exportRow;
    });
    const ws = XLSX.utils.json_to_sheet(xlsxExportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${eeFileNmae}.xlsx`);
  };



  const handleTabSelect = (index) => {
    tabContentComponents = []
    console.log('Tab selected:', index);
    setActiveTabIndex(index);
  };

  const handleTabExportFile = () => {
    prepareExcelExportData()
  }


  return (
    <>
      <Card style={{ padding: '1rem' }}>
        <TabsSection tabData={customTabData} tabContentComponents={tabContentComponents}  //@ts-ignore
          onTabChange={handleTabSelect} pageTabName={"merchant"} onTabChangeExportFile={handleTabExportFile} />
      </Card>

    </>
  );
};

export default MerchantProductsBody;