export const segment_solution = {
    allIds: ['a1','a2'],
    byIds: {
        'a1': {
            id: 'a1', value: 'Wireless data, manned operations', uid: 'manned_operation', options: {
                allIds: ['a11', 'a12', 'a13'],
                byIds: {
                    'a11': {id:"a11",uid: "dongles_vmt", value: "Dongles for VMT retrofit" },
                    'a12': {id:"a12", uid: "workpads_vmt", value: "workpads for VMT/handheld"},
                    'a13': {id:"a13", uid: "routers_che", value: "Routers for CHE Connection"},
                }
            }
        },
        'a2': {
                id: 'a2', uid: "semi_automated_operations", value: "Wireless data, semi-automated operations", options: {
                allIds: ['a21', 'a22'],
                byIds: {
                    'a21': { id:"a21",uid: "router_remote", value: "Router (For remote control traffic:Profinet / Profisafe)" },
                    'a22': {id:"a22", uid: "router_video", value: "Router (For video and other non - Profinet traffic)" }
                }
            }
        },
    }
}

