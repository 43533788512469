// Regular expressions for IPv4 and IPv6 addresses
export const IPV4REGEX = /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/;
export const IPV6REGEX = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}(([0-9]{1,3}\.){3,3}[0-9]{1,3})|([0-9a-fA-F]{1,4}:){1,4}:(([0-9]{1,3}\.){3,3}[0-9]{1,3}))$/;


export const userRadioOptions = [
    { label: 'User', value: 'user' },
    { label: 'Machine', value: 'machine' },
    { label: 'Whitelisted application', value: 'whitelisted_client' }
];

export const userTypeOptions = [
    { label: 'Nokia', value: 'nokia' },
    { label: 'External', value: 'external' }
];

export const machineIPTypeOptions = [
    { label: 'IP address', value: 'ipAddress' },
    { label: 'IP range', value: 'ipRange' }
]

export const externalTypes = [
    {
        "id": "direct_customer",
        "value": "Direct Customer"
    },
    {
        "id": "merchant",
        "value": "Merchant"
    },
    {
        "id": "indirect_reseller",
        "value": "Reseller"
    },
    {
        "id": "distributor",
        "value": "Distributor"
    }
]



export const modifyData = (dataList, idKey, valueKey) => {
    return dataList.map(item => ({
        id: item[idKey],
        value: item[valueKey]
    }));
};

export const getRequestedInfo = (persona) => {
    // Set requested_info based on persona
    let requested_info = "";
    switch (persona) {
      case "nokia":
      case "partner":
      case "direct_customer":
        requested_info = "sell_to_party";
        break;
      case "indirect_end_customer":
        requested_info = "end_customer";
        break;
      case "merchant":
        requested_info = "supplier";
        break;
      default:
        console.warn("Unknown persona type");
        return;
    }
    return requested_info;
  }

