import { Hyperlink, Typography } from "@nokia-csf-uxr/ccfk";
// import { SimpleCard } from "@nokia-csf-uxr/ccfk/Card";
import emptyBox from "Assets/Icons/emptyBox.svg";
import PropTypes from "prop-types";
import React from "react";

const EmptyState = ({ msg, actionText, actionhref, src }) => {
  return (
    <>
      <div
        style={{
          // padding: "1rem",

          textAlign: "center",
          margin: "auto",

          //  height: '600px',
          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'center',
        }}
      >
        {/* <img height="200" alt="nothing-found" src={src ? src : emptyBox} /> */}
        <img alt="nothing-found" src={src ? src : emptyBox} />
        <Typography typography="BODY">{msg}</Typography>
        {/* <HorizontalDivider  className="m"/> */}
        {(actionhref && actionText) && (actionhref.trim().length > 0 && actionText.trin().length>0)}
        <Hyperlink
          href={actionhref}
          /* onClick={(e) => {
            e.preventDefault();
            actionhref();
          }} */
        >
          <Typography typography="BODY">{actionText}</Typography>
        </Hyperlink>
      </div>
    </>
  );
};
//EmptyState.displayName = "EmptyState";
export { EmptyState };
EmptyState.propTypes = {
  msg: PropTypes.string,
  actionText: PropTypes.string,
  actionhref: PropTypes.func,
  src: PropTypes.string,
};
