import React, {useState, useEffect, useCallback} from 'react';
import MultiSelectSmartList, { MultiSelectSmartListHeader, MultiSelectSmartCheckboxList, MultiSelectSmartListCheckbox, MultiSelectSmartListDivider } from '@nokia-csf-uxr/ccfk/MultiSelectSmartList';
import _cloneDeep from 'lodash/cloneDeep';
import OOT from 'Constants/services/oot';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Store/mainStore';
import Label from '@nokia-csf-uxr/ccfk/Label';
import userStyles from '../Style/UserManagement.module.css'
import { FilterField } from '@nokia-csf-uxr/ccfk';
import SearchBox from 'Pages/marketplace/common/SearchBox';
import { setUserInputFields } from 'Store/contentState/userManagementSlice';
import { getRequestedInfo } from '../utils/constants';

import { debounce } from 'lodash';
const ENTER_KEY =  'Enter';
const SPACE_KEY =  ' ';



const isSelectionKeyPressed = key => key && (key === ENTER_KEY || key === SPACE_KEY);



const AccountListSmartSelect = (props) => {
  const dispatch = useDispatch();
  const filter = useSelector((state: RootState) => state.userManagement.hierachyFilterQuery);
  const selectedOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
  const [sortedData, setSortedData] = useState([]);
  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const minSelected = 1;

  // Helper function to check if filter is empty
  const isFilterEmpty = (filter) => {
    return !filter || Object.keys(filter).length === 0;
  };

  // Determine if a divider is needed between selected and unselected items
  const doesListItemRequireDivider = (index, items) => {
    return index + 1 < items.length && !items[index + 1].selected && items[index].selected;
  };

  // Sorting function to ensure selected items appear at the top
  const sortDataBySelection = (data) => {
    const selectedData = [];
    const unSelectedData = [];

    data.forEach((item) => {
      if (item.selected) {
        selectedData.push(item);
      } else {
        unSelectedData.push(item);
      }
    });

    return selectedData.concat(unSelectedData);
  };
  // Function to fetch account list based on the filter
  const fetchAccountList = async () => {
    try {
      let limit = 10;
      let offeset = 0;
      let requested_info = getRequestedInfo(selectedOrgType[0]);
      const response = await OOT.getAccountsForInternalExternal(filter, limit , offeset, requested_info);
      //const result = await OOT.getAccountsCount(filter, limit , offeset, requested_info);
      if (response.status) {
        const data = response.data;
        if (data) {
          const accountList = data.map((item) => ({
            id: item.customer_account_number,
            label: item.name,
            businessName: item.business_name,
            parentAccountNumber: item.parent_account_number,
            selected: true, // Default selection set to true
            disabled: false,
          }));
          console.log('accountList:', accountList.length);
          setSortedData(accountList);
          setSelectAllSelected(true); // Set "Select All" to true if all items are selected by default
          updateSelectedAccountDetails(accountList);
        }
      }
    } catch (error) {
      console.error('Error fetching account list:', error);
    }
  };


  const updateSelectedAccountDetails = (data) => {
    const selectedAccounts = data.filter((item) => item.selected);
    let accountThreeStarNumbers = (selectedAccounts.map((item) => item.parentAccountNumber));
    let accountBusinessNames = (selectedAccounts.map((item) => item.businessName));
    dispatch(setUserInputFields({ field: 'threeStarNumber', value: accountThreeStarNumbers }));
    dispatch(setUserInputFields({ field: 'business_name', value: accountBusinessNames }))
  };


  // Handle changes when a specific item is selected or deselected
  const handleOnChange = (id) => {
    const updatedData = sortedData.map((item) =>
      item.id === id ? { ...item, selected: !item.selected } : item
    );

    setSortedData(sortDataBySelection(updatedData));
    setSelectAllSelected(updatedData.every((item) => item.selected)); // Check if all are selected
    updateSelectedAccountDetails(updatedData);
  };

  // Handle key down events for accessibility
  const handleKeyDown = (event, id) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleOnChange(id);
    }
  };

  // Handle "Select All" checkbox change
  const handleSelectAllOnChange = () => {
    const updatedData = sortedData.map((item) => ({
      ...item,
      selected: !selectAllSelected,
    }));
    setSortedData(sortDataBySelection(updatedData));
    setSelectAllSelected(!selectAllSelected);
    updateSelectedAccountDetails(updatedData);
  };


  // Call the API only if filter is not empty
  useEffect(() => {
    if (!isFilterEmpty(filter)) {
      fetchAccountList();
    }else{
        setSortedData([]);
        dispatch(setUserInputFields({ field: 'threeStarNumber', value: [] }));
        dispatch(setUserInputFields({ field: 'business_name', value: [] }))
    }
  }, [filter]);

  return (
    <div style={{width:'50%'}}>
     {sortedData.length > 0 && <div>
      <Label className={userStyles.userLabel}>Account</Label>
      <div>
        {/* <SearchBox placeholder="Search "/> */}
      </div>
      <MultiSelectSmartList>
        <>
          {sortedData.length > 0 && (
            <MultiSelectSmartListHeader
              title="Select all"
              selectAllCheckbox={{
                isEnabled: true,
                checked: selectAllSelected,
                indeterminate: sortedData.some((item) => item.selected),
                onChange: handleSelectAllOnChange,
                checkboxProps: {
                  inputProps: {
                    id: 'selectAllChkBoxId',
                  },
                },
                labelProps: {
                  htmlFor: 'selectAllChkBoxId',
                },
              }}
            />
          )}
          <MultiSelectSmartCheckboxList style={{ height: '400px' }}>
            {sortedData.map((data, index) => (
              <React.Fragment key={`listItem-${index}`}>
                <MultiSelectSmartListCheckbox
                  label={data.label}
                  selected={data.selected}
                  disabled={data.disabled}
                  icon={data.icon}
                  checkboxProps={{
                    inputProps: {
                      id: `listItem-${index}`,
                    },
                  }}
                  labelProps={{
                    htmlFor: `listItem-${index}`,
                  }}
                  onChange={() => handleOnChange(data.id)}
                  onKeyDown={(event) => handleKeyDown(event, data.id)}
                />
                {doesListItemRequireDivider(index, sortedData) && <MultiSelectSmartListDivider />}
              </React.Fragment>
            ))}
          </MultiSelectSmartCheckboxList>

          {/* {sortedData.length === 0 && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              No data found
            </div>
          )} */}
        </>
      </MultiSelectSmartList>
    </div>} 
    </div>
    
  );
};





// const AccountListSmartSelect = (props) => {
//   const dispatch = useDispatch();
//   const filter = useSelector((state: RootState) => state.userManagement.hierachyFilterQuery);
//   const selectedOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
//   const [sortedData, setSortedData] = useState([]);
//   const [selectAllSelected, setSelectAllSelected] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [limit, setLimit] = useState(10);
//   const [offset, setOffset] = useState(0);
//   const [searchQuery, setSearchQuery] = useState('');

//   // Helper function to check if filter is empty
//   const isFilterEmpty = (filter) => {
//     return !filter || Object.keys(filter).length === 0;
//   };

//   const doesListItemRequireDivider = (index, items) => {
//     return index + 1 < items.length && !items[index + 1].selected && items[index].selected;
//   };

//   const sortDataBySelection = (data) => {
//     const selectedData = [];
//     const unSelectedData = [];

//     data.forEach((item) => {
//       if (item.selected) {
//         selectedData.push(item);
//       } else {
//         unSelectedData.push(item);
//       }
//     });

//     return selectedData.concat(unSelectedData);
//   };

//   // Function to fetch account list based on the filter, pagination, and search
//   const fetchAccountList = async (newOffset = 0, query = searchQuery) => {
//     try {
//       let requested_info = getRequestedInfo(selectedOrgType[0]);
//       if(searchQuery !== ''){

//       }
//       const response = await OOT.getAccountsForInternalExternal(filter, limit, newOffset, requested_info);
//       if (response.status) {
//         const data = response.data;
//         if (data) {
//           const accountList = data.map((item) => ({
//             id: item.customer_account_number,
//             label: item.name,
//             businessName: item.business_name,
//             parentAccountNumber: item.parent_account_number,
//             selected: true,
//             disabled: false,
//           }));
//           setSortedData(accountList);
//           setSelectAllSelected(accountList.every((item) => item.selected));
//           updateSelectedAccountDetails(accountList);
//         }
//       }
//     } catch (error) {
//       console.error('Error fetching account list:', error);
//     }
//   };

//   const updateSelectedAccountDetails = (data) => {
//     const selectedAccounts = data.filter((item) => item.selected);
//     let accountThreeStarNumbers = selectedAccounts.map((item) => item.parentAccountNumber);
//     let accountBusinessNames = selectedAccounts.map((item) => item.businessName);
//     dispatch(setUserInputFields({ field: 'threeStarNumber', value: accountThreeStarNumbers }));
//     dispatch(setUserInputFields({ field: 'business_name', value: accountBusinessNames }));
//   };

//   const handleOnChange = (id) => {
//     const updatedData = sortedData.map((item) =>
//       item.id === id ? { ...item, selected: !item.selected } : item
//     );

//     setSortedData(sortDataBySelection(updatedData));
//     setSelectAllSelected(updatedData.every((item) => item.selected));
//     updateSelectedAccountDetails(updatedData);
//   };

//   const handleKeyDown = (event, id) => {
//     if (event.key === 'Enter' || event.key === ' ') {
//       event.preventDefault();
//       handleOnChange(id);
//     }
//   };

//   const handleSelectAllOnChange = () => {
//     const updatedData = sortedData.map((item) => ({
//       ...item,
//       selected: !selectAllSelected,
//     }));
//     setSortedData(sortDataBySelection(updatedData));
//     setSelectAllSelected(!selectAllSelected);
//     updateSelectedAccountDetails(updatedData);
//   };

//   // Handle pagination
//   const goToNextPage = () => {
//     const newOffset = offset + limit;
//     setCurrentPage(currentPage + 1);
//     setOffset(newOffset);
//     fetchAccountList(newOffset);
//   };

//   const goToPreviousPage = () => {
//     if (currentPage > 1) {
//       const newOffset = offset - limit;
//       setCurrentPage(currentPage - 1);
//       setOffset(newOffset);
//       fetchAccountList(newOffset);
//     }
//   };

//   // Handle search input changes
//   const handleSearchChange = (event) => {
//     const newSearchQuery = event.target.value;
//     setSearchQuery(newSearchQuery);
//     setOffset(0);
//     setCurrentPage(1);
//     fetchAccountList(0, newSearchQuery);
//   };

//   // Call the API only if filter is not empty
//   useEffect(() => {
//     if (!isFilterEmpty(filter)) {
//       fetchAccountList(offset, searchQuery);
//     } else {
//       setSortedData([]);
//       dispatch(setUserInputFields({ field: 'threeStarNumber', value: [] }));
//       dispatch(setUserInputFields({ field: 'business_name', value: [] }));
//     }
//   }, [filter, offset, searchQuery]);

//   return (
//     <div style={{ width: '50%' }}>
//       {sortedData.length > 0 && (
//         <div>
//           <Label className={userStyles.userLabel}>Account</Label>
//           <div>
//             <SearchBox placeholder="Search"  />
//           </div>
//           <MultiSelectSmartList>
//             <>
//               {sortedData.length > 0 && (
//                 <MultiSelectSmartListHeader
//                   title="Select all"
//                   selectAllCheckbox={{
//                     isEnabled: true,
//                     checked: selectAllSelected,
//                     indeterminate: sortedData.some((item) => item.selected),
//                     onChange: handleSelectAllOnChange,
//                     checkboxProps: {
//                       inputProps: {
//                         id: 'selectAllChkBoxId',
//                       },
//                     },
//                     labelProps: {
//                       htmlFor: 'selectAllChkBoxId',
//                     },
//                   }}
//                 />
//               )}
//               <MultiSelectSmartCheckboxList style={{ height: '400px' }}>
//                 {sortedData.map((data, index) => (
//                   <React.Fragment key={`listItem-${index}`}>
//                     <MultiSelectSmartListCheckbox
//                       label={data.label}
//                       selected={data.selected}
//                       disabled={data.disabled}
//                       icon={data.icon}
//                       checkboxProps={{
//                         inputProps: {
//                           id: `listItem-${index}`,
//                         },
//                       }}
//                       labelProps={{
//                         htmlFor: `listItem-${index}`,
//                       }}
//                       onChange={() => handleOnChange(data.id)}
//                       onKeyDown={(event) => handleKeyDown(event, data.id)}
//                     />
//                     {doesListItemRequireDivider(index, sortedData) && <MultiSelectSmartListDivider />}
//                   </React.Fragment>
//                 ))}
//               </MultiSelectSmartCheckboxList>
//               <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
//                 <button onClick={goToPreviousPage} disabled={currentPage === 1}>
//                   Previous
//                 </button>
//                 <span>Page {currentPage}</span>
//                 <button onClick={goToNextPage}>Next</button>
//               </div>
//             </>
//           </MultiSelectSmartList>
//         </div>
//       )}
//     </div>
//   );
// };





export default AccountListSmartSelect;
