import React, { memo } from "react";
import { App } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
//import AdminBody from "./AdminBody";
import UserRoles from "./UserRoles";



const crumItems = [{ title: 'Roles', path: '' }]

const Roles = () => {
    const authSelector = useSelector((state: RootState) => state.authSlice);
    
   return(
    <>
    <LoadingOverlay
        active={authSelector.system.isLoading}
        spinner
        classNamePrefix="MyLoader_"
        text="Please wait "
      >
        <App>
          <Header />
          <Body
            crumbs={[
              "Admin",
            ]}
            bodyContent={<UserRoles />}
            items = {crumItems}
          />          
        </App>
        {/* <AppFooter /> */}
      </LoadingOverlay>
    </>
   )
}

export default Roles;