interface GridDefaults {
  display: string,
  gridTemplateColumns?: string,
  gridTemplateRows?:string,
  gridGap: string,
  alignSelf?:string
};
interface flexDefaults{
   justify?:string,
    flexDirection?:string,
    gap?:string ,
    minHeight?:string ,
    alignItems?:string ,
    flex?:string,
    textAlign?:string
}
export const systemStyle = {
  genrateGridRows: (size:number, ratio: number | string = 1,gridGap:string="1rem"):object => {
    if (ratio !== 1) {
      switch (ratio) {
        case "7/3":
          return {
            display: "grid",
            gridTemplateRows: `7fr 3fr`,
            // gridTemplateAreas: "col 1 col2 col3",
            
            gridGap: `${gridGap}`,
          };
          break;
        case "3/7":
          return {
            display: "grid",
            gridTemplateRows: `3fr 7fr`,
            // gridTemplateAreas: "col 1 col2 col3",
            gridGap: `${gridGap}`,
          };
          break;

        default:
          break;
      }
    }
    return {
      display: "grid",
      gridTemplateRows: `repeat(${size}, minmax(0, 1fr))`,
      // gridTemplateAreas: "col 1 col2 col3",
      gridGap: `${gridGap}`,
    };
  },
  genrateGridCols: (size:number, ratio:number | string = 1, gridGap = "1rem"):object => {
    var setting:GridDefaults = {
      display: "grid",
      gridTemplateColumns: `repeat(${size}, minmax(0, 1fr))`,
      // gridTemplateAreas: "col 1 col2 col3",
      gridTemplateRows: `repeat(${size}, 0fr)`,
      gridGap: `${gridGap}`,
      alignSelf:"center",
    };
    if (ratio !== 1) {
      switch (ratio) {
        case "7/3":
          setting = {
            display: "grid",
            gridTemplateColumns: `7fr 3fr`,
            // gridTemplateAreas: "col 1 col2 col3",
            gridGap: `${gridGap}`,
          };
        
          break;
        case "3/7":
          setting = {
            display: "grid",
            gridTemplateColumns: `3fr 7fr`,
            // gridTemplateAreas: "col 1 col2 col3",
            gridGap: `${gridGap}`,
          }
          break;
        default:
          break;
      }
    }
    return setting;
  },
  flex: ({ justify,flexDirection="row" ,gap="unset",minHeight="unset",alignItems="center",flex="unset",textAlign="unset"}:flexDefaults):object => {
    return {
      display: "flex",
      justifyContent: justify,
      alignItems: alignItems,
      flexDirection: flexDirection,
      minHeight: minHeight,
      flex: flex,
      textAlign: textAlign,
      gap:gap,
    };
  },
  CARD: {},
  DIALOG: {
    content: {
      top: `calc((100vh - ${"30rem"}) / 2)`,
      height: "30rem",
      minHeight: "30rem",
      left: `calc((100vw - ${"60rem"}) / 2)`,
      right: `calc((100vw - ${"60rem"}) / 2)`,
      width: "60rem",
    },
  },
};
