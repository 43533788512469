import React from "react";
import {formatNumberWithCommas} from '../Utils/commons';

const PriceDisplay = (prop) => {
    return (
        <>
          {formatNumberWithCommas(prop.price)}
        </>
    )

}

export default PriceDisplay;