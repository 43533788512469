import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import classes from '../../marketplace/Style/MarketplaceFilter.module.css';
import { RootState } from "Store/mainStore";
import CircularProgressIndicatorIndeterminate from "@nokia-csf-uxr/ccfk/CircularProgressIndicatorIndeterminate";
import { setPartnerList, setLoader, setProducts, setFilters, clearCart, setShowFilter, setCustomerList } from "Store/contentState/marketplaceSlice";
import { useQuery } from "@tanstack/react-query";
import OOT from "Constants/services/oot";
import { setProp } from "Store/Actions";
import { useMarketplaceFilter } from "../hooks/useMarketplaceFilter";
import { setAccountDetails } from "Store/contentState/marketplaceSlice";
import infoCircleIcon from '../../../Assets/Icons/info-circle-small-fill-white.svg';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import { resetPaginationsProps } from "Store/contentState/paginationSlice";
import Button, {
    ButtonText,
    ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBox from "./SearchBox";

const shipToValues = [
    {
        "id": "sellToParty",
        "value": "Sell to party"
    },
    {
        "id": "reseller",
        "value": "Reseller"
    },
    {
        "id": "endCustomer",
        "value": "End customer"
    },
]


const CommonFilter = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const { fetchMarketplaceData, setCustomerState, updateAccountData, prepareProductApiFilter, setShippingCountry, } = useMarketplaceFilter();
    const subscriptionSelector = useSelector((state: RootState) => state.subscription);
    const { billingCountry, shippingCountry } = marketplaceSelector.filters.selection;
    const accountData = marketplaceSelector.accountDetails



    const handleCustomerSelection = (__customer) => {
        if (__customer !== '') {
            setCustomerState(__customer);
            //updateAccountData(__customer, marketplaceSelector.accountDetails, marketplaceSelector.customerList, marketplaceSelector.accountDropdown);
            updateAccountData(__customer, marketplaceSelector.accountDetails, marketplaceSelector.customerList);

        } else {
            dispatch(setFilters({ filterName: 'customer', value: '' }))
            dispatch(resetPaginationsProps());
            dispatch(setShowFilter(false));
            dispatch(setProducts([]));
            dispatch(setFilters({ filterName: 'productType', value: "" }));
            dispatch(clearCart());
        }
    };



    const handlePartnerSelection = (__partner) => {
        if (__partner !== '') {
            dispatch(setFilters({ filterName: 'partner', value: __partner }));
            // getAccountDetails(__partner)
            fetchMarketplaceData('fetch', __partner, marketplaceSelector.partnerList);
            // dispatch(setShowFilter(true));
            // if (marketplaceSelector.customerList.length === 0 && !marketplaceSelector.isCustomerLoading) {
            //     toast.error(
            //         "There are no end customers for this account"
            //     )
            // }
            dispatch(setFilters({ filterName: 'productType', value: "" }));
            dispatch(setProp({ slice: 'marketplace', key: 'searchQuery', value: "" }));
            dispatch(clearCart());

        } else {
            dispatch(setFilters({ filterName: 'partner', value: '' }))
            dispatch(setFilters({ filterName: 'customer', value: '' }))
            dispatch(setShowFilter(false));
            dispatch(setProp({ slice: 'marketplace', key: 'showShipTo', value: false }))
            dispatch(
                setCustomerList({
                    customerList: [],
                })
            );
            dispatch(
                setLoader({
                    key: "isCustomerLoading",
                    value: false,
                })
            );
            dispatch(setProducts([]));
            dispatch(setFilters({ filterName: 'productType', value: "" }));
            dispatch(clearCart());
        }

    }


    const handleCountrySelection = (_country) => {
        if (_country !== '') {
            //dispatch(setFilters({ filterName: 'shippingCountry', value: _country }));
            //getCountryDetails(_country, marketplaceSelector.countryList)
            //getSelectCountryDetails(_country, 'shipping', marketplaceSelector.countryList)
            setShippingCountry(_country, marketplaceSelector.countryList)
            dispatch(setFilters({ filterName: 'productType', value: "" }));
            dispatch(setProp({ slice: 'marketplace', key: 'searchQuery', value: "" }));
            // dispatch(clearCart());

        } else {
            dispatch(setFilters({ filterName: 'shippingCountry', value: '' }))
            // dispatch(setFilters({ filterName: 'customer', value: '' }))
            dispatch(setShowFilter(false));
            dispatch(resetPaginationsProps());
            dispatch(setProducts([]));
            dispatch(setFilters({ filterName: 'productType', value: "" }));
            dispatch(clearCart());
            // dispatch(setProp({ slice: 'marketplace', key: 'showShipTo', value: false }))
        }
    }

    const handleShipToSelection = (_value) => {
        if (_value != '') {
            dispatch(setFilters({ filterName: 'shipTo', value: _value }));
        } else {
            dispatch(setFilters({ filterName: 'shipTo', value: "" }));
        }
    }

    const handleProceed = () => {
        prepareProductApiFilter(billingCountry, shippingCountry, marketplaceSelector.countryList, accountData[0].accountType);
        dispatch(setProp({ slice: 'marketplace', key: 'isGetProducts', value: true }))
    }

    const checkBtnDisable = () => {
        return (marketplaceSelector.filters.selection.shippingCountry === '' || marketplaceSelector.filters.selection.partner === '' || marketplaceSelector.filters.selection.customer === '' || marketplaceSelector.accountDetails.length === 0 || (marketplaceSelector.showShipTo && marketplaceSelector.filters.selection.shipTo === ''))
    }

    return (
        <>
            {/* <ToastContainer /> */}
            <div className={classes["mainFilterContainer"]} >
                

                {location.pathname.includes("marketplace") && <div className={classes.selection}>
                    {marketplaceSelector?.filters?.selection?.shippingCountry ? (
                        <Tooltip tooltip={marketplaceSelector.shippingCountryText || "Select shipping country"} placement="bottom">
                            <SelectBox
                                labelText="Shipping country"
                                placeholder="Select shipping country"
                                maxWidth={true}
                                caseInsentiveMatching={true}
                                selectedItem={marketplaceSelector?.filters?.selection?.shippingCountry}
                                data={marketplaceSelector.countryList}
                                onUpdateSelection={handleCountrySelection}
                                isLoading={marketplaceSelector.isCountryLoading}
                            />
                        </Tooltip>
                    ) : (
                        <SelectBox
                            labelText="Shipping country"
                            placeholder="Select shipping country"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={marketplaceSelector?.filters?.selection?.shippingCountry}
                            data={marketplaceSelector.countryList}
                            onUpdateSelection={handleCountrySelection}
                            isLoading={marketplaceSelector.isCountryLoading}
                        />
                    )}
                    {marketplaceSelector.isCountryLoading && (
                        <div className={classes.optionLoader}>
                            <CircularProgressIndicatorIndeterminate
                                strokeWidth={6}
                                role="alert"
                            />
                        </div>
                    )}
                </div>}
                {location.pathname.includes("marketplace") && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '.5rem', marginTop: '23px' }}>
                    <Tooltip
                        closeOnReferenceHidden={false}
                        placement="bottom"
                        // fallbackPlacements={['right', 'left']}
                        modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
                        trigger="hover"
                        tooltip={"Changing the country/region you shop from may affect factors including price, shipping options and product availability in DAC Marketplace."}
                    >
                        <img src={infoCircleIcon} />
                    </Tooltip>
                </div>}

                <div className={classes.selection}>
                    {marketplaceSelector?.filters?.selection?.partner ? (
                        <Tooltip tooltip={accountData[0]?.name || "Select account"} placement="bottom">
                            <SelectBox
                                labelText={location.pathname.includes("marketplace") ? "Account" : ""}
                                placeholder="Select account"
                                maxWidth={true}
                                caseInsentiveMatching={true}
                                selectedItem={marketplaceSelector?.filters?.selection?.partner}
                                data={marketplaceSelector.partnerList}
                                onUpdateSelection={handlePartnerSelection}
                                isLoading={marketplaceSelector.isPartnerLoading}
                            />
                        </Tooltip>
                    ) : (
                        <SelectBox
                            labelText={location.pathname.includes("marketplace") ? "Account" : ""}
                            placeholder="Select account"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={marketplaceSelector?.filters?.selection?.partner}
                            data={marketplaceSelector.partnerList}
                            onUpdateSelection={handlePartnerSelection}
                            isLoading={marketplaceSelector.isPartnerLoading}
                        />
                    )}
                    {subscriptionSelector.isPartnerLoading && (
                        <div className={classes.optionLoader}>
                            <CircularProgressIndicatorIndeterminate
                                strokeWidth={6}
                                role="alert"
                            />
                        </div>
                    )}
                </div>

                <div className={classes.selection}>
                    {marketplaceSelector?.filters?.selection?.customer ? (
                        <Tooltip tooltip={accountData[0]?.end_customer_name || "Select customer"} placement="bottom">
                            <SelectBox
                                labelText={location.pathname.includes("marketplace") ? "Customer" : ""}
                                placeholder="Select customer"
                                maxWidth={true}
                                caseInsentiveMatching={true}
                                selectedItem={marketplaceSelector?.filters?.selection?.customer}
                                data={marketplaceSelector.customerList}
                                onUpdateSelection={(e) => handleCustomerSelection(e)}
                                isLoading={marketplaceSelector.isCustomerLoading}
                            />
                        </Tooltip>
                    ) : (
                        <SelectBox
                            labelText={location.pathname.includes("marketplace") ? "Customer" : ""}
                            placeholder="Select customer"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={marketplaceSelector?.filters?.selection?.customer}
                            data={marketplaceSelector.customerList}
                            onUpdateSelection={(e) => handleCustomerSelection(e)}
                            isLoading={marketplaceSelector.isCustomerLoading}
                        />
                    )}
                    {marketplaceSelector.isCustomerLoading && (
                        <div className={`${classes.optionLoader}`}>
                            <CircularProgressIndicatorIndeterminate
                                strokeWidth={6}
                                role="alert"
                            />
                        </div>
                    )}
                </div>

                {(location.pathname.includes("marketplace") && marketplaceSelector.showShipTo) && <div className={classes.selection}>
                    <SelectBox
                        // labelText="(Indirect) End customer"
                        labelText={"Ship to"}
                        placeholder="Select Ship to"
                        maxWidth={true}
                        caseInsentiveMatching={true}
                        selectedItem={marketplaceSelector?.filters?.selection?.shipTo}
                        data={shipToValues}
                        onUpdateSelection={(e) => handleShipToSelection(e)}
                        isLoading={false}
                    />
                </div>}


                {/* <div className={classes.selection}>
     <SelectBox
        labelText="Networks"
        placeholder="Select Network"
        maxWidth={true}
        caseInsentiveMatching={true}
        selectedItem={subscriptionSlice?.filters?.selection?.network}
        data={subscriptionSlice.networkList}
        onUpdateSelection={handleNetworkSelection}
     />
  </div> */}
                {location.pathname.includes("marketplace") && <div className={classes.selection} style={{ justifyContent: 'center', marginTop: '21px' }}>
                    <Button variant="call-to-action" disabled={checkBtnDisable()} onClick={handleProceed}>
                        <ButtonText>Proceed</ButtonText>
                    </Button>
                </div>}
            </div>
        </>






    )

}

export default CommonFilter;