/* eslint-disable */
import React, { useState } from 'react';
import Tabs, { Tab, TabsContent } from "@nokia-csf-uxr/ccfk/Tabs";// Import your UI library components
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import Button from '@nokia-csf-uxr/ccfk/Button';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import classes from '../../marketplace/Style/ProductCard.module.css';
import SearchBox from "Pages/marketplace/common/SearchBox";
import ExportIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/export-all.svg';
import exporButton from '../../../Assets/Icons/exporButton.png';

const ARIA_LABEL_TEXT_1 = 'To select Tab ';
const ARIA_LABEL_TEXT_2 = ' press spacebar';

const TabPanel = ({ index, selected, children, ...otherProps }) => {
    //console.log('index', index, selected, children);
    return (
        <div hidden={index !== selected} {...otherProps}>
            {children}
        </div>
    )
};

const TabsSection = ({ tabData, tabContentComponents, isFullWidth = false, onTabChange = () => { }, pageTabName = "All", onTabChangeExportFile = () => { } }) => {
    const [selected, setSelected] = useState(0);
    const [renderedTabs, setRenderedTabs] = useState([0]);

    //console.log('tabData', tabContentComponents);
    const clickHandler = (selectedValue) => () => {
        //console.log('selected', selectedValue);
        setSelected(selectedValue);
        //@ts-ignore
        onTabChange(selectedValue, renderedTabs);
    };

    const clickExportFile = () => {
        onTabChangeExportFile()
    }




    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Tabs alignment="left" fullWidth={isFullWidth} variant="default">
                    {tabData.map(({ tab }, i) => {
                        const ariaMainText = tab && tab.label;
                        //console.log('ariaMainText', ariaMainText);
                        return (
                            <Tab
                                id={`tab-${i}`}
                                aria-label={selected === i ? `${ariaMainText}` : `${ARIA_LABEL_TEXT_1} ${ariaMainText} ${ARIA_LABEL_TEXT_2} `}
                                aria-controls={`tab-content-${i}`}
                                aria-posinset={(tab && tab.label) ? i + 1 : undefined}
                                aria-setsize={(tab && tab.label) ? tabData.length : undefined}
                                role="option"
                                key={`tab-${i}`}
                                selected={selected === i}
                                onSelect={clickHandler(i)}
                                {...tab}
                                children={tab.children}
                            />
                        );
                    })}
                </Tabs>
                {pageTabName === "merchant" && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '30px' }}>
                            <SearchBox placeholder="Search" />
                        </div>

                        <div className={classes.mlAuto}>
                            <Button
                                variant="secondary-small"
                                onClick={clickExportFile}
                            >
                                <ButtonIcon><img src={exporButton} alt="order-icon" /></ButtonIcon>
                                <ButtonText>Export</ButtonText>
                            </Button>

                        </div>
                    </div>
                )}
            </div>


            <TabsContent >
                {tabData.map(({ tab }, i) => (

                    <TabPanel
                        id={`tab-content-${i}`}
                        key={i}
                        index={i}
                        selected={selected}
                        role="tabpanel"
                        aria-labelledby={`tab-${i}`}
                        aria-live="polite"
                        aria-relevant="all"
                    >
                        {tabContentComponents[i]}
                    </TabPanel>
                ))}
            </TabsContent>
        </>
    );
};

export default TabsSection;
